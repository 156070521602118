import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Snackbar,
  Alert,
  List,
  ListItem,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Pagination,
  AppBar,
  Toolbar,
  FormControlLabel,
  Switch,
  DialogContentText,
  RadioGroup,
  Radio,
  Divider,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Visibility';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';

import { db } from '../../firebaseConfig';
import {
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';

import { applyTemplate } from './functions';  // 従来テンプレ適用の関数
import { calculatePrices } from './calculatePrices'; // 価格計算ロジック
import { applyAITitles } from './applyAITitles';     // AIタイトル生成
import { applyAIProductDescriptions } from './applyAIProductDescriptions'; // AI商品説明生成


// ★ 再置換用の関数をimport（domTransform.jsに実装してある想定）
import { transformFinalHTMLWithDOMParser } from './domTransform';

// === (5) ItemSpecifics用の処理関数 (既存)
import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';

// ▼ 画像解析関連
import ImageAnalysis from '../image_func/ImageAnalysis';

// ▼ 価格変更処理（ファイルを分けるなら別ファイルでもOK）
const applyPriceChanges = async (
  currentData,
  selectedPriceSettings,
  setSnackbar
) => {
  try {
    if (!selectedPriceSettings) {
      throw new Error('価格設定が取得されていません。');
    }

    // ここでは selectedPriceSettings に最新の selectedShippingTemplateId が入っている前提
    const updatedSettings = { ...selectedPriceSettings };

    const updatedData = await Promise.all(
      currentData.map(async (file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        const data = parsedResult.data;

        // calculatePrices に updatedSettings を渡して価格計算
        const newPrices = calculatePrices(data, updatedSettings);

        const updatedRows = data.map((item, index) => {
          const calculatedPrice = newPrices[index];
          if (calculatedPrice) {
            return {
              ...item,
              StartPrice: calculatedPrice.discountedPrice,
              BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
              MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
            };
          }
          return item;
        });

        const csvContent = Papa.unparse(updatedRows);
        return {
          ...file,
          content: csvContent,
        };
      })
    );

    return updatedData;
  } catch (error) {
    console.error('価格適用中エラー:', error);
    setSnackbar({
      open: true,
      message: '価格適用中にエラーが発生しました。',
      severity: 'error',
    });
    throw error;
  }
};

// ▼ AIタイトル設定読み込み
async function loadTitleSettings(uid) {
  const docRef = doc(db, 'userTitleSettings', uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log('userTitleSettings が見つかりません');
    return null;
  }
}

// ▼ PicURLカラムを分割（複数画像URL対応）
export const getImageUrls = (picUrlString) => {
  if (!picUrlString) return [];
  return picUrlString.split('|').filter(Boolean);
};



/* ============================= */
/* ★ 以下が追加・改修ポイント ★ */
/* ============================= */

/** 1) AI生成部分抽出用のユーティリティ関数群 **/
const extractAIGeneratedContent = (description) => {
  if (!description) return '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const aiContentDiv = doc.getElementById('ai-generated-content');
  return aiContentDiv ? aiContentDiv.innerHTML : '';
};

// 商品説明から "C:Key : Value" または "C:Key = Value" のペアを抽出する関数（bullet付きにも対応）
const extractKeyValuePairs = (content) => {
  const keyValuePairs = {};
  if (!content) return keyValuePairs;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  
  // <li> 要素をすべて取得
  const listItems = doc.querySelectorAll('li');
  console.log('--- extractKeyValuePairs: 発見した <li> 要素数 ---', listItems.length);

  // bullet を除去する正規表現（✔, ✓, •, -, * など両方のチェックマークを含む）
  const bulletRegex = /^[\-\•\✓\✔\*\s]+/;
  // 「C:」で始まる行のみ抽出する正規表現
  // ・先頭の不要な記号（bullet）を除去後、必ず "C:" または "C：" があることを前提
  // ・セパレータはコロン(半角: 全角：)またはイコール(=)に対応
  const mainRegex = /^C[:：]\s*([^:=：]+?)\s*[:：=]\s*(.+)$/;

  listItems.forEach((li, index) => {
    let text = li.textContent.trim();
    console.log(`li[${index}] の元テキスト: "${text}"`);
    // bullet（不要な先頭記号）を除去
    text = text.replace(bulletRegex, '').trim();
    console.log(`li[${index}] bullet除去後: "${text}"`);

    // 正規表現で "C:Key : Value" または "C:Key = Value" を抽出
    const match = text.match(mainRegex);
    if (match) {
      const key = match[1].trim();
      const value = match[2].trim();
      if (key && value) {
        keyValuePairs[key] = value;
        console.log(`Extracted Key: "${key}", Value: "${value}"`);
      }
    } else {
      console.log('マッチせずスキップ:', text);
    }
  });

  console.log('--- extractKeyValuePairs: 最終抽出結果 ---');
  console.log(keyValuePairs);
  return keyValuePairs;
};


const truncateValueByWords = (value, maxLength) => {
  if (value.length <= maxLength) return value;
  const words = value.split(' ');
  let truncatedValue = '';
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const separator = truncatedValue ? ' ' : '';
    if ((truncatedValue + separator + word).length > maxLength) {
      break;
    }
    truncatedValue += separator + word;
  }
  return truncatedValue;
};

/**
 * 2) Item Specifics を適用する wrapper
 *    - applyItemSpecificsProcessor をコールしつつ、
 *      その後 AI生成部分から "C:Color: Red" のようなデータを抽出して
 *      CSVのカラム "C:Color" にセットする機能などを追加。
 */
async function applyItemSpecificsWrapper(
  currentData,
  userUid,
  {
    selectedItemSpecificsCategory,
    selectedItemSpecificsColumns,
    itemSpecificsMatchingOptions,
    overwriteExisting,
    setSnackbar,
    setProgress,
  }
) {
  console.log('★★ Debug: applyItemSpecificsWrapper called with category =', selectedItemSpecificsCategory);
  console.log('★★ Debug: selectedItemSpecificsColumns =', selectedItemSpecificsColumns);
  console.log('★★ Debug: overwriteExisting =', overwriteExisting);

  try {
    if (!selectedItemSpecificsCategory) {
      console.log('★★ Debug: category is EMPTY, so throwing error...');
      setSnackbar?.({
        open: true,
        message: '適用するItem Specificsのカテゴリーが選択されていません。',
        severity: 'warning',
      });
      throw new Error('Item Specificsカテゴリー未選択');
    }

    // 既存の Processor で項目を適用
    if (setProgress) {
      setProgress({ totalItems: 0, completedItems: 0 });
    }
    let updatedData = await applyItemSpecificsProcessor(
      currentData,
      userUid,
      {
        selectedCategory: selectedItemSpecificsCategory,
        selectedColumns: selectedItemSpecificsColumns,
        matchingOptions: itemSpecificsMatchingOptions,
      },
      (prog) => {
        if (setProgress) {
          setProgress(prog);
        }
      }
    );

    // 追加で "C:xxx" をAI生成文から抽出して適用
    updatedData = await Promise.all(
      updatedData.map((file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        const data = parsedResult.data;
        const headers = parsedResult.meta.fields;

        const titleKey = headers.find((h) => h.toLowerCase() === 'title');
        const descriptionKey = headers.find((h) => h.toLowerCase() === 'description');

        const { matchSource } = itemSpecificsMatchingOptions || {};

        const newData = data.map((item) => {
          const titleVal = item[titleKey] || '';
          const descVal = item[descriptionKey] || '';

          // AI生成部分
          const aiContent = extractAIGeneratedContent(descVal);

          let sourceText = '';
          if (matchSource === 'title') {
            sourceText = titleVal;
          } else if (matchSource === 'description') {
            sourceText = aiContent;
          } else if (matchSource === 'both') {
            sourceText = `<div>${titleVal}</div>${aiContent}`;
          }
          console.log('使用する抽出対象テキスト:', sourceText);
    
          // ★ sourceText を利用してキー・バリュー抽出
          const keyValuePairs = extractKeyValuePairs(sourceText);

          const newItem = { ...item };

          const normalizeString = (str) =>
            str.toLowerCase().replace(/\s+/g, '').replace(/[^a-z0-9]/g, '');

          Object.keys(keyValuePairs).forEach((key) => {
            const normalizedKey = normalizeString(key);
            // ヘッダに "C:Color" などがあるか探す
            const matchingHeader = headers.find(
              (h) =>
                h.startsWith('C:') &&
                normalizeString(h.slice(2)) === normalizedKey
            );
            if (matchingHeader) {
              const rawValue = keyValuePairs[key];
              const valueToApply = truncateValueByWords(rawValue, 64);

              if (overwriteExisting || !newItem[matchingHeader]) {
                newItem[matchingHeader] = valueToApply;
              }
            }
          });

          return newItem;
        });

        const csvContent = Papa.unparse(newData);
        return { ...file, content: csvContent };
      })
    );

    return updatedData;
  } catch (error) {
    console.error('applyItemSpecificsWrapperエラー:', error);
    setSnackbar?.({
      open: true,
      message: 'Item Specifics適用中にエラーが発生しました。',
      severity: 'error',
    });
    throw error;
  }
}

// メインファイルの中（function CsvSchedulerUploader({ ... }) の前でも後でもOK）に追加

function TruncatedText({ data, maxLines = 5 }) {
  // 展開中かどうかのフラグ
  const [expanded, setExpanded] = useState(false);

  // data が null/undefined なら「データなし」と表示
  if (!data) {
    return (
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        データがありません
      </Typography>
    );
  }

  // JSONを文字列化
  const jsonString = JSON.stringify(data, null, 2);
  // 行ごとに分割
  const lines = jsonString.split('\n');

  // expanded が true なら全行表示、それ以外なら先頭 maxLines 行のみ
  const displayedLines = expanded ? lines : lines.slice(0, maxLines);

  // 「もっと見る」ボタンを表示するかどうか
  const isLong = lines.length > maxLines;

  return (
    <Box sx={{ position: 'relative', mt: 1 }}>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
        {displayedLines.join('\n')}
      </Typography>

      {isLong && (
        <Button
          variant="text"
          size="small"
          onClick={() => setExpanded(!expanded)}
          sx={{ mt: 1 }}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Box>
  );
}


/* ============================= */
/*     コンポーネント本体       */
/* ============================= */
function CsvSchedulerUploader({ user, apiKey, apiKeys }) {
  const navigate = useNavigate();

  // ===================================
  // ローカルステート (ファイルアップロード周り)
  // ===================================
  const [csvFiles, setCsvFiles] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [processedParsedData, setProcessedParsedData] = useState([]);

  // ファイル入力をリセットするための key（コンポーネント再レンダリング用）
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  // リセットボタン押下時のハンドラ
  const resetUploader = () => {
  // 各状態を初期状態に戻す
  setCsvFiles([]);
  setFileContents([]);
  setParsedData([]);
  setProcessedData([]);
  // ファイル入力コンポーネントの key を更新して再マウント
  setFileInputKey(Date.now());
    };


  // スナックバー
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  // テンプレート適用(旧 doc) 用
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');

  // ===================================
  // AI商品説明生成用 (subcollection)
  // ===================================
  const [templates, setTemplates] = useState([]);
  const [selectedAiDescTemplateId, setSelectedAiDescTemplateId] = useState('');
  const [aiDescTemplateData, setAiDescTemplateData] = useState(null);

  const [customFilters, setCustomFilters] = useState([]);
  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]);
  const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [templateLoading, setTemplateLoading] = useState(false);
  const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
  const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
  const [saveOption, setSaveOption] = useState('new');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [selectedTemplate, setSelectedDesignTemplate] = useState("");

  // ===================================
  // 価格系ステート
  // ===================================
  const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);
  const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
  const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');
  const [exchangeRate, setExchangeRate] = useState(0);
  const [targetProfitMargin, setTargetProfitMargin] = useState(0);
  const [fees, setFees] = useState({});
  const [bestOfferSettings, setBestOfferSettings] = useState({});
  const [hasInitialized, setHasInitialized] = useState(false);

  const selectedShippingTemplate = shippingRateTemplates.find(
    (tpl) => tpl.id === selectedShippingTemplateId
  );
  

  // ===================================
  // スケジュール関連
  // ===================================
  const [isImmediate, setIsImmediate] = useState(true);
  const [scheduledTime, setScheduledTime] = useState('');
  const [isScheduling, setIsScheduling] = useState(false);

  // ===================================
  // プレビュー関連
  // ===================================
  const [activeTab, setActiveTab] = useState(0);
  const [itemsPerPageForPreview, setItemsPerPageForPreview] = useState(50);
  const [previewCurrentPage, setPreviewCurrentPage] = useState(1);

  // ===================================
  // パターン管理
  // ===================================
  const [patterns, setPatterns] = useState([]);
  const [selectedPatternName, setSelectedPatternName] = useState('');
  const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
  const [newPatternName, setNewPatternName] = useState('');
  const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

  // ===================================
  // AIタイトル生成関連
  // ===================================
  const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
  const [errorItems, setErrorItems] = useState([]);
  const [deleteStrings, setDeleteStrings] = useState([]);
  const [replacePairs, setReplacePairs] = useState([]);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [limitTitleLength, setLimitTitleLength] = useState(false);
  const [customCategories, setCustomCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [additionalPrompt, setAdditionalPrompt] = useState('');

  // 【変更】画像解析データをタイトル生成に利用するかのフラグ
const [useImageAnalysisForTitle, setUseImageAnalysisForTitle] = useState();


  // ===================================
  // AI商品説明生成 (バナー等)
  // ===================================
  const [bannerEnabled, setBannerEnabled] = useState(false);
  const [bannerUrl, setBannerUrl] = useState('');
  const [picInsertEnabled, setPicInsertEnabled] = useState(false);

  // ===================================
  // その他UI (プレビュー用ダイアログ)
  // ===================================
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewRowData, setPreviewRowData] = useState(null);

  

  // ===================================
  // 機能選択ダイアログ
  // ===================================
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [functionDialogOpen, setFunctionDialogOpen] = useState(false);

  // ===================================
  // (5) Item Specifics関連ステート (★)
  // ===================================
  const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
  const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
  const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
    caseSensitive: false,
    partialMatch: true,
    matchSource: 'both', // 'title' | 'description' | 'both'
    matchingOption: 'priority', // 'priority' | 'all'
  });
  const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});
  const [overwriteExisting, setOverwriteExisting] = useState(true);

  // ※ カテゴリーを選択した際、選択できるカラムを返す
  const getColumnsForSelectedCategory = () => {
    if (
      selectedItemSpecificsCategory &&
      itemSpecificsCategories[selectedItemSpecificsCategory] &&
      itemSpecificsCategories[selectedItemSpecificsCategory].columns
    ) {
      return Object.keys(itemSpecificsCategories[selectedItemSpecificsCategory].columns);
    }
    return [];
  };

  // ===================================
  // 価格機能更新
  // ===================================
  const reloadPriceSettings = async () => {
    try {
      const docRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userSettingsData = docSnap.data();
        // 各種価格設定ステートを更新
        setSelectedPriceSettings(userSettingsData);
        if (userSettingsData.shippingRateTemplates) {
          setShippingRateTemplates(userSettingsData.shippingRateTemplates);
        }
        setSelectedShippingTemplateId(userSettingsData.selectedShippingTemplateId);
        setExchangeRate(userSettingsData.exchangeRate || 0);
        setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
        setFees(userSettingsData.fees || {});
        setBestOfferSettings({
          bestOfferAutoAcceptPercentage:
            userSettingsData.bestOfferAutoAcceptPercentage || 0,
          minimumBestOfferPercentage:
            userSettingsData.minimumBestOfferPercentage || 0,
        });
        setSnackbar({
          open: true,
          message: '最新の価格設定を読み込みました。',
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'ユーザー設定が見つかりませんでした。',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('価格設定再読み込みエラー:', error);
      setSnackbar({
        open: true,
        message: '価格設定の再読み込みに失敗しました。',
        severity: 'error',
      });
    }
  };
  

  // (2) 除外ワードを保持する state
  //     => 「現在適用されているワード一覧」を保持する
  const [deleteWords, setDeleteWords] = useState([]);

  // ---------------------
  // (3) 除外ワードテンプレートの一覧を Firestore から取得する
  //     => "/users/{uid}/deleteWordTemplates" に複数のドキュメントがある想定
  const [deleteWordTemplates, setDeleteWordTemplates] = useState([]);
  const [selectedDeleteWordTemplateId, setSelectedDeleteWordTemplateId] = useState('');


  // 追加：除外前のデータを保持する state（リセット用）
const [backupProcessedData, setBackupProcessedData] = useState([]);
// 追加：除外された行のログを保持する state
const [exclusionLog, setExclusionLog] = useState([]);
// 追加：除外された商品の確認モーダルの開閉用 state
const [isExclusionLogOpen, setIsExclusionLogOpen] = useState(false);


  // ==============================
  // Firestore から「deleteWordTemplates」をまとめて取得
  // ==============================
  // これは「deleteWordTemplates」がドキュメントの場合の書き方

useEffect(() => {
  if (!user) return;

  // 4セグメント => ドキュメント扱いなので doc(...) を使う
  // const docRef = doc(db, 'users', user.uid, 'templates', 'deleteWordTemplates');
  const docRef = doc(db, 'users', user.uid, 'deleteWordTemplates', 'list');

  getDoc(docRef)
    .then((docSnap) => {
      if (docSnap.exists()) {
        const docData = docSnap.data();
        setDeleteWordTemplates(docData.templates || []);
      } else {
        // ドキュメントが存在しない場合
        setDeleteWordTemplates([]);
      }
    })
    .catch((err) => {
      console.error('テンプレート一覧取得エラー:', err);
      setSnackbar({
        open: true,
        message: '除外ワードテンプレートの取得に失敗しました',
        severity: 'error',
      });
    });
}, [user]);


  // ==============================
  // (5) 「選択されたテンプレートを適用する」ボタン押下時
  // ==============================
  const handleApplySelectedDeleteWordTemplate = () => {
    if (!selectedDeleteWordTemplateId) {
      setSnackbar({
        open: true,
        message: '適用するテンプレートを選択してください。',
        severity: 'warning',
      });
      return;
    }

    // deleteWordTemplates の中から ID が一致するものを探す
    const selectedDoc = deleteWordTemplates.find(
      (tpl) => tpl.name === selectedDeleteWordTemplateId
    );
    if (!selectedDoc) {
      setSnackbar({
        open: true,
        message: '選択されたテンプレートが見つかりません。',
        severity: 'error',
      });
      return;
    }

    // words: [ "aaa", "bbb", ... ] を { value, label } に変換
    const newDeleteWords = (selectedDoc.words || []).map((w) => ({
      value: w,
      label: w,
    }));

    setDeleteWords(newDeleteWords);
    setSnackbar({
      open: true,
      message: `テンプレート「${selectedDoc.name}」を適用しました。`,
      severity: 'success',
    });
  };

  // ==============================
  // (6) 除外ワードによる行の除外処理
  // ==============================
  const handleDeleteRowsByExclusionWords = () => {
    if (processedData.length === 0) {
      setSnackbar({
        open: true,
        message: '処理後のデータがありません。',
        severity: 'warning',
      });
      return;
    }
    if (!deleteWords || deleteWords.length === 0) {
      setSnackbar({
        open: true,
        message: '除外ワードが入力されていません。',
        severity: 'warning',
      });
      return;
    }
  
    // バックアップがまだない場合、現在の processedData をバックアップ
    if (backupProcessedData.length === 0) {
      // deep copy（元の状態に戻すため）
      setBackupProcessedData(JSON.parse(JSON.stringify(processedData)));
    }
  
    let totalRowsExcluded = 0;
    let newExclusionLog = [];
  
    const updatedData = processedData.map((file, fileIndex) => {
      const parsedResult = Papa.parse(file.content, { header: true });
      const originalRows = parsedResult.data;
      const filteredRows = [];
  
      originalRows.forEach((row, rowIndex) => {
        const titleText = row.Title || row.title || '';
        const jpTitleText = row.jp_title || row.JP_title || row.jpTitle || '';
        const jpDescText = row.jp_desc || row.JP_desc || row.jpDesc || '';
        const combinedText = (titleText + ' ' + jpTitleText + ' ' + jpDescText).toLowerCase();
        const hasExclusion = deleteWords.some((wordObj) =>
          combinedText.includes(wordObj.value.toLowerCase())
        );
        if (hasExclusion) {
          totalRowsExcluded += 1;
          newExclusionLog.push({
            fileIndex,
            fileName: file.name,
            rowIndex,
            rowData: row,
          });
        } else {
          filteredRows.push(row);
        }
      });
  
      const updatedCsv = Papa.unparse(filteredRows);
      return { ...file, content: updatedCsv };
    });
  
    setProcessedData(updatedData);
    setExclusionLog(newExclusionLog);
    setSnackbar({
      open: true,
      message: `除外ワードによって ${totalRowsExcluded} 行を除外しました。`,
      severity: 'success',
    });
  };

  // ===================================
  // 画像解析関連
  // ===================================
  const [imageAnalysisMode, setImageAnalysisMode] = useState("emptyOnly"); // "emptyOnly" または "all"
  const [imageAnalysisStrategy, setImageAnalysisStrategy] = useState("preferExisting");

  // ① 画像解析処理：空の場合のみ解析する関数
  const applyImageAnalysisIfEmpty = async (currentData, apiKey, setSnackbar) => {
    try {
      const updatedData = await Promise.all(
        currentData.map(async (file) => {
          const parsedResult = Papa.parse(file.content, { header: true });
          const data = parsedResult.data;
          const updatedRows = await Promise.all(
            data.map(async (row) => {
              if (!row["jp_desc"] || row["jp_desc"].trim() === "") {
                const picUrlString = row["PicURL"] || "";
                const picUrls = picUrlString.split("|").filter(Boolean);
                if (picUrls.length > 0) {
                  const description = await ImageAnalysis(picUrls[0], apiKey);
                  row["jp_image_analysis"] = description;
                }
              }
              return row;
            })
          );
          const csvContent = Papa.unparse(updatedRows);
          return { ...file, content: csvContent };
        })
      );
      return updatedData;
    } catch (error) {
      console.error("画像解析でエラー:", error);
      setSnackbar({
        open: true,
        message: "画像解析中にエラーが発生しました。",
        severity: "error",
      });
      throw error;
    }
  };

  // ② 画像解析処理：全ての商品に対して解析する関数
  const applyImageAnalysisForAll = async (currentData, apiKey, setSnackbar) => {
    try {
      const updatedData = await Promise.all(
        currentData.map(async (file) => {
          const parsedResult = Papa.parse(file.content, { header: true });
          const data = parsedResult.data;
          const updatedRows = await Promise.all(
            data.map(async (row) => {
              const picUrlString = row["PicURL"] || "";
              const picUrls = picUrlString.split("|").filter(Boolean);
              if (picUrls.length > 0) {
                const description = await ImageAnalysis(picUrls[0], apiKey);
                row["jp_image_analysis"] = description;
              }
              return row;
            })
          );
          const csvContent = Papa.unparse(updatedRows);
          return { ...file, content: csvContent };
        })
      );
      return updatedData;
    } catch (error) {
      console.error("画像解析でエラー:", error);
      setSnackbar({
        open: true,
        message: "画像解析中にエラーが発生しました。",
        severity: "error",
      });
      throw error;
    }
  };


  
  // 機能一覧 (名前と表示名だけを持つ)
  // ===================================
  // 関数自体は handleUploadAndExecute 内で呼び出すため、ここでは func: つけない
  const availableFunctions = [
    { name: 'applyTemplate', displayName: 'テンプレートを実行する' },
    { name: 'applyPriceChanges', displayName: '価格の変更を適用する' },
    { name: 'applyAITitles', displayName: 'AIタイトル生成を適用する' },
    {
      name: 'applyAIProductDescriptions',
      displayName: 'AI商品説明生成を適用する',
    },
    {
      name: 'applyImageAnalysis',
      displayName: '画像解析を適用する',
    },
    // "applyItemSpecifics" は別ボタンで適用
  ];

  // ===================================
  // 初期読み込み (useEffect)
  // ===================================
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    // (1) doc(db, 'userTemplates', user.uid) → 旧テンプレ
    const fetchOldTemplatesDoc = async () => {
      try {
        const docRef = doc(db, 'userTemplates', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const categories = data.categories || {};
          const merged = Object.values(categories).flat().map((t) => ({
            name: t.name,
            operations: t.operations,
          }));
          setTemplateList(merged);
          if (merged.length > 0) {
            setSelectedTemplateName(merged[0].name);
          }
        }
      } catch (error) {
        console.error('旧テンプレート読み込みエラー:', error);
        setSnackbar({
          open: true,
          message: '従来のテンプレート取得に失敗しました。',
          severity: 'error',
        });
      }
    };

    // (2) subcollection "users/{uid}/templates"
    const loadTemplatesSubcollection = async () => {
      try {
        const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
        const snap = await getDocs(userTemplatesRef);
        const data = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTemplates(data);
      } catch (error) {
        console.error('サブコレクション読み込みエラー:', error);
        setSnackbar({
          open: true,
          message: 'AI説明用テンプレートの取得に失敗しました。',
          severity: 'error',
        });
      }
    };

    // (3) userSettings
    const fetchUserSettings = async () => {
      try {
        const docRef = doc(db, 'userSettings', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userSettingsData = docSnap.data();
          console.log('★★ userSettingsData:', userSettingsData);

          setSelectedPriceSettings(userSettingsData);

          if (!hasInitialized) {
            setSelectedShippingTemplateId(userSettingsData.selectedShippingTemplateId);
            setHasInitialized(true);
            if (userSettingsData.shippingRateTemplates) {
              setShippingRateTemplates(userSettingsData.shippingRateTemplates);
            }
            setHasInitialized(true);
          } else {
            if (userSettingsData.shippingRateTemplates) {
              setShippingRateTemplates(userSettingsData.shippingRateTemplates);
            }
          }

          setExchangeRate(userSettingsData.exchangeRate || 0);
          setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
          setFees(userSettingsData.fees || {});
          setBestOfferSettings({
            bestOfferAutoAcceptPercentage:
              userSettingsData.bestOfferAutoAcceptPercentage || 0,
            minimumBestOfferPercentage:
              userSettingsData.minimumBestOfferPercentage || 0,
          });

          if (userSettingsData.patterns) {
            setPatterns(userSettingsData.patterns);
          } else {
            setPatterns([]);
          }
        }
      } catch (error) {
        console.error('userSettings読み込みエラー:', error);
        setSnackbar({
          open: true,
          message: 'ユーザー設定の取得に失敗しました。',
          severity: 'error',
        });
      }
    };

    

    // (4) AIタイトル設定
    const loadTitleSettingsFromFirestore = async () => {
      try {
        const titleSettingsData = await loadTitleSettings(user.uid);
        if (titleSettingsData) {
          if (titleSettingsData.deleteStrings) {
            setDeleteStrings(titleSettingsData.deleteStrings);
          }
          if (titleSettingsData.replacePairs) {
            setReplacePairs(titleSettingsData.replacePairs);
          }
          if (titleSettingsData.prependText) {
            setPrependText(titleSettingsData.prependText);
          }
          if (titleSettingsData.appendText) {
            setAppendText(titleSettingsData.appendText);
          }
          // 追加：追加したいプロンプトを抽出
          if (titleSettingsData.additionalPrompt) {
            setAdditionalPrompt(titleSettingsData.additionalPrompt);
          }
          if (typeof titleSettingsData.limitTitleLength === 'boolean') {
            setLimitTitleLength(titleSettingsData.limitTitleLength);
          }
          if (titleSettingsData.customCategories) {
            setCustomCategories(titleSettingsData.customCategories);
          }
        }
      } catch (error) {
        console.error('AIタイトル設定読み込みエラー:', error);
      }
    };

    // (5) Item Specifics Settings
    const fetchItemSpecificsSettings = async () => {
      try {
        if (!user) return;
        const docRef = doc(db, 'itemSpecificsSettings', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setItemSpecificsCategories(data.categories || {});
        }
      } catch (error) {
        console.error('Item Specifics設定取得エラー:', error);
      }
    };

    fetchOldTemplatesDoc();
    loadTemplatesSubcollection();
    fetchUserSettings();
    loadTitleSettingsFromFirestore();
    fetchItemSpecificsSettings();
    fetchPatterns(user.uid, setPatterns);
  }, [user, navigate, hasInitialized]);

  // ログ確認用
  useEffect(() => {
    console.log("selectedPriceSettings が更新されました:", selectedPriceSettings);
  }, [selectedPriceSettings]);

  // ===================================
  // CSVアップロード
  // ===================================  
  // const handleFileChange = (e) => {
  //   const files = Array.from(e.target.files).slice(0, 5);
  //   setCsvFiles(files);

  //   Promise.all(files.map((file) => file.text())).then((contents) => {
  //     setFileContents(contents);
  //   });
  // };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 5);
  
    Promise.all(files.map((file) => file.text())).then((rawContents) => {
      const filteredContents = rawContents.map((raw) => {
        // PapaParse の skipEmptyLines オプションのみで空行（空白のみの行）を除外
        const parsedResult = Papa.parse(raw, {
          header: true,
          skipEmptyLines: 'greedy',
        });
        // そのまま CSV に戻す
        return Papa.unparse(parsedResult.data);
      });
  
      setCsvFiles(files);
      setFileContents(filteredContents);
    });
  };
  

  useEffect(() => {
    if (fileContents.length > 0) {
      const data = fileContents.map((content) => {
        const parsedResult = Papa.parse(content, { header: true });
        const filteredRows = parsedResult.data.filter((row) =>
          Object.values(row).some((value) => value && value.trim() !== '')
        );
        return {
          data: filteredRows,
          columns: parsedResult.meta.fields,
        };
      });
      setParsedData(data);
    } else {
      setParsedData([]);
    }
  }, [fileContents]);

  useEffect(() => {
    if (processedData.length > 0) {
      const data = processedData.map((file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        return {
          name: file.name,
          data: parsedResult.data,
          columns: parsedResult.meta.fields,
        };
      });
      setProcessedParsedData(data);
    } else {
      setProcessedParsedData([]);
    }
  }, [processedData]);

  // ===================================
  // 機能選択ダイアログ
  // ===================================
  const handleFunctionDialogOpen = () => {
    setFunctionDialogOpen(true);
  };
  const handleFunctionDialogClose = () => {
    setFunctionDialogOpen(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newFunctions = Array.from(selectedFunctions);
    const [removed] = newFunctions.splice(result.source.index, 1);
    newFunctions.splice(result.destination.index, 0, removed);
    setSelectedFunctions(newFunctions);
  };

  const handleFunctionToggle = (func) => () => {
    const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
    const newSelected = [...selectedFunctions];
    if (currentIndex === -1) {
      newSelected.push(func);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedFunctions(newSelected);
  };

  // ===================================
  // subcollection テンプレート保存
  // ===================================
  const saveTemplate = async () => {
    if (!templateName.trim()) {
      setSnackbar({
        open: true,
        message: 'テンプレート名を入力してください',
        severity: 'warning',
      });
      return;
    }

    const templateData = {
      name: templateName.trim(),
      customFilters,
      sentencesToDelete,
      wordsToReplace,
      mandatoryKeywords,
      selectedFilters,
      selectedTemplate,
    };

    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
      if (saveOption === 'overwrite' && selectedTemplateId) {
        const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
        await setDoc(templateDocRef, templateData);
        setSnackbar({
          open: true,
          message: 'テンプレートが更新されました',
          severity: 'success',
        });
      } else {
        await addDoc(userTemplatesRef, templateData);
        setSnackbar({
          open: true,
          message: '新しいテンプレートが保存されました',
          severity: 'success',
        });
      }

      setIsSaveTemplateDialogOpen(false);
      setTemplateName('');

      // 再取得
      const templatesSnapshot = await getDocs(userTemplatesRef);
      const newData = templatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(newData);
    } catch (error) {
      console.error('テンプレート保存エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの保存中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  // ===================================
  // テンプレート読み込み (カラムテンプレ用)
  // ===================================
  const handleLoadTemplate = async (templateId) => {
    if (!templateId) {
      setSelectedTemplateId('');
      setTemplateName('');
      setCurrentTemplateName('');
      setCustomFilters([]);
      setSentencesToDelete([]);
      setWordsToReplace([]);
      setMandatoryKeywords([]);
      setSelectedFilters([]);
      return;
    }

    setSelectedTemplateId(templateId);
    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
      const templateDocRef = doc(userTemplatesRef, templateId);
      const templateDoc = await getDoc(templateDocRef);
      if (templateDoc.exists()) {
        const tmpl = templateDoc.data();
        setTemplateName(tmpl.name || '');
        setCurrentTemplateName(tmpl.name || '');
        setCustomFilters(tmpl.customFilters || []);
        setSentencesToDelete(tmpl.sentencesToDelete || []);
        setWordsToReplace(tmpl.wordsToReplace || []);
        setMandatoryKeywords(tmpl.mandatoryKeywords || []);
        setSelectedFilters(tmpl.selectedFilters || []);
        setSnackbar({
          open: true,
          message: 'テンプレートを読み込みました',
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'テンプレートが見つかりません',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('テンプレート読み込み中エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレート読み込み中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  // ===================================
  // AI商品説明用 テンプレ読み込み
  // ===================================
  const handleLoadAiDescTemplate = async (templateId) => {
    if (!templateId) {
      setSelectedAiDescTemplateId('');
      setAiDescTemplateData(null);
      return;
    }

    setTemplateLoading(true);
    setSelectedAiDescTemplateId(templateId);

    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
      const docRef = doc(userTemplatesRef, templateId);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        setAiDescTemplateData(data);

        // ローカルステートを上書き (削除ワード等)
        setCustomFilters(data.customFilters || []);
        setSentencesToDelete(data.sentencesToDelete || []);
        setWordsToReplace(data.wordsToReplace || []);
        setMandatoryKeywords(data.mandatoryKeywords || []);
        setSelectedFilters(data.selectedFilters || []);
        setSelectedDesignTemplate(data.selectedTemplate || "");
        

        setSnackbar({
          open: true,
          message: 'AI説明用テンプレートを読み込みました',
          severity: 'success',
        });
      } else {
        setAiDescTemplateData(null);
        setSnackbar({
          open: true,
          message: 'AI説明用テンプレートが見つかりません',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('AI説明用テンプレート詳細取得エラー:', error);
      setSnackbar({
        open: true,
        message: 'AI説明用テンプレート取得中にエラーが発生しました',
        severity: 'error',
      });
    } finally {
      setTemplateLoading(false);
    }
  };

  // ===================================
  // バナー設定取得用の関数
  // ===================================
const fetchBannerSettings = async () => {
  if (!user || !user.uid) {
    console.error("ユーザーが認証されていません");
    return;
  }

  try {
    const docRef = doc(db, "users", user.uid); // "users" コレクションから取得
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const userSettingsData = docSnap.data();
      console.log("Firestoreから取得したバナー設定:", userSettingsData);

      setBannerEnabled(userSettingsData.bannerEnabled ?? false);
      setBannerUrl(userSettingsData.bannerUrl ?? '');
      setPicInsertEnabled(userSettingsData.picInsertEnabled ?? false);
    } else {
      console.warn("ユーザー設定が見つかりませんでした。");
    }
  } catch (error) {
    console.error("Firestoreからの設定取得エラー:", error);
  }
};

// ユーザー情報が更新されたときにバナー設定を取得する
useEffect(() => {
  if (user && user.uid) {
    fetchBannerSettings();
  }
}, [user]);


  // テンプレート削除
  // ===================================
  const handleDeleteTemplate = async (templateId) => {
    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
      const templateDocRef = doc(userTemplatesRef, templateId);
      await deleteDoc(templateDocRef);

      setSnackbar({
        open: true,
        message: 'テンプレートが削除されました',
        severity: 'success',
      });

      const templatesSnapshot = await getDocs(userTemplatesRef);
      const newData = templatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(newData);
    } catch (error) {
      console.error('テンプレート削除エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレート削除中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  // ===================================
  // パターン適用
  // ===================================
  const applyPatternSettings = (pattern) => {
    if (!pattern || !pattern.settings) {
      setSnackbar({
        open: true,
        message: '選択されたパターンが正しくありません。',
        severity: 'error',
      });
      return;
    }
  
    const settings = pattern.settings;
  
    // テンプレート設定: テンプレート名をセット
    if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
      setSelectedTemplateName(settings.applyTemplate.selectedTemplateName);
    }
  
    // 価格変更設定
    if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
      setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
      setSelectedPriceSettings((prev) => ({
        ...prev,
        selectedShippingTemplateId: settings.applyPriceChanges.selectedShippingTemplateId,
      }));
    }
  
    // AIタイトル生成の設定
    if (settings.applyAITitles) {
      const aiTitle = settings.applyAITitles;
      // ここで保存されたカテゴリー名を UI にセット
      setSelectedCategory(aiTitle.selectedCategory || '');
      setDeleteStrings(aiTitle.deleteStrings || []);
      setReplacePairs(aiTitle.replacePairs || []);
      setPrependText(aiTitle.prependText || '');
      setAppendText(aiTitle.appendText || '');
      setAdditionalPrompt(aiTitle.additionalPrompt || '');
      setLimitTitleLength(aiTitle.limitTitleLength || false);
      setCustomCategories(aiTitle.customCategories || []);
    }
  
    if (settings.applyAIProductDescriptions) {
      const aiDesc = settings.applyAIProductDescriptions;
      setSelectedAiDescTemplateId(aiDesc.selectedAiDescTemplateId || '');
      // ここで、選択されたテンプレートIDが変更されたことをトリガーとして、テンプレート詳細を取得する
      if (aiDesc.selectedAiDescTemplateId) {
        handleLoadAiDescTemplate(aiDesc.selectedAiDescTemplateId);
      }
      setCustomFilters(aiDesc.customFilters || []);
      setSentencesToDelete(aiDesc.sentencesToDelete || []);
      setWordsToReplace(aiDesc.wordsToReplace || []);
      setMandatoryKeywords(aiDesc.mandatoryKeywords || []);
      setSelectedFilters(aiDesc.selectedFilters || []);
      setSelectedDesignTemplate(aiDesc.selectedTemplate || "");
      setBannerEnabled(aiDesc.bannerEnabled || false);
      setBannerUrl(aiDesc.bannerUrl || '');
      setPicInsertEnabled(aiDesc.picInsertEnabled || false);
    }
    
  
    // Item Specifics の設定（必要なら）
    if (settings.applyItemSpecifics) {
      setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory || '');
      setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
      setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || {});
      setOverwriteExisting(settings.applyItemSpecifics.overwriteExisting || false);
    }
  
    // 機能リストの更新
    const newSelectedFunctions = [];
    if (settings.applyTemplate) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyTemplate'));
    }
    if (settings.applyPriceChanges) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyPriceChanges'));
    }
    if (settings.applyAITitles) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyAITitles'));
    }
    if (settings.applyAIProductDescriptions) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyAIProductDescriptions'));
    }
    if (settings.applyItemSpecifics) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyItemSpecifics'));
    }
    setSelectedFunctions(newSelectedFunctions);
  
    setSnackbar({
      open: true,
      message: `パターン "${pattern.name}" が適用されました。`,
      severity: 'success',
    });
  
    console.log('適用後のパターン内容:', settings);
  };
  

  // ===================================
  // パターン保存
  // ===================================
// 例: パターンをサブコレクションから取得する関数
const fetchPatterns = async (userUid, setPatterns) => {
  try {
    const patternsRef = collection(db, 'users', userUid, 'patterns');
    const snapshot = await getDocs(patternsRef);
    const patternsData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPatterns(patternsData);
  } catch (error) {
    console.error('パターン取得エラー:', error);
  }
};


  const saveCurrentSettingsAsPattern = async () => {
    if (!newPatternName.trim()) {
      setSnackbar({
        open: true,
        message: 'パターン名を入力してください',
        severity: 'warning',
      });
      return;
    }
  
    const newPattern = {
      name: newPatternName.trim(),
      settings: {}
    };
  
    // テンプレート設定（applyTemplate）: テンプレート名を保存
    if (selectedFunctions.some((f) => f.name === 'applyTemplate')) {
      newPattern.settings.applyTemplate = { selectedTemplateName };
    }
  
    // 価格変更設定（applyPriceChanges）
    if (selectedFunctions.some((f) => f.name === 'applyPriceChanges')) {
      newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
    }
  
    // AIタイトル生成の設定（applyAITitles）
    if (selectedFunctions.some((f) => f.name === 'applyAITitles')) {
      newPattern.settings.applyAITitles = {
        // ユーザーが選択したカテゴリー名も保存する
        selectedCategory,         // 例：'レディースファッション'
        deleteStrings,            // 配列
        replacePairs,             // 配列
        prependText,              // 文字列
        appendText,               // 文字列
        additionalPrompt,         // 文字列
        limitTitleLength,         // boolean
        customCategories,         // カテゴリ一覧（各カテゴリの詳細設定オブジェクトの配列）
      };
    }
  
    // AI商品説明生成の設定（applyAIProductDescriptions）
    if (selectedFunctions.some((f) => f.name === 'applyAIProductDescriptions')) {
      newPattern.settings.applyAIProductDescriptions = {
        selectedAiDescTemplateId,
        customFilters,       
        sentencesToDelete,   
        wordsToReplace,      
        mandatoryKeywords,   
        selectedFilters,
        selectedTemplate,
        bannerEnabled,
        bannerUrl,
        picInsertEnabled,
      };
    }
  
    // Item Specifics の設定（必要なら）
    if (selectedFunctions.some((f) => f.name === 'applyItemSpecifics')) {
      newPattern.settings.applyItemSpecifics = {
        selectedItemSpecificsCategory,
        selectedItemSpecificsColumns,
        itemSpecificsMatchingOptions,
        overwriteExisting,
      };
    }
  
    console.log('保存するパターン:', newPattern); // デバッグ用に保存内容を確認
  
    try {
      const patternsRef = collection(db, 'users', user.uid, 'patterns');
  
      // ここで同名のパターンが存在するかのチェックを追加する場合はクエリで検索する
      // 簡易的にはそのまま追加する例
      await addDoc(patternsRef, newPattern);
  
      // 追加後、再取得して state を更新
      const snapshot = await getDocs(patternsRef);
      const patternsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPatterns(patternsData);
  
      setIsSavePatternDialogOpen(false);
      setNewPatternName('');
      setSnackbar({
        open: true,
        message: 'パターンが保存されました。',
        severity: 'success',
      });
    } catch (error) {
      console.error('パターン保存中エラー:', error);
      setSnackbar({
        open: true,
        message: 'パターンの保存中にエラーが発生しました',
        severity: 'error',
      });
    }
  };
  

  // ===================================
  // パターン削除
  // ===================================
  const handleDeletePattern = async (patternId) => {
    try {
      const patternDocRef = doc(db, 'users', user.uid, 'patterns', patternId);
      await deleteDoc(patternDocRef);
  
      // 削除後、再度パターン一覧を取得して state を更新
      const patternsRef = collection(db, 'users', user.uid, 'patterns');
      const snapshot = await getDocs(patternsRef);
      const patternsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPatterns(patternsData);
  
      setSnackbar({
        open: true,
        message: 'パターンが削除されました。',
        severity: 'success',
      });
    } catch (error) {
      console.error('パターン削除エラー:', error);
      setSnackbar({
        open: true,
        message: 'パターンの削除中にエラーが発生しました。',
        severity: 'error',
      });
    }
  };

  // ===================================
  // 実行ボタン (Item Specifics 以外の機能)
  // ===================================
  const handleUploadAndExecute = async () => {
    // 1) ログイン済みかチェック
    if (!user) {
      setSnackbar({
        open: true,
        message: 'ユーザーが認証されていません。',
        severity: 'error',
      });
      return;
    }

    // 2) ファイルあるか
    if (csvFiles.length === 0) {
      setSnackbar({
        open: true,
        message: 'アップロードするファイルがありません。',
        severity: 'warning',
      });
      return;
    }

    // 3) 機能が選択されているか
    if (selectedFunctions.length === 0) {
      setSnackbar({
        open: true,
        message: '実行する機能を選択してください。',
        severity: 'warning',
      });
      return;
    }

    // 4) テンプレロード中かどうか
    if (templateLoading) {
      setSnackbar({
        open: true,
        message: '現在テンプレート反映中です。完了をお待ちください。',
        severity: 'warning',
      });
      return;
    }

    setIsScheduling(true);

    try {
      const filesData = csvFiles.map((file, index) => ({
        name: file.name,
        content: fileContents[index],
      }));

      // ここで selectedFunctions 配列に「applyImageAnalysis」があれば、必ず先頭に移動
      let functionsToExecute = [...selectedFunctions];
      const imageAnalysisIndex = functionsToExecute.findIndex(
        (f) => f.name === "applyImageAnalysis"
      );
      if (imageAnalysisIndex > 0) {
        const [imageFunc] = functionsToExecute.splice(imageAnalysisIndex, 1);
        functionsToExecute.unshift(imageFunc);
      }

      // 選択された機能を順に実行
      const executeFunctions = async () => {
        let currentData = filesData;

        for (const func of functionsToExecute) {
          try {
            if (func.name === 'applyAITitles') {
              // 各ファイルに対して、apiKeys 配列からキーを割り当てて並行実行
              const results = await Promise.all(
                currentData.map((file, idx) => {
                  // 例: 複数キーを保持している state（apiKeys）がある場合、インデックスを使ってキーを選択
                  const keyForFile = apiKeys && apiKeys.length > 0 ? apiKeys[idx % apiKeys.length] : apiKey;
                  return applyAITitles(
                    [file],             // 単一ファイルを配列に包む
                    keyForFile,
                    (prog) => setProgress(prog),
                    {
                      deleteStrings,
                      replacePairs,
                      prependText,
                      appendText,
                      limitTitleLength,
                      additionalPrompt,
                      useImageAnalysis: useImageAnalysisForTitle, // 【変更】追加
                    },
                    apiKeys            // 複数キーがある場合の情報も渡す（必要なら）
                  );
                })
              );
              // 各ファイルの処理結果を統合
              currentData = results.flatMap(result => result.updatedData);
              setErrorItems(results.flatMap(result => result.errorItems || []));
            }
             else if (func.name === 'applyPriceChanges') {
              // 価格機能
              // 実行直前に最新 state をマージ
              const updatedSettings = {
                ...selectedPriceSettings,
                selectedShippingTemplateId,
              };
              console.log("★★ applyPriceChanges直前 updatedSettings=", updatedSettings);

              // 呼び出し
              currentData = await applyPriceChanges(
                currentData,
                updatedSettings,
                setSnackbar
              );

            } else if (func.name === 'applyTemplate') {
              // 従来テンプレート
              currentData = await applyTemplate(currentData, user, selectedTemplateName);

            } else if (func.name === 'applyAIProductDescriptions') {
              // AI商品説明生成
              const result = await applyAIProductDescriptions(
                currentData,
                apiKey,
                (prog) => setProgress(prog),
                selectedTemplate,
                {
                  sentencesToDelete,
                  wordsToReplace,
                  mandatoryKeywords,
                  customFilters,
                  selectedFilters,
                  selectedTemplate,
                  bannerEnabled,
                  bannerUrl,
                  picInsertEnabled,
                  setSnackbar,
                  imageAnalysisStrategy,
                }
              );
              currentData = result.updatedData;
              setErrorItems(result.errorItems || []);
            }
            else if (func.name === "applyImageAnalysis") {
              // 画像解析の処理をモードに応じて呼び出す
              if (imageAnalysisMode === "emptyOnly") {
                currentData = await applyImageAnalysisIfEmpty(currentData, apiKey, setSnackbar);
              } else if (imageAnalysisMode === "all") {
                currentData = await applyImageAnalysisForAll(currentData, apiKey, setSnackbar);
              }
            }
            // Item Specifics は別ボタンで適用
          } catch (err) {
            console.error(`機能 "${func.displayName}" 実行中エラー:`, err);
            setSnackbar({
              open: true,
              message: `機能 "${func.displayName}" 実行中にエラーが発生しました。`,
              severity: 'error',
            });
            setIsScheduling(false);
            return;
          }
        }

        setProcessedData(currentData);
        setSnackbar({
          open: true,
          message: '選択された機能を実行しました。',
          severity: 'success',
        });
        setIsScheduling(false);
      };

      // 即時実行 or スケジュール実行
      if (isImmediate) {
        await executeFunctions();
      } else {
        const now = new Date();
        const scheduledDateTime = new Date(scheduledTime);
        const delay = scheduledDateTime - now;
        if (delay > 0) {
          setSnackbar({
            open: true,
            message: 'タスクがスケジュールされました。',
            severity: 'success',
          });
          setTimeout(async () => {
            await executeFunctions();
          }, delay);
        } else {
          setSnackbar({
            open: true,
            message: '指定された時間は過去です。正しい時間を選択してください。',
            severity: 'error',
          });
          setIsScheduling(false);
        }
      }
    } catch (error) {
      console.error('処理中エラー:', error);
      setSnackbar({
        open: true,
        message: '処理中にエラーが発生しました。',
        severity: 'error',
      });
      setIsScheduling(false);
    }
  };

  // ===================================
  // Item Specifics を別ボタンで適用する関数
  // ===================================
  const handleApplyItemSpecifics = async () => {
    if (!user) {
      setSnackbar({
        open: true,
        message: 'ユーザーが認証されていません。',
        severity: 'error',
      });
      return;
    }

    try {
      let targetData = processedData.length > 0
        ? processedData
        : csvFiles.map((file, idx) => ({
            name: file.name,
            content: fileContents[idx],
          }));

      const updated = await applyItemSpecificsWrapper(
        targetData,
        user.uid,
        {
          selectedItemSpecificsCategory,
          selectedItemSpecificsColumns,
          itemSpecificsMatchingOptions,
          overwriteExisting,
          setSnackbar,
          setProgress,
        }
      );

      setProcessedData(updated);
      setSnackbar({
        open: true,
        message: 'Item Specificsを適用しました。',
        severity: 'success',
      });
    } catch (err) {
      console.error('Item Specifics適用中エラー:', err);
      setSnackbar({
        open: true,
        message: 'Item Specifics適用中にエラーが発生しました。',
        severity: 'error',
      });
    }
  };

  const handleApplyTruncation = () => {
    if (processedData.length === 0) {
      setSnackbar({
        open: true,
        message: '処理後のデータがありません。',
        severity: 'warning',
      });
      return;
    }
    
    const newData = processedData.map((file) => {
      const parsedResult = Papa.parse(file.content, { header: true });
      const newRows = parsedResult.data.map((row) => {
        // 各行の "C:" で始まるカラムに対して64文字以上なら切り詰め
        const newRow = { ...row };
        parsedResult.meta.fields.forEach((field) => {
          if (field.startsWith('C:') && typeof newRow[field] === 'string') {
            if (newRow[field].length > 64) {
              newRow[field] = newRow[field].substring(0, 64);
            }
          }
        });
        return newRow;
      });
      
      return {
        ...file,
        content: Papa.unparse(newRows),
      };
    });
    
    setProcessedData(newData);
    setSnackbar({
      open: true,
      message: '64文字カットを再適用しました。',
      severity: 'success',
    });
  };
  

  // ===================================
  // 再置換ボタンのハンドラ
  // ===================================
  const handleRefineDescription = () => {
    if (processedData.length === 0) {
      setSnackbar({
        open: true,
        message: 'まだ処理後のデータがありません。',
        severity: 'warning',
      });
      return;
    }

    const newProcessedData = processedData.map((file) => {
      const parsedResult = Papa.parse(file.content, { header: true });
      const rows = parsedResult.data;

      const updatedRows = rows.map((row) => {
        const originalDesc = row.Description || '';
        const refinedHtml = transformFinalHTMLWithDOMParser(
          originalDesc,
          sentencesToDelete,
          wordsToReplace
        );
        return { ...row, Description: refinedHtml };
      });

      const updatedCsv = Papa.unparse(updatedRows);
      return {
        ...file,
        content: updatedCsv,
      };
    });

    setProcessedData(newProcessedData);
    setSnackbar({
      open: true,
      message: '再置換を適用しました！',
      severity: 'success',
    });
  };

  // ===================================
  // プレビュー & ダウンロード
  // ===================================
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPreviewCurrentPage(1); // タブ変更時にプレビューのページを1にリセットする
  };

  const handlePreviewRow = (row) => {
    setPreviewRowData(row);
    setPreviewDialogOpen(true);
  };

  const handleDeleteRow = (actualRowIndex, fileIndex) => {
    setProcessedParsedData((prev) => {
      const newData = [...prev];
      const targetFile = newData[fileIndex];
      const newRows = [...targetFile.data];
      newRows.splice(actualRowIndex, 1);
      targetFile.data = newRows;
      newData[fileIndex] = targetFile;
      return newData;
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
  position="static"
  sx={{
    backgroundColor: 'white',
    border: '2px solid #2196f3',
    boxShadow: 'none',
    color: 'black',
    height: 50, // AppBarの高さを50pxに設定
  }}
>
  <Toolbar sx={{ height: '100%', alignItems: 'center' }}>
    <IconButton edge="start" color="inherit" onClick={() => navigate('/')} sx={{ mr: 1 }}>
      <HomeIcon />
    </IconButton>
    <Typography variant="h6" component="div">
      自動編集機能
    </Typography>
  </Toolbar>
</AppBar>


      {/* メインエリア */}
      <Box sx={{ px: 1, mt: 1 }}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {/* (1) ファイルを選択 */}
            <Grid item>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" component="label">
                  ファイルを選択（最大5つまで）
                  <input
                    key={fileInputKey}
                    type="file"
                    accept=".csv"
                    multiple
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                
                <Button variant="outlined" onClick={resetUploader}>
                  リセット
                </Button>
              </Box>
            </Grid>


            {/* (2) 実行する機能を選択 */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<MenuIcon />}
                onClick={handleFunctionDialogOpen}
              >
                実行する機能を選択
              </Button>
            </Grid>

            {/* (3) パターンを選択 */}
            <Grid item>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <InputLabel>パターンを選択</InputLabel>
                <Select
                  value={selectedPatternName}
                  label="パターンを選択"
                  onChange={(e) => {
                    const name = e.target.value;
                    setSelectedPatternName(name);
                    const pattern = patterns.find((p) => p.name === name);
                    applyPatternSettings(pattern);
                  }}
                >
                  <MenuItem value="">
                    <em>パターンを選択</em>
                  </MenuItem>
                  {patterns.map((ptn) => (
                    <MenuItem key={ptn.name} value={ptn.name}>
                      {ptn.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* (4) 現在の設定をパターンとして保存 */}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsSavePatternDialogOpen(true)}
              >
                現在の設定をパターンとして保存
              </Button>
            </Grid>

            {/* (5) パターンを管理 */}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsManagePatternsDialogOpen(true)}
              >
                パターンを管理
              </Button>
            </Grid>

            {/* (6) アップロードして実行 */}
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUploadAndExecute}
                disabled={isScheduling}
              >
                {isScheduling ? '処理中...' : 'アップロードして実行'}
              </Button>
            </Grid>
          </Grid>

          {/* ファイル一覧や進捗表示などは省略せずそのまま */}
          {csvFiles.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">選択されたファイル:</Typography>
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap',
                  gap: 2
                }}
              >
                {csvFiles.map((file, idx) => {
                  const numberSymbol = String.fromCodePoint(0x2460 + idx); 
                  return (
                    <Typography key={idx} variant="body1">
                      {numberSymbol} {file.name}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          )}

          {isScheduling && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">処理進捗:</Typography>
              <Typography>
                {progress.completedItems} / {progress.totalItems} 件が完了しました
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  progress.totalItems > 0
                    ? (progress.completedItems / progress.totalItems) * 100
                    : 0
                }
              />
            </Box>
          )}
        </Paper>
      </Box>

            {/* (1) & (2): applyTemplate と 価格設定 */}
            <Box sx={{ px: 2, mt: 2 }}>
        <Grid container spacing={2}>
          {selectedFunctions.some((f) => f.name === 'applyTemplate') && (
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">1. テンプレート適用</Typography>
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>テンプレートを選択</InputLabel>
                    <Select
                      value={selectedTemplateName}
                      onChange={(e) => setSelectedTemplateName(e.target.value)}
                    >
                      {templateList.map((tmpl, idx) => (
                        <MenuItem key={idx} value={tmpl.name}>
                          {tmpl.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {selectedTemplateName && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {selectedTemplateName} の詳細
                    </Typography>
                    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                    {(() => {
                      const sel = templateList.find((t) => t.name === selectedTemplateName);
                      if (sel && sel.operations && sel.operations.length > 0) {
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {sel.operations.map((op, i) => (
                              <Paper key={i} sx={{ p: 1, width: '120px' }}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: '0.8rem',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    mb: 0.5,
                                  }}
                                >
                                  {op.columnName || op.title || 'NoTitle'}
                                </Typography>
                                <Typography variant="caption">
                                {op.action}{op.value ? ` : ${op.value}` : ''}
                                </Typography>
                              </Paper>
                            ))}
                          </Box>
                        );
                      } else {
                        return <Typography>操作がありません。</Typography>;
                      }
                    })()}
                  </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          )}

          {selectedFunctions.some((f) => f.name === 'applyPriceChanges') && (
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">2. 価格機能</Typography>
                <Box sx={{ mt: 1 }}>
                  {shippingRateTemplates.length > 0 && (
                    <FormControl fullWidth>
                    <InputLabel>送料設定を選択</InputLabel>
                    <Select
                      value={selectedShippingTemplateId}
                      onChange={async (e) => {
                        const newId = e.target.value;
                        console.log('★★ shippingTemplate onChange newId =', newId);
                  
                        // 1) ローカル state を更新
                        setSelectedShippingTemplateId(newId);
                  
                        // 2) selectedPriceSettings も同時に更新し、IDを同期
                        setSelectedPriceSettings((prev) => ({
                          ...prev,
                          selectedShippingTemplateId: newId,
                        }));
                  
                        // 3) Firestoreに保存したいならこちら
                        await setDoc(doc(db, 'userSettings', user.uid), {
                          selectedShippingTemplateId: newId
                        }, { merge: true });
                  
                        console.log('★★ state更新完了: selectedShippingTemplateId & selectedPriceSettings');
                      }}
                    >
                      {shippingRateTemplates.map((tpl) => (
                        <MenuItem key={tpl.id} value={tpl.id}>
                          {tpl.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>                  
                  )}
                </Box>

                <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {/* 左カラム：現在の価格設定（既存の項目） */}
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1">現在の価格設定</Typography>
                        <Button variant="outlined" size="small" onClick={reloadPriceSettings}>
                          最新設定を読み込む
                        </Button>
                      </Box>
                        <Typography>為替レート: {exchangeRate} (円/ドル)</Typography>
                        <Typography>目標利益率: {targetProfitMargin}%</Typography>
                        <Typography>
                          eBay最終価値手数料: {fees.ebayFinalValue || 0}%
                        </Typography>
                        <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
                        <Typography>
                          広告出品手数料: {fees.promotedListing || 0}%
                        </Typography>
                        <Typography>
                          eBay取引手数料: {fees.ebayTransactionFee || 0} USD
                        </Typography>
                        <Typography>
                          ベストオファー自動承認: {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
                        </Typography>
                        <Typography>
                          最小ベストオファー: {bestOfferSettings.minimumBestOfferPercentage || 0}%
                        </Typography>
                    </Box>
                  </Grid>

                  {/* 右カラム：新しく追加する送料テンプレートの詳細 */}
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography variant="subtitle1">送料テンプレート詳細</Typography>
                      {selectedShippingTemplate ? (
                        selectedShippingTemplate.shippingRates.map((rate, index) => (
                          <Box key={index} sx={{ mb: 1, borderBottom: '1px solid #ddd', pb: 1 }}>
                            <Typography variant="body2">
                              送料 (円): {rate.costJPY}, 送料 ($): {rate.costUSD}
                            </Typography>
                            <Typography variant="body2">
                              最小 (円): {rate.minPriceJPY}, 最大 (円): {rate.maxPriceJPY}
                            </Typography>
                            <Typography variant="body2">
                              最小 ($): {rate.minPriceUSD}, 最大 ($): {rate.maxPriceUSD}
                            </Typography>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body2">
                          テンプレートが選択されていません。
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              </Paper>
            </Grid>
          )}
          
        </Grid>
      </Box>

     {/** 3 と 4 を横並びにする例 */}
{(selectedFunctions.some((f) => f.name === 'applyAITitles') ||
  selectedFunctions.some((f) => f.name === 'applyAIProductDescriptions')) && (
  <Box sx={{ px: 2, mt: 2 }}>
    <Grid container spacing={2}>

      {/* (3) AIタイトル生成 */}
      {selectedFunctions.some((f) => f.name === 'applyAITitles') && (
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">3. AIタイトル生成の設定</Typography>
            <Box sx={{ mt: 2, mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel>カテゴリーを選択</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => {
                    const val = e.target.value;
                    setSelectedCategory(val);
                    const cat = customCategories.find((c) => c.name === val);
                    if (cat) {
                      setDeleteStrings(cat.deleteStrings || []);
                      setReplacePairs(cat.replacePairs || []);
                      setPrependText(cat.prependText || '');
                      setAppendText(cat.appendText || '');
                      setAdditionalPrompt(cat.additionalPrompt || '');
                      setLimitTitleLength(cat.limitTitleLength || false);
                    }
                  }}
                >
                  <MenuItem value="">
                    <em>（未選択）</em>
                  </MenuItem>
                  {customCategories.map((cat) => (
                    <MenuItem key={cat.name} value={cat.name}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">追加したいプロンプト</Typography>
              <TextField
                value={additionalPrompt}
                onChange={(e) => setAdditionalPrompt(e.target.value)}
                placeholder="AIに付加的に指示したい内容"
                multiline
                rows={4}
                fullWidth
                sx={{ mt: 1 }}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <TextField
                label="削除文字列 (カンマ区切り)"
                value={deleteStrings.join(', ')}
                onChange={(e) =>
                  setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
                }
                fullWidth
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle2">置換ペア</Typography>
              {replacePairs.map((pair, idx) => (
                <Box
                  key={idx}
                  sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}
                >
                  <TextField
                    label="置換前"
                    value={pair.find}
                    onChange={(e) => {
                      const newPairs = [...replacePairs];
                      newPairs[idx] = { ...newPairs[idx], from: e.target.value };
                      setReplacePairs(newPairs);
                    }}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="置換後"
                    value={pair.replace}
                    onChange={(e) => {
                      const newPairs = [...replacePairs];
                      newPairs[idx] = { ...newPairs[idx], to: e.target.value };
                      setReplacePairs(newPairs);
                    }}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                  <IconButton
                    onClick={() => {
                      setReplacePairs(replacePairs.filter((_, i) => i !== idx));
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                variant="outlined"
                onClick={() => setReplacePairs([...replacePairs, { from: '', to: '' }])}
              >
                置換ペアを追加
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              <TextField
                label="先頭に追加する文字列"
                value={prependText}
                onChange={(e) => setPrependText(e.target.value)}
                fullWidth
                sx={{ mb: 1 }}
              />
              <TextField
                label="末尾に追加する文字列"
                value={appendText}
                onChange={(e) => setAppendText(e.target.value)}
                fullWidth
              />
            </Box>

            <FormControlLabel
              control={
                <Switch
                  checked={limitTitleLength}
                  onChange={(e) => setLimitTitleLength(e.target.checked)}
                />
              }
              label="80文字に制限する"
              sx={{ mt: 2 }}
            />

            {/* 【変更】画像解析結果をタイトル生成に利用するオプション */}
            <FormControlLabel
              control={
                <Switch
                  checked={useImageAnalysisForTitle}
                  onChange={(e) => setUseImageAnalysisForTitle(e.target.checked)}
                />
              }
              label="画像解析結果を利用する"
              sx={{ mt: 2 }}
            />
          </Paper>
        </Grid>
      )}

      {/* (4) AI商品説明生成 */}
      {selectedFunctions.some((f) => f.name === 'applyAIProductDescriptions') && (
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              4. AI商品説明生成の設定
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">AI説明用テンプレート</Typography>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel>テンプレートを選択</InputLabel>
                <Select
                  value={selectedAiDescTemplateId}
                  label="テンプレートを選択"
                  onChange={(e) => {
                    const tid = e.target.value;
                    handleLoadAiDescTemplate(tid);
                  }}
                >
                  <MenuItem value="">
                    <em>未選択</em>
                  </MenuItem>
                  {templates.map((tpl) => (
                    <MenuItem key={tpl.id} value={tpl.id}>
                      {tpl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {templateLoading && (
              <Alert severity="info" sx={{ mt: 2 }}>
                テンプレート反映中です...
              </Alert>
            )}

            {selectedAiDescTemplateId && aiDescTemplateData && !templateLoading && (
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  border: '1px solid #ddd',
                  borderRadius: 1,
                  backgroundColor: '#fafafa',
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  選択中のAI説明テンプレート : {aiDescTemplateData.name}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      独自フィルタ (customFilters)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'pre-wrap', mt: 1 }}
                    >
                      <TruncatedText data={aiDescTemplateData.customFilters} maxLines={6} />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      削除する文章 (sentencesToDelete)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'pre-wrap', mt: 1 }}
                    >
                      <TruncatedText data={aiDescTemplateData.sentencesToDelete} maxLines={6} />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      置換ワード (wordsToReplace)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'pre-wrap', mt: 1 }}
                    >
                      <TruncatedText data={aiDescTemplateData.wordsToReplace} maxLines={6} />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      必須キーワード (mandatoryKeywords)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'pre-wrap', mt: 1 }}
                    >
                      <TruncatedText data={aiDescTemplateData.mandatoryKeywords} maxLines={6} />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      選択中フィルタ (selectedFilters)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'pre-wrap', mt: 1 }}
                    >
                      <TruncatedText data={aiDescTemplateData.selectedFilters} maxLines={6} />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      デザインテンプレート (selectedTemplate)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ whiteSpace: 'pre-wrap', mt: 1 }}
                    >
                      <TruncatedText data={aiDescTemplateData.selectedTemplate} maxLines={6} />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* ここで「生成に使用する元カラム」を選択するUIを追加 */}
            <Box sx={{ mt: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
              <Typography variant="subtitle1" gutterBottom>
                AI商品説明生成に使用する元カラムの選択
              </Typography>
              <RadioGroup
                row
                value={imageAnalysisStrategy}
                onChange={(e) => setImageAnalysisStrategy(e.target.value)}
              >
                <FormControlLabel
                  value="preferExisting"
                  control={<Radio />}
                  label={
                    "日本語商品説明を使用（既存の説明があればそちらを優先し、空の場合のみを画像解析結果を利用）"
                  }
                />
                <FormControlLabel
                  value="alwaysUseImage"
                  control={<Radio />}
                  label="常に画像解析結果を使用する"
                />
              </RadioGroup>
            </Box>

            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              ※ テンプレート選択時にローカルステートを上書き → AI説明生成で反映されます
            </Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  </Box>
)}


<Box sx={{ px: 2, mt: 2 }}>
  <Grid container spacing={2}>
     {/* 左カラム：画像解析と除外ワードテンプレートを上下に分割 */}
     <Grid item xs={12} md={6}>
      <Grid container direction="column" spacing={2}>
        {/* 上半分：画像解析の設定UI */}
        <Grid item>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              画像解析の設定
            </Typography>
            {/* 画像解析モードを選択する例 */}
            <RadioGroup
              row
              value={imageAnalysisMode}
              onChange={(e) => setImageAnalysisMode(e.target.value)}
            >
              <FormControlLabel
                value="emptyOnly"
                control={<Radio />}
                label="商品説明が空の場合のみ解析"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="全商品を解析"
              />
            </RadioGroup>
          </Paper>
        </Grid>
        {/* 下半分：除外ワードテンプレートUI */}
        <Grid item>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              除外ワードテンプレートを選択
              <br />
              ※自動実行適用後に、こちらは手動で適用してください
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel id="delete-word-template-select-label">
                  テンプレートを選択
                </InputLabel>
                <Select
                  labelId="delete-word-template-select-label"
                  value={selectedDeleteWordTemplateId}
                  label="テンプレートを選択"
                  onChange={(e) => setSelectedDeleteWordTemplateId(e.target.value)}
                >
                  <MenuItem value="">
                    <em>-- 未選択 --</em>
                  </MenuItem>
                  {deleteWordTemplates.map((tpl) => (
                    <MenuItem key={tpl.id} value={tpl.name}>
                      {tpl.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplySelectedDeleteWordTemplate}
                disabled={!selectedDeleteWordTemplateId}
              >
                テンプレートを適用
              </Button>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteRowsByExclusionWords}
              >
                除外ワードで行を除外
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (backupProcessedData.length > 0) {
                    setProcessedData(backupProcessedData);
                    setBackupProcessedData([]);
                    setExclusionLog([]);
                    setSnackbar({
                      open: true,
                      message: "除外処理をリセットしました。",
                      severity: "info",
                    });
                  } else {
                    setSnackbar({
                      open: true,
                      message: "リセットできる状態がありません。",
                      severity: "warning",
                    });
                  }
                }}
              >
                除外処理をリセット
              </Button>
              <Button
                variant="outlined"
                onClick={() => setIsExclusionLogOpen(true)}
              >
                除外された商品を確認
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>

    {/* 右カラム: Item Specifics 適用 */}
    <Grid item xs={12} md={6}>
      <Paper sx={{ p: 2 }}>
        <Divider textAlign="left" sx={{ my: 0, fontSize: '1rem' }}>
          Item Specifics 適用 ※上記の自動実行適用後に、こちらは手動で適用してください
        </Divider>

        {/* ▼ Item Specifics カテゴリ選択 */}
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Item Specifics カテゴリ</InputLabel>
              <Select
                value={selectedItemSpecificsCategory}
                onChange={(e) => {
                  const newCategory = e.target.value;
                  setSelectedItemSpecificsCategory(newCategory);
                  // 選択されたカテゴリがあれば、そのカテゴリの全カラムを自動選択
                  const allColumns =
                    newCategory &&
                    itemSpecificsCategories[newCategory] &&
                    itemSpecificsCategories[newCategory].columns
                      ? Object.keys(itemSpecificsCategories[newCategory].columns)
                      : [];
                  setSelectedItemSpecificsColumns(allColumns);
                }}
              >
              <MenuItem value="">
                <em>未選択</em>
              </MenuItem>
              {Object.keys(itemSpecificsCategories).map((categoryKey) => (
                <MenuItem key={categoryKey} value={categoryKey}>
                  {categoryKey}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* ▼ 適用カラム選択 (複数) */}
        {selectedItemSpecificsCategory && (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>適用するカラムを選択</InputLabel>
              <Select
                multiple
                value={selectedItemSpecificsColumns}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.includes('all')) {
                    const allColumns = getColumnsForSelectedCategory();
                    if (
                      selectedItemSpecificsColumns.length === allColumns.length
                    ) {
                      // 全部入っている → 全解除
                      setSelectedItemSpecificsColumns([]);
                    } else {
                      // 全部は入っていない → 全部入れる
                      setSelectedItemSpecificsColumns(allColumns);
                    }
                  } else {
                    setSelectedItemSpecificsColumns(value);
                  }
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return '適用するカラムを選択';
                  }
                  const displayedValues = selected.slice(0, 5);
                  const hiddenCount = selected.length - displayedValues.length;
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {displayedValues.map((val) => (
                        <Paper
                          key={val}
                          sx={{
                            px: 1,
                            borderRadius: '8px',
                            backgroundColor: '#e0e0e0',
                          }}
                        >
                          {val}
                        </Paper>
                      ))}
                      {hiddenCount > 0 && (
                        <Paper
                          sx={{
                            px: 1,
                            borderRadius: '8px',
                            backgroundColor: '#e0e0e0',
                          }}
                        >
                          +{hiddenCount}個
                        </Paper>
                      )}
                    </Box>
                  );
                }}
              >
                <MenuItem
                  value="all"
                  onClick={() => {
                    const allColumns = getColumnsForSelectedCategory();
                    if (
                      selectedItemSpecificsColumns.length ===
                        allColumns.length &&
                      selectedItemSpecificsColumns.length > 0
                    ) {
                      setSelectedItemSpecificsColumns([]);
                    } else {
                      setSelectedItemSpecificsColumns(allColumns);
                    }
                  }}
                >
                  <Checkbox
                    checked={
                      selectedItemSpecificsColumns.length ===
                        getColumnsForSelectedCategory().length &&
                      selectedItemSpecificsColumns.length > 0
                    }
                    indeterminate={
                      selectedItemSpecificsColumns.length > 0 &&
                      selectedItemSpecificsColumns.length <
                        getColumnsForSelectedCategory().length
                    }
                  />
                  すべて選択/解除
                </MenuItem>
                {getColumnsForSelectedCategory().map((colKey) => (
                  <MenuItem key={colKey} value={colKey}>
                    <Checkbox
                      checked={selectedItemSpecificsColumns.indexOf(colKey) > -1}
                    />
                    {colKey}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {/* ▼ マッチングオプション (大文字小文字 / 部分一致 / タイトル・説明等のラジオボタン) */}
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
            <Checkbox
              checked={overwriteExisting}
              onChange={(e) => setOverwriteExisting(e.target.checked)}
            />
          }
          label="既存の値を上書きする"
        />
          <FormControlLabel
            control={
              <Checkbox
                checked={itemSpecificsMatchingOptions.partialMatch}
                onChange={(e) =>
                  setItemSpecificsMatchingOptions((prev) => ({
                    ...prev,
                    partialMatch: e.target.checked,
                  }))
                }
              />
            }
            label="部分一致を許可する"
          />

          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              どのテキストからマッチングを行いますか？
            </Typography>
            <RadioGroup
              value={itemSpecificsMatchingOptions.matchSource}
              onChange={(e) =>
                setItemSpecificsMatchingOptions((prev) => ({
                  ...prev,
                  matchSource: e.target.value,
                }))
              }
              row
            >
              <FormControlLabel
                value="title"
                control={<Radio />}
                label="タイトルのみ"
              />
              <FormControlLabel
                value="description"
                control={<Radio />}
                label="商品説明のみ (AI生成部分)"
              />
              <FormControlLabel
                value="both"
                control={<Radio />}
                label="タイトル + 商品説明"
              />
            </RadioGroup>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              マッチングが複数あった場合の動作
            </Typography>
            <RadioGroup
              value={itemSpecificsMatchingOptions.matchingOption}
              onChange={(e) =>
                setItemSpecificsMatchingOptions((prev) => ({
                  ...prev,
                  matchingOption: e.target.value,
                }))
              }
              row
            >
              <FormControlLabel
                value="priority"
                control={<Radio />}
                label="優先順位で適用"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="マッチしたすべての値を適用"
              />
            </RadioGroup>
          </Box>
        </Box>

        {/* ▼ 適用ボタン */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyItemSpecifics}
          sx={{ mt: 2 }}
        >
          ITEM SPECIFICSを適用
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyTruncation}
          sx={{ mt: 2 }}
        >
          64文字カットを再適用
        </Button>

      </Paper>
    </Grid>
  </Grid>
</Box>



      {/* 下部：処理結果プレビュー + "再置換" ボタン */}
      <Box sx={{ px: 2, mt: 2 }}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">処理結果のプレビュー / ダウンロード</Typography>

          {processedData.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleRefineDescription}
                sx={{ mb: 2 }}
              >
                AI後の再置換を適用
              </Button>

              <Typography variant="subtitle1">処理結果のプレビュー:</Typography>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {processedParsedData.map((file, index) => (
                  <Tab key={index} label={file.name} />
                ))}
              </Tabs>

              {processedParsedData.map((file, fileIndex) => {
                const isCurrentTab = activeTab === fileIndex;
                const totalItems = file.data.length;
                const parsedItemsPerPage =
                  itemsPerPageForPreview === 'All'
                    ? totalItems
                    : itemsPerPageForPreview;
                const totalPages = Math.ceil(totalItems / parsedItemsPerPage);
                const startIndex = (previewCurrentPage - 1) * parsedItemsPerPage;
                const endIndex =
                  itemsPerPageForPreview === 'All'
                    ? totalItems
                    : startIndex + parsedItemsPerPage;
                const displayData = file.data.slice(startIndex, endIndex);

                return (
                  <div key={fileIndex} role="tabpanel" hidden={!isCurrentTab}>
                    {isCurrentTab && (
                      <Box sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                          <FormControl size="small" sx={{ width: 120 }}>
                            <InputLabel>表示件数</InputLabel>
                            <Select
                              value={itemsPerPageForPreview}
                              label="表示件数"
                              onChange={(e) => {
                                setItemsPerPageForPreview(e.target.value);
                                setPreviewCurrentPage(1);
                              }}
                            >
                              <MenuItem value={50}>50件</MenuItem>
                              <MenuItem value={100}>100件</MenuItem>
                              <MenuItem value={200}>200件</MenuItem>
                              <MenuItem value="All">All</MenuItem>
                            </Select>
                          </FormControl>

                          {itemsPerPageForPreview !== 'All' && totalPages > 1 && (
                            <Pagination
                              count={totalPages}
                              page={previewCurrentPage}
                              onChange={(event, value) => setPreviewCurrentPage(value)}
                              color="primary"
                            />
                          )}
                        </Box>

                        <Paper elevation={2} sx={{ overflowX: 'auto' }}>
                          <TableContainer>
                            <Table size="small" sx={{ tableLayout: 'auto', width: 'auto' }}>
                              <TableHead>
                                <TableRow>
                                  {/* 行番号カラム */}
                                    <TableCell
                                      sx={{
                                        maxWidth: '50px',
                                        whiteSpace: 'nowrap',
                                        padding: '8px',
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      行番号
                                    </TableCell>
                                  <TableCell
                                    sx={{
                                      maxWidth: '50px',
                                      whiteSpace: 'nowrap',
                                      padding: '8px',
                                      border: '1px solid rgba(224, 224, 224, 1)',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    操作
                                  </TableCell>
                                  {file.columns.map((column, idx) => (
                                    <TableCell
                                      key={idx}
                                      sx={{
                                        maxWidth: '150px',
                                        whiteSpace: 'nowrap',
                                        padding: '8px',
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {column}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {displayData.map((row, rowIndex) => (
                                  <TableRow key={rowIndex}>
                                    {/* 行番号のセル: ページングの場合は startIndex を考慮 */}
                                    <TableCell
                                      sx={{
                                        whiteSpace: 'nowrap',
                                        padding: '8px',
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                      }}
                                    >
                                      {startIndex + rowIndex + 1}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        whiteSpace: 'nowrap',
                                        padding: '8px',
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                      }}
                                    >
                                      <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => handlePreviewRow(row)}
                                      >
                                        <PreviewIcon fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={() =>
                                          handleDeleteRow(
                                            startIndex + rowIndex,
                                            fileIndex
                                          )
                                        }
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>

                                    {file.columns.map((column, colIndex) => (
                                      <TableCell
                                        key={colIndex}
                                        sx={{
                                          maxWidth: '150px',
                                          whiteSpace: 'nowrap',
                                          padding: '8px',
                                          border: '1px solid rgba(224, 224, 224, 1)',
                                        }}
                                      >
                                        {column === 'PicURL' ? (
                                          row[column] ? (
                                            <img
                                              src={getImageUrls(row[column])[0]}
                                              alt="商品画像"
                                              style={{
                                                width: '50px',
                                                height: '50px',
                                                objectFit: 'cover',
                                              }}
                                            />
                                          ) : (
                                            'なし'
                                          )
                                        ) : (
                                          row[column]
                                        )}
                                      </TableCell>
                                      
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>

                        {itemsPerPageForPreview !== 'All' && totalPages > 1 && (
                          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                            <Pagination
                              count={totalPages}
                              page={previewCurrentPage}
                              onChange={(event, value) => setPreviewCurrentPage(value)}
                              color="primary"
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </div>
                );
              })}
            </Box>
          )}

          {errorItems.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" color="error">
                エラーが発生したアイテム:
              </Typography>
              <List>
                {errorItems.map((item, index) => (
                  <ListItem key={index}>
                    <Typography>
                      ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {processedParsedData.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">処理結果のダウンロード:</Typography>
              {processedParsedData.map((file, idx) => (
                <Button
                  key={idx}
                  variant="contained"
                  sx={{ mt: 1, mr: 1 }}
                  onClick={() => {
                    // processedParsedData の data 配列（削除後の状態）から CSV を再生成
                    const csvContent = Papa.unparse(file.data);
                    const blob = new Blob([csvContent], {
                      type: 'text/csv;charset=utf-8;',
                    });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);

                    // 今日の日付を YYYYMMDD 形式で取得
                    const today = new Date();
                    const month = ('0' + (today.getMonth() + 1)).slice(-2);
                    const day = ('0' + today.getDate()).slice(-2);
                    const formattedDate = `${month}${day}`;

                    // ダウンロードするファイル名を「今日の日付 + auto + 元のファイル名」に設定
                    link.setAttribute('download', `${formattedDate}auto${file.name}`);

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  {file.name}
                </Button>
              ))}
            </Box>
          )}

        </Paper>
      </Box>

      {/* プレビュー用ダイアログ */}
      <Dialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>商品プレビュー</DialogTitle>
        <DialogContent>
          {previewRowData ? (
            <Box>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {previewRowData.Title || '(No Title)'}
              </Typography>
              <Box>
                {getImageUrls(previewRowData.PicURL)[0] && (
                  <img
                    src={getImageUrls(previewRowData.PicURL)[0]}
                    alt="メイン画像"
                    style={{ width: '300px', height: 'auto', objectFit: 'cover' }}
                  />
                )}
                <Box sx={{ mt: 1, display: 'flex', gap: '8px' }}>
                  {getImageUrls(previewRowData.PicURL).map((url, idx) => (
                    <img
                      key={idx}
                      src={url}
                      alt={`thumb-${idx}`}
                      style={{
                        width: '60px',
                        height: '60px',
                        objectFit: 'cover',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1">
                  価格 : {previewRowData.StartPrice || '(不明)'}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  説明:
                </Typography>
                <Box
                  sx={{ mt: 1 }}
                  dangerouslySetInnerHTML={{
                    __html: previewRowData.Description || '(説明なし)',
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Typography>データがありません</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 機能選択ダイアログ */}
      <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
        <DialogTitle>実行する機能を選択</DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="functions">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {availableFunctions.map((func, index) => (
                    <Draggable key={func.name} draggableId={func.name} index={index}>
                      {(provided) => (
                        <ListItem
                          button
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Checkbox
                            checked={
                              selectedFunctions.findIndex((f) => f.name === func.name) !== -1
                            }
                            onClick={handleFunctionToggle(func)}
                          />
                          <Typography>{func.displayName}</Typography>
                          <IconButton {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </IconButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFunctionDialogClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート保存ダイアログ */}
      <Dialog
        open={isSaveTemplateDialogOpen}
        onClose={() => setIsSaveTemplateDialogOpen(false)}
      >
        <DialogTitle>テンプレートの保存</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <label>
              <input
                type="radio"
                name="saveOption"
                value="new"
                checked={saveOption === 'new'}
                onChange={(e) => setSaveOption(e.target.value)}
              />
              新しいテンプレートとして保存
            </label>
            {selectedTemplateId && (
              <label style={{ marginLeft: '16px' }}>
                <input
                  type="radio"
                  name="saveOption"
                  value="overwrite"
                  checked={saveOption === 'overwrite'}
                  onChange={(e) => setSaveOption(e.target.value)}
                />
                既存のテンプレートを上書き保存
              </label>
            )}
          </Box>
          <TextField
            label="テンプレート名"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
          <Button onClick={saveTemplate} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート管理ダイアログ */}
      <Dialog
        open={isManageTemplatesDialogOpen}
        onClose={() => setIsManageTemplatesDialogOpen(false)}
      >
        <DialogTitle>テンプレートの管理 (subcollection)</DialogTitle>
        <DialogContent>
          <List>
            {templates.map((tpl) => (
              <ListItem key={tpl.id}>
                <Typography>{tpl.name}</Typography>
                <Button
                  onClick={() => handleLoadTemplate(tpl.id)}
                  sx={{ ml: 2 }}
                  variant="outlined"
                >
                  ロード
                </Button>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteTemplate(tpl.id)}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* パターン保存ダイアログ */}
      <Dialog
        open={isSavePatternDialogOpen}
        onClose={() => setIsSavePatternDialogOpen(false)}
      >
        <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
        <DialogContent>
          <TextField
            label="パターン名"
            value={newPatternName}
            onChange={(e) => setNewPatternName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
          <Button onClick={saveCurrentSettingsAsPattern} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* パターン管理ダイアログ */}
      <Dialog
        open={isManagePatternsDialogOpen}
        onClose={() => setIsManagePatternsDialogOpen(false)}
      >
        <DialogTitle>パターンの管理</DialogTitle>
        <DialogContent>
          <List>
            {patterns.map((pattern) => (
              <ListItem key={pattern.id}>
                <Typography>{pattern.name}</Typography>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeletePattern(pattern.id)}
                  sx={{ ml: 2 }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={isExclusionLogOpen}
  onClose={() => setIsExclusionLogOpen(false)}
  maxWidth="md"
  fullWidth
>
  <DialogTitle>除外された商品の一覧</DialogTitle>
  <DialogContent>
    {exclusionLog.length > 0 ? (
      <Box>
        {exclusionLog.map((log, index) => (
          <Box key={index} sx={{ mb: 1, borderBottom: '1px solid #ddd', pb: 1 }}>
            <Typography variant="body2">
              ファイル: {log.fileName} / 行番号: {log.rowIndex + 1} / タイトル: {log.rowData.Title || log.rowData.title || '(No Title)'}
            </Typography>
          </Box>
        ))}
      </Box>
    ) : (
      <Typography>除外された商品はありません。</Typography>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsExclusionLogOpen(false)}>閉じる</Button>
  </DialogActions>
</Dialog>


      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default CsvSchedulerUploader;