// Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth, db } from '../../firebaseConfig';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Avatar,
  CssBaseline,
  Alert,
  Card,
  CardContent,
  CardMedia,
  Fade,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Description as DescriptionIcon,
  Image as ImageIcon,
  Calculate as CalculateIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';

function Login({ setIsAuthenticated }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false); // サインアップとログインの切り替え
  const [error, setError] = useState('');
  const [email, setEmail] = useState(''); // メールアドレスとパスワードの状態
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleEmailPasswordAuth = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      if (isSigningUp) {
        // 新規登録
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Firestoreにユーザー情報を保存
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          createdAt: serverTimestamp(),
        });

        setIsAuthenticated(true);
        navigate('/');
      } else {
        // ログイン
        await signInWithEmailAndPassword(auth, email, password);
        setIsAuthenticated(true);
        navigate('/');
      }
    } catch (error) {
      console.error('認証エラー:', error);
      const errorCode = error.code;
      let errorMessage = '';

      if (isSigningUp) {
        switch (errorCode) {
          case 'auth/email-already-in-use':
            errorMessage = 'このメールアドレスは既に使用されています。ログインしてください。';
            break;
          case 'auth/invalid-email':
            errorMessage = '無効なメールアドレスです。';
            break;
          case 'auth/weak-password':
            errorMessage = 'パスワードは6文字以上にしてください。';
            break;
          default:
            errorMessage = '新規登録に失敗しました。もう一度お試しください。';
            break;
        }
      } else {
        switch (errorCode) {
          case 'auth/user-not-found':
            errorMessage = 'ユーザーが見つかりません。メールアドレスを確認してください。';
            break;
          case 'auth/wrong-password':
            errorMessage = 'パスワードが間違っています。';
            break;
          case 'auth/invalid-email':
            errorMessage = '無効なメールアドレスです。';
            break;
          default:
            errorMessage = 'ログインに失敗しました。もう一度お試しください。';
            break;
        }
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('パスワードリセットにはメールアドレスを入力してください。');
      return;
    }
    setIsLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setError('パスワードリセットのメールを送信しました。メールを確認してください。');
    } catch (error) {
      console.error('パスワードリセットエラー:', error);
      const errorCode = error.code;
      let errorMessage = '';

      switch (errorCode) {
        case 'auth/user-not-found':
          errorMessage = 'このメールアドレスのユーザーが見つかりません。';
          break;
        case 'auth/invalid-email':
          errorMessage = '無効なメールアドレスです。';
          break;
        default:
          errorMessage = 'パスワードリセットに失敗しました。もう一度お試しください。';
          break;
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth={false} disableGutters>
      <CssBaseline />
      <Grid container sx={{ minHeight: '100vh' }}>
        {/* 左側：ログインフォーム */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            padding: { xs: 2, md: 4 },
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 400,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#1976d2' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {isSigningUp ? '新規登録' : 'ログイン'}
            </Typography>
            <Box component="form" onSubmit={handleEmailPasswordAuth} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード（6文字以上）"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isSigningUp ? '新規登録' : 'ログイン'}
              </Button>
              <Grid container spacing={1} direction="column" alignItems="center">
                {!isSigningUp && (
                  <Grid item>
                    <Link href="#" variant="body2" onClick={handlePasswordReset}>
                      パスワードをお忘れの方はこちら
                    </Link>
                  </Grid>
                )}
                <Grid item>
                  <Link href="#" variant="body2" onClick={() => setIsSigningUp(!isSigningUp)}>
                    
                    {/* {isSigningUp
                      ? '既にアカウントをお持ちの方はこちら'
                      : '初めてご利用の方はこちら'} */}
                  </Link>
                </Grid>
              </Grid>
              <Box sx={{ mt: 5 }}>
                <Typography variant="body2" color="text.secondary" align="center">
                  サインインすることで、
                  <Link href="/terms" target="_blank" rel="noopener">
                    利用規約
                  </Link>
                  と
                  <Link href="/privacy" target="_blank" rel="noopener">
                    プライバシーポリシー
                  </Link>
                  に同意したことになります。
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* 右側：機能紹介セクション */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* 背景画像 */}
          <CardMedia
            component="img"
            image="https://i.postimg.cc/65TKLrmy/3.png" // お好みの背景画像に変更可能
            alt=""
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: 1.0,
            }}
          />
         
          {/* コンテンツ */}
          <Box
            sx={{
              position: 'relative',
              color: '#fff',
              textAlign: 'center',
              padding: { xs: 2, md: 4 },
              maxWidth: 600,
            }}
          >
            <Fade in timeout={1000}>
            <Typography
              component="h2"
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#fff', // ここで文字色を白に設定
              }}
            >
              Specifics-IN
            </Typography>
            </Fade>
            <Fade in timeout={1500}>
              <Typography variant="h5" gutterBottom>
                効率的な出品で、eBayビジネスを次のレベルへ
              </Typography>
            </Fade>
            <Grid container spacing={2} sx={{ mt: 4 }}>
              {featureData.map((feature, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Fade in timeout={2000 + index * 500}>
                    <Card
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 2,
                        backdropFilter: 'blur(5px)',
                      }}
                    >
                      <Box sx={{ mb: 1 }}>{feature.icon}</Box>
                      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" color="inherit">
                        {feature.description}
                      </Typography>
                    </Card>
                  </Fade>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

const featureData = [
  {
    icon: <DescriptionIcon fontSize="large" sx={{ color: '#fff' }} />,
    title: 'テンプレート管理',
    description: 'CSVに対して一貫した変更を効率的に適用します。',
  },
  {
    icon: <ImageIcon fontSize="large" sx={{ color: '#fff' }} />,
    title: 'AIタイトル生成',
    description: 'AIを使用して最適なタイトルを自動生成します。',
  },
  {
    icon: <CalculateIcon fontSize="large" sx={{ color: '#fff' }} />,
    title: 'AI商品説明挿入',
    description: 'AIを使用して最適な商品説明を自動生成します。',
  },
  {
    icon: <CloudUploadIcon fontSize="large" sx={{ color: '#fff' }} />,
    title: 'Specificsを挿入可能',
    description: 'eBayへのアップロードをスムーズに行います。',
  },
];

export default Login;
