// ImageDownloader.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  LinearProgress,
  FormControlLabel,
  Switch,
  Paper,
  Grid,
  DialogContentText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import ImageOperations from './ImageOperations'; // 追加

const INITIAL_BATCH_SIZE = 5;
const MAX_BATCH_SIZE = 20;
const MIN_BATCH_SIZE = 1;
const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24時間
const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 1000;

const workers = [
  'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
  'https://bold-block-5adc.kikuchi-shun.workers.dev/',
  'https://broken-fire-195b.kikuchi-shun.workers.dev/',
];

const userAgents = [
  // 実際のユーザーエージェント文字列をここに追加してください
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)',
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7)',
];

function getRandomWorker() {
  return workers[Math.floor(Math.random() * workers.length)];
}

function getRandomUserAgent() {
  return userAgents[Math.floor(Math.random() * userAgents.length)];
}

const imageCache = new Map();

function ImageDownloader({ data, setData, apiKey }) {
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(150);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failCount, setFailCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
  const [remainingRequests, setRemainingRequests] = useState(null);
  const [maxRequestsPerDay, setMaxRequestsPerDay] = useState(null);
  const [operationType, setOperationType] = useState('download'); // 'download' or 'delete'
  const [useFolderDownload, setUseFolderDownload] = useState(false);
  const [useZipDownload, setUseZipDownload] = useState(false);
  const [downloadOption, setDownloadOption] = useState('first'); // 'first' or 'all'
  const [openDownloadConfirmDialog, setOpenDownloadConfirmDialog] = useState(false);

  // 追加: ImageOperationsコンポーネントのプレビューを開くための状態と関数
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const theme = useTheme();

  const startTimeRef = useRef(null);

  const auth = getAuth();
  const db = getFirestore();

    // データの取得
  useEffect(() => {
    if (data && data.length > 0) {
      setStartRow(1);
      setEndRow(data.length); // 全件を初期範囲とする
    }
  }, []);


  // ユーザーの maxRequestsPerDay を取得
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setMaxRequestsPerDay(userData.maxRequestsPerDay || 350);
          setRemainingRequests(
            getRemainingRequests(user.uid, userData.maxRequestsPerDay || 350)
          );
        } else {
          console.error('ユーザードキュメントが存在しません');
        }
      } else {
        console.error('ユーザーがログインしていません');
      }
    };

    fetchUserData();
  }, [auth.currentUser]);

  // リクエストを記録するためのキーをユーザーごとに設定
  const user = auth.currentUser;
  const rateLimitKey = user ? `rateLimitRequests_${user.uid}` : 'rateLimitRequests';

  function checkRateLimit() {
    const now = Date.now();
    const requests = JSON.parse(localStorage.getItem(rateLimitKey) || '[]');
    const validRequests = requests.filter((time) => now - time < RATE_LIMIT_PERIOD);
    if (validRequests.length >= maxRequestsPerDay) {
      return false;
    }
    validRequests.push(now);
    localStorage.setItem(rateLimitKey, JSON.stringify(validRequests));
    return true;
  }

  function getRemainingRequests(uid, maxRequestsPerDayValue) {
    const now = Date.now();
    const requests = JSON.parse(localStorage.getItem(`rateLimitRequests_${uid}`) || '[]');
    const validRequests = requests.filter((time) => now - time < RATE_LIMIT_PERIOD);
    return maxRequestsPerDayValue - validRequests.length;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (user && maxRequestsPerDay) {
        setRemainingRequests(getRemainingRequests(user.uid, maxRequestsPerDay));
      }
    }, 60000); // 1分ごとに更新

    return () => clearInterval(interval);
  }, [user, maxRequestsPerDay]);

  const calculateMaxEndRow = () => {
    if (data && data.length > 0) {
      return data.length; // データ長まで可能にする
    } else {
      return startRow + 10000; // 任意の大きな値でも可
    }
  };
  

  const handleStartRowChange = (event) => {
    const value = parseInt(event.target.value);
    setStartRow(value);
  
    // ここで maxEndRow の計算や 150 行の制限を撤廃
    // const maxEndRow = calculateMaxEndRow();
    // if (endRow < value || endRow > maxEndRow || isNaN(endRow)) {
    //   setEndRow(maxEndRow);
    // }
  };
  

  const handleEndRowChange = (event) => {
    const value = parseInt(event.target.value);
    const maxEndRow = calculateMaxEndRow();
    setEndRow(Math.min(Math.max(value, startRow), maxEndRow));
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const adjustBatchSize = (successRate) => {
    if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
      setBatchSize((prev) => prev + 1);
    } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
      setBatchSize((prev) => Math.max(prev - 2, MIN_BATCH_SIZE));
    } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
      setBatchSize((prev) => Math.max(prev - 3, MIN_BATCH_SIZE));
    }
  };

  async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
    if (imageCache.has(url)) {
      return imageCache.get(url);
    }

    if (!checkRateLimit()) {
      throw new Error('1日のリクエスト上限を超えました');
    }

    const workerUrl = getRandomWorker();
    const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;

    try {
      const response = await axios.get(proxyUrl, {
        responseType: 'arraybuffer',
        timeout: 30000,
        headers: {
          'User-Agent': getRandomUserAgent(),
          Referer: 'https://www.rakuten.co.jp/',
        },
      });
      const data = response.data;
      imageCache.set(url, data);
      return data;
    } catch (error) {
      if (retryCount < MAX_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        return download(url, retryCount + 1, retryDelay * 2);
      }
      throw error;
    }
  }

  const processBatch = async (images, startIndex) => {
    const batch = images.slice(startIndex, startIndex + batchSize);
    const results = await Promise.all(
      batch.map(async (imageObj, index) => {
        try {
          const imageData = await download(imageObj.url);
          setRemainingRequests(getRemainingRequests(user.uid, maxRequestsPerDay));
          const fileName = `image_row${imageObj.rowIndex}_img${imageObj.imageIndex}.jpg`;
          return {
            success: true,
            data: imageData,
            fileName,
            url: imageObj.url,
            imageObj,
          };
        } catch (error) {
          console.error(`画像のダウンロードに失敗しました ${imageObj.url}:`, error);
          return {
            success: false,
            url: imageObj.url,
            error: error.message || 'Unknown error',
            imageObj,
          };
        }
      })
    );

    const successfulDownloads = results.filter((r) => r.success).length;
    const successRate = successfulDownloads / batch.length;

    adjustBatchSize(successRate);

    return results;
  };

  const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
    const speed = downloadedCount / (elapsedTime / 1000);
    setDownloadSpeed(speed);

    const remainingCount = totalCount - downloadedCount;
    const estimatedTime = remainingCount / speed;
    setEstimatedTimeRemaining(estimatedTime);
  };

  const handleDownload = useCallback(
    async (option) => {
      if (useFolderDownload && !('showDirectoryPicker' in window)) {
        alert(
          'このブラウザはフォルダへのダウンロードをサポートしていません。ChromeまたはEdgeを使用してください。'
        );
        return;
      }

      setIsProcessing(true);
      setError('');
      setProgress(0);
      setSuccessCount(0);
      setFailCount(0);
      startTimeRef.current = Date.now();

      let dirHandle;

      if (useFolderDownload) {
        try {
          dirHandle = await window.showDirectoryPicker();
        } catch (err) {
          setError('フォルダの選択がキャンセルされました。');
          setIsProcessing(false);
          return;
        }
      }

      // ローカル変数を追加
      let localSuccessCount = 0;
      let localFailCount = 0;

      try {
        if (!startRow || !endRow || isNaN(startRow) || isNaN(endRow)) {
          throw new Error('Start RowとEnd Rowは有効な数字である必要があります。');
        }

        const actualStartRow = parseInt(startRow) - 1; // インデックスは0から開始
        const maxEndRow = calculateMaxEndRow();
        const actualEndRow = Math.min(parseInt(endRow) - 1, maxEndRow - 1);

        if (actualStartRow < 0 || actualEndRow < actualStartRow) {
          throw new Error('Start RowとEnd Rowが有効ではありません。');
        }

        const picUrlKey = Object.keys(data[0]).find(
          (key) => key.toLowerCase() === 'picurl'
        );
        if (!picUrlKey) {
          throw new Error('PicURLカラムが見つかりません。');
        }

        let imagesToDownload = [];

        if (option === 'first') {
          // 1枚目の画像のみを取得
          for (let i = actualStartRow; i <= actualEndRow; i++) {
            const item = data[i];
            const picUrl = item[picUrlKey];
            const imageUrl = picUrl ? picUrl.split('|')[0] : null;
            if (imageUrl) {
              imagesToDownload.push({
                url: imageUrl,
                rowIndex: i + 1,
                imageIndex: 1,
              });
            }
          }
        } else {
          // すべての画像を取得
          for (let i = actualStartRow; i <= actualEndRow; i++) {
            const item = data[i];
            const picUrl = item[picUrlKey];
            const imageUrls = picUrl ? picUrl.split('|') : [];
            imageUrls.forEach((url, idx) => {
              if (url) {
                imagesToDownload.push({
                  url,
                  rowIndex: i + 1,
                  imageIndex: idx + 1,
                });
              }
            });
          }
        }

        // ダウンロード処理
        let failedImages = [];
        let zip;
        if (useZipDownload) {
          zip = new JSZip();
        }

        for (let i = 0; i < imagesToDownload.length; i += batchSize) {
          const batchResults = await processBatch(imagesToDownload, i);
          for (const result of batchResults) {
            if (result.success) {
              // ローカル変数を更新
              localSuccessCount += 1;
              setSuccessCount(localSuccessCount);
              const blob = new Blob([result.data], { type: 'image/jpeg' });
              if (useFolderDownload && dirHandle) {
                const fileHandle = await dirHandle.getFileHandle(result.fileName, {
                  create: true,
                });
                const writable = await fileHandle.createWritable();
                await writable.write(blob);
                await writable.close();
              } else if (useZipDownload) {
                zip.file(result.fileName, result.data);
              } else {
                // ブラウザのダウンロード機能を使用
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = result.fileName;
                a.click();
                window.URL.revokeObjectURL(url);
              }
            } else {
              // ローカル変数を更新
              localFailCount += 1;
              setFailCount(localFailCount);
              failedImages.push(result.imageObj);
            }
          }

          const totalProgress = Math.min(i + batchSize, imagesToDownload.length);
          setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

          const elapsedTime = Date.now() - startTimeRef.current;
          updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

          setRemainingRequests(getRemainingRequests(user.uid, maxRequestsPerDay));
        }

        // ZIPファイルをダウンロード
        if (useZipDownload && zip) {
          const zipBlob = await zip.generateAsync({ type: 'blob' });
          saveAs(zipBlob, 'images.zip');
        }

        // ローカル変数を使用して最終メッセージを構築
        const finalMessage = `ダウンロードが完了しました。${localSuccessCount}枚の画像が正常にダウンロードされ、${localFailCount}枚が失敗しました。`;
        showSnackbar(finalMessage);
      } catch (err) {
        const errorMessage = `ダウンロードに失敗しました: ${err.message}`;
        setError(errorMessage);
        showSnackbar(errorMessage);
        console.error(errorMessage, err);
      } finally {
        setIsProcessing(false);
      }
    },
    [
      data,
      startRow,
      endRow,
      batchSize,
      useFolderDownload,
      useZipDownload,
      calculateMaxEndRow,
      setData,
      updateDownloadStats,
      processBatch,
      downloadOption,
      showSnackbar,
      maxRequestsPerDay,
      user,
    ]
  );

  const handleOperationConfirmation = (operation, option) => {
    setOperationType(operation);
    setDownloadOption(option);

    if (operation === 'download') {
      setOpenDownloadConfirmDialog(true); // ダウンロード確認ダイアログを開く
    } else if (operation === 'delete') {
      // 削除操作の場合はプレビューダイアログを表示
      setOpenPreviewDialog(true);
    }
  };

  const handleConfirmDownload = () => {
    setOpenDownloadConfirmDialog(false);
    handleDownload(downloadOption);
  };

  return (
    <Box
      sx={{
        mt: 0,
        p: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography variant="h6" gutterBottom>
        画像ダウンロードと削除
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        150商品以上ある場合は、End Rowを調整してください。
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            label="Start Row"
            type="number"
            value={startRow}
            onChange={handleStartRowChange}
            fullWidth
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="End Row"
            type="number"
            value={endRow}
            onChange={handleEndRowChange}
            fullWidth
            InputProps={{ inputProps: { min: startRow, max: calculateMaxEndRow() } }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Tooltip title="指定した範囲の商品の1枚目の画像をダウンロード">
                <Button
                  variant="contained"
                  onClick={() => handleOperationConfirmation('download', 'first')}
                  disabled={
                    isProcessing || !data || data.length === 0 || remainingRequests <= 0
                  }
                  fullWidth
                >
                  {isProcessing &&
                  operationType === 'download' &&
                  downloadOption === 'first' ? (
                    <CircularProgress size={24} />
                  ) : (
                    '1枚目のみダウンロード'
                  )}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Tooltip title="指定した範囲の商品のすべての画像をダウンロード">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleOperationConfirmation('download', 'all')}
                  disabled={
                    isProcessing || !data || data.length === 0 || remainingRequests <= 0
                  }
                  fullWidth
                >
                  {isProcessing &&
                  operationType === 'download' &&
                  downloadOption === 'all' ? (
                    <CircularProgress size={24} />
                  ) : (
                    'すべての画像をダウンロード'
                  )}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Tooltip title="指定した範囲の商品の画像を編集・削除">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleOperationConfirmation('delete', 'all')}
                  disabled={isProcessing || !data || data.length === 0}
                  fullWidth
                >
                  {isProcessing && operationType === 'delete' ? (
                    <CircularProgress size={24} />
                  ) : (
                    '画像の編集・削除'
                  )}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {operationType === 'download' && (
        <>
          <FormControlLabel
            control={
              <Switch
                checked={useFolderDownload}
                onChange={(e) => setUseFolderDownload(e.target.checked)}
              />
            }
            label="フォルダに直接ダウンロード (Chromeのみ対応)"
            sx={{ mt: 2 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={useZipDownload}
                onChange={(e) => setUseZipDownload(e.target.checked)}
              />
            }
            label="Zipファイルでダウンロード"
            sx={{ mt: 2 }}
          />
        </>
      )}

      {remainingRequests !== null && maxRequestsPerDay !== null && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          1日のリクエスト残数: {remainingRequests} / {maxRequestsPerDay}
        </Typography>
      )}

      {isProcessing && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            進行状況: {progress}%
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
          <Typography variant="body2" color="text.secondary">
            ダウンロード速度: {downloadSpeed.toFixed(2)} images/second
          </Typography>
          <Typography variant="body2" color="text.secondary">
            残り時間の推定: {formatTimeRemaining(estimatedTimeRemaining)}
          </Typography>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3} sx={{ mt: 3, p: 2 }}>
        <Typography variant="body2" color="text.secondary">
          正常に処理された画像: {successCount}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          処理に失敗した画像: {failCount}
        </Typography>
        {operationType === 'download' && (
          <Typography variant="body2" color="text.secondary">
            現在のバッチサイズ: {batchSize}
          </Typography>
        )}
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* ダウンロード確認ダイアログ */}
      <Dialog
        open={openDownloadConfirmDialog}
        onClose={() => setOpenDownloadConfirmDialog(false)}
        aria-labelledby="download-confirm-dialog-title"
        aria-describedby="download-confirm-dialog-description"
      >
        <DialogTitle id="download-confirm-dialog-title">{'確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="download-confirm-dialog-description">
            {`行 ${startRow} から行 ${endRow} までの商品画像をダウンロードします。よろしいですか？`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDownloadConfirmDialog(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleConfirmDownload} color="primary" autoFocus>
            実行
          </Button>
        </DialogActions>
      </Dialog>

      {/* ImageOperationsコンポーネントを追加 */}
      <ImageOperations
        data={data}
        setData={setData}
        startRow={startRow}
        endRow={endRow}
        calculateMaxEndRow={calculateMaxEndRow}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
        setError={setError}
        showSnackbar={showSnackbar}
        openPreviewDialog={openPreviewDialog}
        setOpenPreviewDialog={setOpenPreviewDialog}
        apiKey={apiKey} // OpenAIのAPIキーを渡す
      />
    </Box>
  );
}

// 残り時間をフォーマットするヘルパー関数
function formatTimeRemaining(seconds) {
  if (seconds < 60) {
    return `${Math.round(seconds)}秒`;
  } else if (seconds < 3600) {
    return `${Math.round(seconds / 60)}分`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours}時間${minutes}分`;
  }
}

export default ImageDownloader;
