/**
 * autoDesignTemplates.js
 *
 * カード型レイアウトを基本とした自動編集用テンプレート群
 */

/**
 * (A) Markdown の太字（**テキスト**）を <strong> に変換する関数
 */
function parseMarkdownBold(str) {
    return str.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }
  
  /**
   * (B) 特定キーワードを太字化する関数
   */
  function highlightKeywords(str) {
    const keywords = ['Brand', 'Condition', 'Size', 'Color', 'Includes'];
    let result = str;
    keywords.forEach((kw) => {
      const re = new RegExp(`(${kw})`, 'gi');
      result = result.replace(re, '<strong>$1</strong>');
    });
    return result;
  }
  
  /**
   * テンプレート群
   * 各テンプレートは「カード型レイアウト」で出力するように調整しています
   */
  const autoDesignTemplates = {
    // ================== PROFESSIONAL ==================
    professional: {
      name: 'Professional',
      generateHTML: (title, features, description, specifications, userSettings,picUrl) => {
                // 1) Features の前処理
        const parsedFeatures = parseMarkdownBold(features);
        const allFeatureLines = parsedFeatures
          .split('\n')
          .map(line => line.trim())
          .filter(line => line.length > 0);
        // 通常行（"C:" で始まらない）の先頭7行を抽出
        const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
        // "C:"で始まる行はすべて抽出
        const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
        // 両方を連結
        const featureLines = [...normalLines, ...cLines];

        const featuresListHtml = featureLines.map(feature => {
          const highlighted = highlightKeywords(feature);
          return `<li style="margin-bottom:6px;">
                    <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                  </li>`;
        }).join('');

  
        // 2) Specifications の処理
        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value]) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr>
                    <td style="padding:8px; border-bottom:1px solid #ddd; font-weight:bold; white-space:nowrap;">${label}</td>
                    <td style="padding:8px; border-bottom:1px solid #ddd;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
  
        // 3) バナー表示
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner Image" style="max-width:100%; height:auto;" />
            </div>
          `;
        }

      
  
        // 4) カード型全体レイアウト
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            overflow: hidden;
            font-family: Arial, sans-serif;
          ">
            ${bannerHtml}
            <div style="padding: 20px;">
              <h1 style="
                margin-top: 0;
                font-size: 24px;
                border-bottom: 1px solid #ddd;
                padding-bottom: 10px;
              ">${title}</h1>
              
              <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-top: 20px;">
                <!-- Key Features -->
                <div style="flex: 1; min-width: 300px;">
                  <h2 style="font-size: 20px; margin-bottom: 10px;">Key Features</h2>
                  <ul style="padding-left: 20px; margin: 0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <!-- Specifications -->
                <div style="flex: 1; min-width: 300px;">
                  <h2 style="font-size: 20px; margin-bottom: 10px;">Specifications</h2>
                  ${specsHtml}
                </div>
              </div>
              
              ${description ? `
                <div style="margin-top: 20px;">
                  <h2 style="font-size: 20px; margin-bottom: 10px;">Additional Description</h2>
                  <p style="line-height: 1.6; margin: 0;">${description}</p>
                </div>
              ` : ''}
              
              ${userSettings?.disclaimerText ? `
                <div style="margin-top: 10px; font-size: 0.85em; color: #666;">
                  ${userSettings.disclaimerText}
                </div>
              ` : ''}
            </div>
          </div>
        `;
      },
    },
    
    // ================== SIMPLE ==================
    simple: {
      name: 'Simple',
      generateHTML: (title, features, description, specifications, userSettings) => {
        // Features 前処理
        // 1) Features の前処理
        const parsedFeatures = parseMarkdownBold(features);
        const allFeatureLines = parsedFeatures
          .split('\n')
          .map(line => line.trim())
          .filter(line => line.length > 0);
        // 通常行（"C:" で始まらない）の先頭7行を抽出
        const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
        // "C:"で始まる行はすべて抽出
        const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
        // 両方を連結
        const featureLines = [...normalLines, ...cLines];

        const featuresListHtml = featureLines.map(feature => {
          const highlighted = highlightKeywords(feature);
          return `<li style="margin-bottom:6px;">
                    <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                  </li>`;
        }).join('');
        
        // Specifications 処理
        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value]) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr>
                    <td style="padding:8px; border-bottom:1px solid #ddd; font-weight:bold;">${label}</td>
                    <td style="padding:8px; border-bottom:1px solid #ddd;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        
        // バナー表示
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        
        // カードレイアウト全体
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 20px;
            font-family: Arial, sans-serif;
            color: #333;
          ">
            ${bannerHtml}
            <h1 style="text-align:center; font-size:24px; margin-bottom:20px;">${title}</h1>
            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <div style="flex: 1; min-width:300px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Features</h2>
                <ul style="list-style-type:disc; padding-left:20px; margin:0;">
                  ${featuresListHtml}
                </ul>
              </div>
              <div style="flex: 1; min-width:300px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Specifications</h2>
                ${specsHtml}
              </div>
            </div>
            ${description ? `
              <div style="margin-top:20px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Description</h2>
                <p style="line-height:1.6; margin:0;">${description}</p>
              </div>
            ` : ''}
          </div>
        `;
      },
    },
    
    // ================== MODERN ==================
    modern: {
      name: 'Modern',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value]) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr>
                    <td style="padding:8px; border-bottom:1px solid #ddd; font-weight:bold;">${label}</td>
                    <td style="padding:8px; border-bottom:1px solid #ddd;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background-color: #f7fafc;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            overflow: hidden;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            color: #2d3748;
            padding: 20px;
          ">
            ${bannerHtml}
            <h1 style="text-align:center; font-size:28px; margin-bottom:30px;">${title}</h1>
            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <div style="flex:1; min-width:280px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Key Features</h2>
                <ul style="padding-left:20px; margin:0;">
                  ${featuresListHtml}
                </ul>
              </div>
              <div style="flex:1; min-width:280px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Specifications</h2>
                ${specsHtml}
              </div>
            </div>
            ${description ? `
              <div style="margin-top:20px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Additional Description</h2>
                <p style="line-height:1.6; margin:0;">${description}</p>
              </div>
            ` : ''}
          </div>
        `;
      },
    },
    
    // ================== ELEGANT ==================
    elegant: {
      name: 'Elegant',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value]) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr>
                    <td style="padding:8px; border-bottom:1px solid #ddd; font-weight:bold;">${label}</td>
                    <td style="padding:8px; border-bottom:1px solid #ddd;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background-color: #fffaf0;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            overflow: hidden;
            font-family: 'Georgia', serif;
            color: #2d3748;
            padding: 20px;
          ">
            ${bannerHtml}
            <h1 style="text-align:center; font-size:32px; margin-bottom:30px; border-bottom:2px solid #d69e2e; padding-bottom:10px; color:#744210;">${title}</h1>
            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <div style="flex:1; min-width:280px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Key Features</h2>
                <ul style="list-style:none; padding-left:0; margin:0;">
                  ${featuresListHtml}
                </ul>
              </div>
              <div style="flex:1; min-width:280px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Specifications</h2>
                ${specsHtml}
              </div>
            </div>
            ${description ? `
              <div style="margin-top:20px;">
                <h2 style="font-size:20px; margin-bottom:10px;">Additional Description</h2>
                <p style="line-height:1.6; margin:0;">${description}</p>
              </div>
            ` : ''}
            ${userSettings?.disclaimerText ? `
              <div style="margin-top:10px; font-size:0.85em; color:#666;">
                ${userSettings.disclaimerText}
              </div>
            ` : ''}
          </div>
        `;
      },
    },
    
    // ================== MINIMALIST ==================
    minimalist: {
      name: 'Minimalist',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value]) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr>
                    <td style="padding:8px; border-bottom:1px solid #ccc;">${label}</td>
                    <td style="padding:8px; border-bottom:1px solid #ccc;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 600px;
            margin: 20px auto;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 20px;
            font-family: 'Helvetica', sans-serif;
            color: #333;
          ">
            ${bannerHtml}
            <h1 style="text-align:center; font-size:28px; margin-bottom:20px;">${title}</h1>
            <div>
              <h2 style="font-size:22px; margin-bottom:10px;">Features</h2>
              <ul style="list-style-type:disc; padding-left:20px; margin:0;">
                ${featuresListHtml}
              </ul>
            </div>
            <div style="margin-top:20px;">
              <h2 style="font-size:22px; margin-bottom:10px;">Specifications</h2>
              ${specsHtml}
            </div>
            <div style="margin-top:20px;">
              <h2 style="font-size:22px; margin-bottom:10px;">Description</h2>
              <p style="line-height:1.6;">${description}</p>
            </div>
          </div>
        `;
      },
    },
    
    // ================== SAKURA UMBRELLA ==================
    sakuraUmbrella: {
      name: 'SakuraUmbrella',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%; background-color:rgba(255,255,255,0.8); border-radius:4px;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'};">
                    <td style="padding:8px; font-weight:bold; border:1px solid rgba(140,80,80,0.2);">${label}</td>
                    <td style="padding:8px; border:1px solid rgba(140,80,80,0.2);">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%);
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            overflow: hidden;
            font-family: 'Hiragino Mincho ProN', '游明朝', serif;
            color: #4c3b3b;
            padding: 30px;
          ">
            ${bannerHtml}
            <div style="padding:20px;">
              <h1 style="text-align:center; font-size:32px; margin-bottom:25px; color:#8e4a49; border-bottom:2px solid #f8bac6; padding-bottom:10px;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px;">
                  <h2 style="font-size:24px; margin-bottom:15px;">Key Features</h2>
                  <ul style="list-style:none; padding-left:0; margin:0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px;">
                  <h2 style="font-size:24px; margin-bottom:15px;">Specifications</h2>
                  ${specsHtml}
                </div>
              </div>
              <div style="margin-top:20px;">
                <h2 style="font-size:24px; margin-bottom:15px;">Product Description</h2>
                <p style="line-height:1.6;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== NINAGAWA STYLE ==================
    ninagawaStyle: {
      name: 'NinagawaStyle',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:auto;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.25)'};">
                    <td style="padding:8px; font-weight:bold; color:#fff;">${label}</td>
                    <td style="padding:8px; color:#fffee0;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background: linear-gradient(45deg, #ff8ec7, #ffd747);
            border-radius: 10px;
            box-shadow: 0 0 20px rgba(0,0,0,0.3);
            overflow: hidden;
            font-family: 'Trebuchet MS', sans-serif;
            color: #fff;
          ">
            ${bannerHtml}
            <div style="padding:20px;">
              <h1 style="font-size:36px; font-weight:bold; text-align:center; margin-bottom:30px; text-shadow:2px 2px #ff4791;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.2); padding:20px; border-radius:10px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#fffee0;">Key Features</h2>
                  <ul style="list-style:none; padding:0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.2); padding:20px; border-radius:10px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#fffee0;">Specifications</h2>
                  <table style="width:100%; border-collapse:collapse;">
                    ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
                  </table>
                </div>
              </div>
              <div style="background-color:rgba(255,255,255,0.15); margin-top:30px; padding:20px; border-radius:10px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#fffee0;">Product Description</h2>
                <p style="line-height:1.8;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== KYARY POP ==================
    kyaryPop: {
      name: 'KyaryPop',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="width:100%; border-collapse:collapse; border:2px dotted #ff1493;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? '#ffd1ec' : '#d1faff'};">
                    <td style="padding:10px; border:1px dashed #ff1493; font-weight:bold;">${label}</td>
                    <td style="padding:10px; border:1px dashed #ff1493;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background: repeating-linear-gradient(-45deg, #ffa6e6, #ffa6e6 20px, #a6f9ff 20px, #a6f9ff 40px);
            border-radius: 10px;
            padding: 30px;
            color: #333;
            font-family: 'Comic Sans MS', cursive, sans-serif;
          ">
            <div style="max-width:1000px; margin:0 auto; background-color:rgba(255,255,255,0.8); border-radius:10px; padding:20px;">
              ${bannerHtml}
              <h1 style="text-align:center; font-size:32px; margin-bottom:30px; color:#ff1493; text-shadow:2px 2px #fdfd96;">${title}</h1>
              <div style="margin-bottom:30px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#ff69b4;">Features</h2>
                <ul style="padding-left:20px;">
                  ${featuresListHtml}
                </ul>
              </div>
              <div style="margin-bottom:30px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#ff69b4;">Specifications</h2>
                ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
              </div>
              <div style="margin-bottom:30px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#ff69b4;">Description</h2>
                <p style="line-height:1.6; background-color:#fff; padding:10px; border-radius:5px;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== SHINAKO DREAM ==================
    shinakoDream: {
      name: 'ShinakoDream',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');

        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? '#f9daf3' : '#fedbf2'};">
                    <td style="padding:8px; border:1px solid #e9badf; font-weight:bold;">${label}</td>
                    <td style="padding:8px; border:1px solid #e9badf;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background-color: #fceaff;
            border-radius: 10px;
            box-shadow: 0 0 15px rgba(255,182,193,0.3);
            overflow: hidden;
            font-family: 'Verdana', sans-serif;
            color: #5b3f5e;
            padding: 20px;
          ">
            ${bannerHtml}
            <div style="padding:30px;">
              <h1 style="text-align:center; font-size:34px; color:#d14eb6; text-shadow:1px 1px 0 #fedbf2; margin-bottom:30px;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.5); border-radius:8px; padding:20px;">
                  <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Features</h2>
                  <ul style="list-style-type:circle; padding-left:20px;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.5); border-radius:8px; padding:20px;">
                  <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Specifications</h2>
                  ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
                </div>
              </div>
              <div style="background-color:rgba(255,255,255,0.5); margin-top:30px; padding:20px; border-radius:8px;">
                <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Product Description</h2>
                <p style="line-height:1.6;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== WAFU SAKURA ==================
    wafuSakura: {
      name: 'WafuSakura',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%; background-color:rgba(255,255,255,0.8); border-radius:4px;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'};">
                    <td style="padding:8px; font-weight:bold; border:1px solid rgba(140,80,80,0.2);">${label}</td>
                    <td style="padding:8px; border:1px solid rgba(140,80,80,0.2);">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%);
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            overflow: hidden;
            font-family: 'Hiragino Mincho ProN', '游明朝', serif;
            color: #4c3b3b;
            padding: 30px;
          ">
            ${bannerHtml}
            <div style="padding:20px;">
              <h1 style="text-align:center; font-size:32px; margin-bottom:25px; color:#8e4a49; border-bottom:2px solid #f8bac6; padding-bottom:10px;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px; background: url('https://cdn.pixabay.com/photo/2017/03/20/08/52/sakura-2156305_1280.jpg') no-repeat center/cover; border-radius:8px; padding:20px; color:#4A3A3A;">
                  <h2 style="font-size:24px; margin-bottom:15px; text-shadow:1px 1px 4px rgba(0,0,0,0.3);">Key Features</h2>
                  <ul style="list-style-type:none; padding:0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.6); border-radius:8px; padding:20px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#8e4a49;">Specifications</h2>
                  ${specsHtml}
                </div>
              </div>
              <div style="margin-top:30px; background-color:rgba(255,255,255,0.6); border-radius:8px; padding:20px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#8e4a49;">Product Description</h2>
                <p style="line-height:1.6;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== CYBER NEON ==================
    cyberNeon: {
      name: 'CyberNeon',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%; color:#fff;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.07)' : 'rgba(255,255,255,0.05)'};">
                    <td style="padding:8px; font-weight:bold; border:1px solid rgba(255,255,255,0.1);">${label}</td>
                    <td style="padding:8px; border:1px solid rgba(255,255,255,0.1);">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background:#0f0f0f;
            color:#fff;
            padding:30px;
            position:relative;
            overflow:hidden;
            font-family: 'Roboto', 'Arial', sans-serif;
          ">
            <style>
              @keyframes floatingLines {
                0% { transform: translateX(-100%) skewX(-15deg); }
                100% { transform: translateX(100%) skewX(-15deg); }
              }
            </style>
            <div style="position:absolute; top:0; left:0; width:200%; height:2px; background:linear-gradient(90deg, #d800ff, #00fff9, #ff8c00); animation:floatingLines 3s linear infinite; opacity:0.5;"></div>
            <div style="max-width:1000px; margin:0 auto; background:rgba(0,0,0,0.7); border-radius:10px; padding:20px; box-shadow:0 0 30px rgba(255,0,255,0.3); position:relative; z-index:1;">
              ${bannerHtml}
              ${/* ※ 商品画像 (picHtml) の処理を必要に応じて追加してください */ ''}
              <h1 style="text-align:center; font-size:34px; margin-bottom:30px; color:#00e6e6; text-shadow:0 0 5px #00fff9, 0 0 10px #00fff9;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#ff00ff; text-shadow:0 0 3px #ff00ff;">Key Features</h2>
                  <ul style="list-style-type:none; padding:0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#ff00ff; text-shadow:0 0 3px #ff00ff;">Specifications</h2>
                  ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
                </div>
              </div>
              <div style="margin-top:30px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#00fff9; text-shadow:0 0 3px #00fff9;">Product Description</h2>
                <p style="line-height:1.7;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== SHINAKO DREAM ==================
    shinakoDream: {
      name: 'ShinakoDream',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');

        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? '#f9daf3' : '#fedbf2'};">
                    <td style="padding:8px; border:1px solid #e9badf; font-weight:bold;">${label}</td>
                    <td style="padding:8px; border:1px solid #e9badf;">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background-color: #fceaff;
            border-radius: 10px;
            box-shadow: 0 0 15px rgba(255,182,193,0.3);
            overflow: hidden;
            font-family: 'Verdana', sans-serif;
            color: #5b3f5e;
            padding: 20px;
          ">
            ${bannerHtml}
            <div style="padding:30px;">
              <h1 style="text-align:center; font-size:34px; color:#d14eb6; text-shadow:1px 1px 0 #fedbf2; margin-bottom:30px;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.5); border-radius:8px; padding:20px;">
                  <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Features</h2>
                  <ul style="list-style-type:circle; padding-left:20px;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.5); border-radius:8px; padding:20px;">
                  <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Specifications</h2>
                  ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
                </div>
              </div>
              <div style="background-color:rgba(255,255,255,0.5); margin-top:30px; padding:20px; border-radius:8px;">
                <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Product Description</h2>
                <p style="line-height:1.6;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== WAFU SAKURA ==================
    wafuSakura: {
      name: 'WafuSakura',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');

        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%; background-color:rgba(255,255,255,0.8); border-radius:4px;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'};">
                    <td style="padding:8px; font-weight:bold; border:1px solid rgba(140,80,80,0.2);">${label}</td>
                    <td style="padding:8px; border:1px solid rgba(140,80,80,0.2);">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        } else {
          specsHtml = '<p style="margin:0;">(No Specifications)</p>';
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%);
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            overflow: hidden;
            font-family:'Hiragino Mincho ProN', '游明朝', serif;
            color: #4c3b3b;
            padding: 30px;
          ">
            ${bannerHtml}
            <div style="padding:20px;">
              <h1 style="text-align:center; font-size:32px; margin-bottom:25px; color:#8e4a49; border-bottom:2px solid #f8bac6; padding-bottom:10px;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px;">
                  <h2 style="font-size:24px; margin-bottom:15px;">Key Features</h2>
                  <ul style="list-style:none; padding-left:0; margin:0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px;">
                  <h2 style="font-size:24px; margin-bottom:15px;">Specifications</h2>
                  ${specsHtml}
                </div>
              </div>
              <div style="margin-top:30px;">
                <h2 style="font-size:24px; margin-bottom:15px;">Product Description</h2>
                <p style="line-height:1.6;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
    
    // ================== CYBER NEON ==================
    cyberNeon: {
      name: 'CyberNeon',
      generateHTML: (title, features, description, specifications, userSettings) => {
               // 1) Features の前処理
               const parsedFeatures = parseMarkdownBold(features);
               const allFeatureLines = parsedFeatures
                 .split('\n')
                 .map(line => line.trim())
                 .filter(line => line.length > 0);
               // 通常行（"C:" で始まらない）の先頭7行を抽出
               const normalLines = allFeatureLines.filter(line => !line.startsWith('C:')).slice(0, 7);
               // "C:"で始まる行はすべて抽出
               const cLines = allFeatureLines.filter(line => line.startsWith('C:'));
               // 両方を連結
               const featureLines = [...normalLines, ...cLines];
       
               const featuresListHtml = featureLines.map(feature => {
                 const highlighted = highlightKeywords(feature);
                 return `<li style="margin-bottom:6px;">
                           <span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}
                         </li>`;
               }).join('');


        const specsEntries = Object.entries(specifications)
          .filter(([_, value]) => value !== 'NA' && value !== '');
        let specsHtml = '';
        if (specsEntries.length > 0) {
          specsHtml = `
            <table style="border-collapse:collapse; width:100%; color:#fff;">
              ${specsEntries.map(([key, value], index) => {
                const label = key.replace(/^C:/, '');
                return `
                  <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.07)' : 'rgba(255,255,255,0.05)'};">
                    <td style="padding:8px; font-weight:bold; border:1px solid rgba(255,255,255,0.1);">${label}</td>
                    <td style="padding:8px; border:1px solid rgba(255,255,255,0.1);">${value}</td>
                  </tr>
                `;
              }).join('')}
            </table>
          `;
        }
        let bannerHtml = '';
        if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
          bannerHtml = `
            <div style="text-align:center; margin-bottom:20px;">
              <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
            </div>
          `;
        }
        return `
          <div style="
            max-width: 800px;
            margin: 20px auto;
            background:#0f0f0f;
            color:#fff;
            padding:30px;
            position:relative;
            overflow:hidden;
            font-family: 'Roboto', 'Arial', sans-serif;
          ">
            <style>
              @keyframes floatingLines {
                0% { transform: translateX(-100%) skewX(-15deg); }
                100% { transform: translateX(100%) skewX(-15deg); }
              }
            </style>
            <div style="position:absolute; top:0; left:0; width:200%; height:2px; background:linear-gradient(90deg, #d800ff, #00fff9, #ff8c00); animation:floatingLines 3s linear infinite; opacity:0.5;"></div>
            <div style="max-width:1000px; margin:0 auto; background:rgba(0,0,0,0.7); border-radius:10px; padding:20px; box-shadow:0 0 30px rgba(255,0,255,0.3); position:relative; z-index:1;">
              ${bannerHtml}
              ${/* 商品画像 (picHtml) の処理は必要に応じて追加 */ ''}
              <h1 style="text-align:center; font-size:34px; margin-bottom:30px; color:#00e6e6; text-shadow:0 0 5px #00fff9, 0 0 10px #00fff9;">${title}</h1>
              <div style="display:flex; flex-wrap:wrap; gap:20px;">
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#ff00ff; text-shadow:0 0 3px #ff00ff;">Key Features</h2>
                  <ul style="list-style-type:none; padding:0;">
                    ${featuresListHtml}
                  </ul>
                </div>
                <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
                  <h2 style="font-size:24px; margin-bottom:15px; color:#ff00ff; text-shadow:0 0 3px #ff00ff;">Specifications</h2>
                  ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
                </div>
              </div>
              <div style="margin-top:30px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
                <h2 style="font-size:24px; margin-bottom:15px; color:#00fff9; text-shadow:0 0 3px #00fff9;">Product Description</h2>
                <p style="line-height:1.7;">${description}</p>
              </div>
            </div>
          </div>
        `;
      },
    },
  };
  
  export default autoDesignTemplates;
  



// // auto_designTemplates.js

// /**
//  * (A) Markdown の太字（**テキスト**）を <strong> に変換する関数
//  */
// function parseMarkdownBold(str) {
//     return str.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
//   }
  
//   /**
//    * (B) 特定キーワードを太字化する関数
//    */
//   function highlightKeywords(str) {
//     const keywords = ['Brand', 'Condition', 'Size', 'Color', 'Includes'];
//     let result = str;
//     keywords.forEach((kw) => {
//       const re = new RegExp(`(${kw})`, 'gi');
//       result = result.replace(re, '<strong>$1</strong>');
//     });
//     return result;
//   }
  
//   /**
//    * 各テンプレートの自動編集版（auto-design）を定義
//    */
//   const autoDesignTemplates = {
//     // ================== SIMPLE ==================
//     simple: {
//       name: 'Simple',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         // バナー HTML
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         // 商品画像 HTML（最大8枚/行）
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         // 特徴テキストの前処理（Markdown 太字変換＋キーワード強調）
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:6px;"><span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}</li>`;
//           })
//           .join('');
//         // 仕様情報の処理
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:auto;">
//               ${specsEntries
//                 .map(([key, value]) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr>
//                       <td style="padding:6px; border:1px solid #ccc; font-weight:bold; white-space:nowrap;">${label}</td>
//                       <td style="padding:6px; border:1px solid #ccc;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         // 2カラムレイアウト（Key Features / Specifications）
//         const twoColumnBoxHtml = `
//           <div style="display:flex; flex-wrap:wrap; gap:20px; margin-bottom:1em;">
//             <div style="flex:1; min-width:280px; border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#fafafa;">
//               <h2 style="margin-top:0; font-size:1.2em;">Key Features</h2>
//               <ul style="list-style:none; padding-left:0; margin:0;">
//                 ${featuresListHtml}
//               </ul>
//             </div>
//             <div style="flex:1; min-width:280px; border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#fafafa;">
//               <h2 style="margin-top:0; font-size:1.2em;">Specifications</h2>
//               ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//             </div>
//           </div>
//         `;
//         // 全体 HTML 組み立て（タイトル＋サブタイトル、2カラム、追加説明、注意書き）
//         return `
//           <div style="font-family:Arial, sans-serif; line-height:1.5; color:#333;">
//             ${bannerHtml}
//             ${picHtml}
//             <div style="border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#f1f1f1; margin-bottom:1em;">
//               <h1 style="margin-top:0; font-size:1.4em;">${title}</h1>
//               <p style="margin:0; font-size:0.95em;">
//                 ${userSettings?.subtitleText || 'A special edition collectible item for fans and collectors!'}
//               </p>
//             </div>
//             ${twoColumnBoxHtml}
//             ${description ? `
//               <div style="margin-bottom:1em;">
//                 <h2 style="font-size:1em; margin-bottom:0.5em;">Additional Description</h2>
//                 <div style="font-size:0.95em;">
//                   ${description}
//                 </div>
//               </div>
//             ` : ''}
//             ${userSettings?.disclaimerText ? `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">${userSettings.disclaimerText}</p>
//               </div>
//             ` : `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">
//                   We stand behind the authenticity of our products.
//                   All items are thoroughly inspected before listing.
//                   If you have any questions or concerns, please contact us before purchasing.
//                   All sales are final unless the item is not as described.
//                 </p>
//               </div>
//             `}
//           </div>
//         `;
//       }
//     },
  
//     // ================== PROFESSIONAL ==================
//     professional: {
//       name: 'Professional',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:6px;"><span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:auto;">
//               ${specsEntries
//                 .map(([key, value]) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr>
//                       <td style="padding:6px; border:1px solid #ccc; font-weight:bold; white-space:nowrap;">${label}</td>
//                       <td style="padding:6px; border:1px solid #ccc;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         const twoColumnBoxHtml = `
//           <div style="display:flex; flex-wrap:wrap; gap:20px; margin-bottom:1em;">
//             <div style="flex:1; min-width:280px; border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#fafafa;">
//               <h2 style="margin-top:0; font-size:1.2em;">Key Features</h2>
//               <ul style="list-style:none; padding-left:0; margin:0;">
//                 ${featuresListHtml}
//               </ul>
//             </div>
//             <div style="flex:1; min-width:280px; border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#fafafa;">
//               <h2 style="margin-top:0; font-size:1.2em;">Specifications</h2>
//               ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//             </div>
//           </div>
//         `;
//         return `
//           <div style="font-family:Arial, sans-serif; line-height:1.5; color:#333;">
//             <div style="border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#f1f1f1; margin-bottom:1em;">
//               <h1 style="margin-top:0; font-size:1.4em;">${title}</h1>
//               <p style="margin:0; font-size:0.95em;">
//                 ${userSettings?.subtitleText || 'A special edition collectible item for fans and collectors!'}
//               </p>
//             </div>
//             ${bannerHtml}
//             ${picHtml}
//             ${twoColumnBoxHtml}
//             ${description ? `
//               <div style="margin-bottom:1em;">
//                 <h2 style="font-size:1em; margin-bottom:0.5em;">Additional Description</h2>
//                 <div style="font-size:0.95em;">
//                   ${description}
//                 </div>
//               </div>
//             ` : ''}
//             ${userSettings?.disclaimerText ? `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">${userSettings.disclaimerText}</p>
//               </div>
//             ` : `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">
//                   We stand behind the authenticity of our products.
//                   All items are thoroughly inspected before listing.
//                   If you have any questions or concerns, please contact us before purchasing.
//                   All sales are final unless the item is not as described.
//                 </p>
//               </div>
//             `}
//           </div>
//         `;
//       }
//     },
  
//     // ================== MODERN ==================
//     modern: {
//       name: 'Modern',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:6px;"><span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:auto;">
//               ${specsEntries
//                 .map(([key, value]) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr>
//                       <td style="padding:6px; border:1px solid #ccc; font-weight:bold; white-space:nowrap;">${label}</td>
//                       <td style="padding:6px; border:1px solid #ccc;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         const twoColumnBoxHtml = `
//           <div style="display:flex; flex-wrap:wrap; gap:20px; margin-bottom:1em;">
//             <div style="flex:1; min-width:280px; border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#f7fafc;">
//               <h2 style="margin-top:0; font-size:1.2em;">Key Features</h2>
//               <ul style="list-style:none; padding-left:0; margin:0;">
//                 ${featuresListHtml}
//               </ul>
//             </div>
//             <div style="flex:1; min-width:280px; border:2px solid #ccc; border-radius:6px; padding:15px; background-color:#f7fafc;">
//               <h2 style="margin-top:0; font-size:1.2em;">Specifications</h2>
//               ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//             </div>
//           </div>
//         `;
//         return `
//           <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; max-width:1000px; margin:0 auto; padding:20px; color:#2d3748; background-color:#f7fafc;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color:#2b6cb0; font-size:28px; font-weight:600; text-align:center; margin-bottom:30px;">${title}</h1>
//             ${twoColumnBoxHtml}
//             ${description ? `
//               <div style="margin-bottom:30px;">
//                 <h2 style="color:#3182ce; font-size:22px; margin-bottom:15px;">Additional Description</h2>
//                 <p style="line-height:1.7;">${description}</p>
//               </div>
//             ` : ''}
//             ${userSettings?.disclaimerText ? `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">${userSettings.disclaimerText}</p>
//               </div>
//             ` : `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">
//                   We stand behind the authenticity of our products.
//                   All items are thoroughly inspected before listing.
//                   If you have any questions or concerns, please contact us before purchasing.
//                   All sales are final unless the item is not as described.
//                 </p>
//               </div>
//             `}
//           </div>
//         `;
//       }
//     },
  
//     // ================== ELEGANT ==================
//     elegant: {
//       name: 'Elegant',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:6px;"><span style="color:#2ecc71; font-weight:bold;">✔</span> ${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:auto;">
//               ${specsEntries
//                 .map(([key, value]) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr>
//                       <td style="padding:12px; border:1px solid #ecc94b; font-weight:bold;">${label}</td>
//                       <td style="padding:12px; border:1px solid #ecc94b;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         const twoColumnBoxHtml = `
//           <div style="display:flex; flex-wrap:wrap; gap:20px; margin-bottom:1em;">
//             <div style="flex:1; min-width:280px; background-color:#fffaf0; padding:15px; border-radius:8px;">
//               <h2 style="margin-top:0; font-size:1.2em; color:#975a16;">Key Features</h2>
//               <ul style="list-style:none; padding-left:0; margin:0;">
//                 ${featuresListHtml}
//               </ul>
//             </div>
//             <div style="flex:1; min-width:280px; background-color:#fffaf0; padding:15px; border-radius:8px;">
//               <h2 style="margin-top:0; font-size:1.2em; color:#975a16;">Specifications</h2>
//               ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//             </div>
//           </div>
//         `;
//         return `
//           <div style="font-family:'Georgia', serif; max-width:1000px; margin:0 auto; padding:20px; color:#2d3748; background-color:#fffaf0;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="text-align:center; font-size:32px; margin-bottom:30px; font-weight:normal; border-bottom:2px solid #d69e2e; padding-bottom:10px; color:#744210;">${title}</h1>
//             ${twoColumnBoxHtml}
//             ${description ? `
//               <div style="margin-bottom:40px;">
//                 <h2 style="font-size:24px; margin-bottom:20px; color:#975a16;">Additional Description</h2>
//                 <p style="line-height:1.8; text-align:justify;">${description}</p>
//               </div>
//             ` : ''}
//             ${userSettings?.disclaimerText ? `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">${userSettings.disclaimerText}</p>
//               </div>
//             ` : `
//               <div style="font-size:0.85em; color:#666;">
//                 <p style="margin:0;">
//                   We stand behind the authenticity of our products.
//                   All items are thoroughly inspected before listing.
//                   If you have any questions or concerns, please contact us before purchasing.
//                   All sales are final unless the item is not as described.
//                 </p>
//               </div>
//             `}
//           </div>
//         `;
//       }
//     },
  
//     // ================== MINIMALIST ==================
//     minimalist: {
//       name: 'Minimalist',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:10px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:100%;">
//               ${specsEntries
//                 .map(([key, value]) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr>
//                       <td style="padding:8px; border-bottom:1px solid #ccc;">${label}</td>
//                       <td style="padding:8px; border-bottom:1px solid #ccc;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Helvetica', sans-serif; max-width:800px; margin:0 auto; padding:20px; color:#333;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="font-size:28px; text-align:center; margin-bottom:40px;">${title}</h1>
//             <div>
//               <h2 style="font-size:22px; margin-bottom:20px;">Features</h2>
//               <ul style="list-style-type:disc; padding-left:20px;">
//                 ${featuresListHtml}
//               </ul>
//             </div>
//             <div style="margin-top:30px;">
//               <h2 style="font-size:22px; margin-bottom:20px;">Specifications</h2>
//               ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//             </div>
//             <div style="margin-top:30px;">
//               <h2 style="font-size:22px; margin-bottom:20px;">Description</h2>
//               <p style="line-height:1.6;">${description}</p>
//             </div>
//           </div>
//         `;
//       }
//     },
  
//     // ================== SAKURA UMBRELLA ==================
//     sakuraUmbrella: {
//       name: 'SakuraUmbrella',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:8px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:100%; background-color:rgba(255,255,255,0.8); border-radius:4px;">
//               ${specsEntries
//                 .map(([key, value], index) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr style="background-color: ${index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'};">
//                       <td style="padding:8px; font-weight:bold; border:1px solid rgba(140,80,80,0.2);">${label}</td>
//                       <td style="padding:8px; border:1px solid rgba(140,80,80,0.2);">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Hiragino Mincho ProN', '游明朝', serif; background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%); color:#4c3b3b; padding:30px;">
//             <div style="max-width:1000px; margin:0 auto; background-color:rgba(255,255,255,0.8); border-radius:10px; padding:20px; box-shadow:0 0 10px rgba(0,0,0,0.1);">
//               ${bannerHtml}
//               ${picHtml}
//               <h1 style="text-align:center; font-size:32px; margin-bottom:25px; color:#8e4a49; border-bottom:2px solid #f8bac6; padding-bottom:10px;">${title}</h1>
//               <div style="display:flex; flex-wrap:wrap; gap:20px;">
//                 <div style="flex:1; min-width:300px; background: url('https://cdn.pixabay.com/photo/2017/03/20/08/52/sakura-2156305_1280.jpg') no-repeat center/cover; border-radius:8px; padding:20px; color:#4A3A3A;">
//                   <h2 style="font-size:24px; margin-bottom:15px; text-shadow:1px 1px 4px rgba(0,0,0,0.3);">Key Features</h2>
//                   <ul style="list-style-type:none; padding:0;">
//                     ${featuresListHtml}
//                   </ul>
//                 </div>
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.6); border-radius:8px; padding:20px;">
//                   <h2 style="font-size:24px; margin-bottom:15px; color:#8e4a49;">Specifications</h2>
//                   ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//                 </div>
//               </div>
//               <div style="margin-top:30px; background-color:rgba(255,255,255,0.6); border-radius:8px; padding:20px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#8e4a49;">Product Description</h2>
//                 <p style="line-height:1.6;">${description}</p>
//               </div>
//             </div>
//           </div>
//         `;
//       }
//     },
  
//     // ================== NINAGAWA STYLE ==================
//     ninagawaStyle: {
//       name: 'NinagawaStyle',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:10px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:auto;">
//               ${specsEntries
//                 .map(([key, value], index) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.25)'};">
//                       <td style="padding:8px; font-weight:bold; color:#fff;">${label}</td>
//                       <td style="padding:8px; color:#fffee0;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Trebuchet MS', sans-serif; padding:20px; background: linear-gradient(45deg, #ff8ec7, #ffd747); color:#fff;">
//             <div style="max-width:1000px; margin:0 auto;">
//               ${bannerHtml}
//               ${picHtml}
//               <h1 style="font-size:36px; font-weight:bold; text-align:center; margin-bottom:30px; text-shadow:2px 2px #ff4791;">${title}</h1>
//               <div style="display:flex; flex-wrap:wrap; gap:20px;">
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.2); padding:20px; border-radius:10px;">
//                   <h2 style="font-size:24px; margin-bottom:15px; color:#fffee0;">Key Features</h2>
//                   <ul style="list-style:none; padding:0;">
//                     ${featuresListHtml}
//                   </ul>
//                 </div>
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.2); padding:20px; border-radius:10px;">
//                   <h2 style="font-size:24px; margin-bottom:15px; color:#fffee0;">Specifications</h2>
//                   <table style="width:100%; border-collapse:collapse;">
//                     ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//                   </table>
//                 </div>
//               </div>
//               <div style="background-color:rgba(255,255,255,0.15); margin-top:30px; padding:20px; border-radius:10px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#fffee0;">Product Description</h2>
//                 <p style="line-height:1.8;">${description}</p>
//               </div>
//             </div>
//           </div>
//         `;
//       }
//     },
  
//     // ================== KYARY POP ==================
//     kyaryPop: {
//       name: 'KyaryPop',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; border:2px dotted #ff1493; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:10px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="width:100%; border-collapse:collapse; border:2px dotted #ff1493;">
//               ${specsEntries
//                 .map(([key, value], index) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr style="background-color: ${index % 2 === 0 ? '#ffd1ec' : '#d1faff'};">
//                       <td style="padding:10px; border:1px dashed #ff1493; font-weight:bold;">${label}</td>
//                       <td style="padding:10px; border:1px dashed #ff1493;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Comic Sans MS', cursive, sans-serif; background: repeating-linear-gradient(-45deg, #ffa6e6, #ffa6e6 20px, #a6f9ff 20px, #a6f9ff 40px); padding:30px; color:#333;">
//             <div style="max-width:1000px; margin:0 auto; background-color:rgba(255,255,255,0.8); border-radius:10px; padding:20px;">
//               ${bannerHtml}
//               ${picHtml}
//               <h1 style="text-align:center; font-size:32px; margin-bottom:30px; color:#ff1493; text-shadow:2px 2px #fdfd96;">${title}</h1>
//               <div style="margin-bottom:30px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#ff69b4;">Features</h2>
//                 <ul style="padding-left:20px;">
//                   ${featuresListHtml}
//                 </ul>
//               </div>
//               <div style="margin-bottom:30px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#ff69b4;">Specifications</h2>
//                 ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//               </div>
//               <div style="margin-bottom:30px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#ff69b4;">Description</h2>
//                 <p style="line-height:1.6; background-color:#fff; padding:10px; border-radius:5px;">${description}</p>
//               </div>
//             </div>
//           </div>
//         `;
//       }
//     },
  
//     // ================== SHINAKO DREAM ==================
//     shinakoDream: {
//       name: 'ShinakoDream',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:8px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:100%;">
//               ${specsEntries
//                 .map(([key, value], index) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr style="background-color: ${index % 2 === 0 ? '#f9daf3' : '#fedbf2'};">
//                       <td style="padding:8px; border:1px solid #e9badf; font-weight:bold;">${label}</td>
//                       <td style="padding:8px; border:1px solid #e9badf;">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Verdana', sans-serif; background-color:#fceaff; padding:20px; color:#5b3f5e;">
//             <div style="max-width:1000px; margin:0 auto; border-radius:10px; background: linear-gradient(135deg, #fff0fa 30%, #fceaff 70%); box-shadow:0 0 15px rgba(255,182,193,0.3); padding:30px;">
//               ${bannerHtml}
//               ${picHtml}
//               <h1 style="text-align:center; font-size:34px; color:#d14eb6; text-shadow:1px 1px 0 #fedbf2; margin-bottom:30px;">${title}</h1>
//               <div style="display:flex; flex-wrap:wrap; gap:20px;">
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.5); border-radius:8px; padding:20px;">
//                   <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Features</h2>
//                   <ul style="list-style-type:circle; padding-left:20px;">
//                     ${featuresListHtml}
//                   </ul>
//                 </div>
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.5); border-radius:8px; padding:20px;">
//                   <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Specifications</h2>
//                   ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//                 </div>
//               </div>
//               <div style="background-color:rgba(255,255,255,0.5); margin-top:30px; padding:20px; border-radius:8px;">
//                 <h2 style="font-size:22px; margin-bottom:15px; color:#c63b9f;">Product Description</h2>
//                 <p style="line-height:1.6;">${description}</p>
//               </div>
//             </div>
//           </div>
//         `;
//       }
//     },
  
//     // ================== WAFU SAKURA ==================
//     wafuSakura: {
//       name: 'WafuSakura',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:8px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:100%; background-color:rgba(255,255,255,0.8); border-radius:4px;">
//               ${specsEntries
//                 .map(([key, value], index) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr style="background-color: ${index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'};">
//                       <td style="padding:8px; font-weight:bold; border:1px solid rgba(140,80,80,0.2);">${label}</td>
//                       <td style="padding:8px; border:1px solid rgba(140,80,80,0.2);">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Hiragino Mincho ProN', '游明朝', serif; background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%); color:#4c3b3b; padding:30px;">
//             <div style="max-width:1000px; margin:0 auto; background-color:rgba(255,255,255,0.8); border-radius:10px; padding:20px; box-shadow:0 0 10px rgba(0,0,0,0.1);">
//               ${bannerHtml}
//               ${picHtml}
//               <h1 style="text-align:center; font-size:32px; margin-bottom:25px; color:#8e4a49; border-bottom:2px solid #f8bac6; padding-bottom:10px;">${title}</h1>
//               <div style="display:flex; flex-wrap:wrap; gap:20px;">
//                 <div style="flex:1; min-width:300px; background: url('https://cdn.pixabay.com/photo/2017/03/20/08/52/sakura-2156305_1280.jpg') no-repeat center/cover; border-radius:8px; padding:20px; color:#4A3A3A;">
//                   <h2 style="font-size:24px; margin-bottom:15px; text-shadow:1px 1px 4px rgba(0,0,0,0.3);">Key Features</h2>
//                   <ul style="list-style-type:none; padding:0;">
//                     ${featuresListHtml}
//                   </ul>
//                 </div>
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.6); border-radius:8px; padding:20px;">
//                   <h2 style="font-size:24px; margin-bottom:15px; color:#8e4a49;">Specifications</h2>
//                   ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//                 </div>
//               </div>
//               <div style="margin-top:30px; background-color:rgba(255,255,255,0.6); border-radius:8px; padding:20px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#8e4a49;">Product Description</h2>
//                 <p style="line-height:1.6;">${description}</p>
//               </div>
//             </div>
//           </div>
//         `;
//       }
//     },
  
//     // ================== CYBER NEON ==================
//     cyberNeon: {
//       name: 'CyberNeon',
//       generateHTML: (title, features, description, specifications, userSettings) => {
//         let bannerHtml = '';
//         if (userSettings?.bannerEnabled && userSettings.bannerUrl) {
//           bannerHtml = `
//             <div style="text-align:center; margin-bottom:20px;">
//               <img src="${userSettings.bannerUrl}" alt="Banner" style="max-width:100%; height:auto;" />
//             </div>
//           `;
//         }
//         let picHtml = '';
//         if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//           const chunkSize = 8;
//           const rows = [];
//           for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//             const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//             const cols = chunk
//               .map(url => `
//                 <td style="padding:5px;">
//                   <img src="${url}" alt="Product Image" style="max-width:120px; height:auto;" />
//                 </td>
//               `)
//               .join('');
//             rows.push(`<tr>${cols}</tr>`);
//           }
//           picHtml = `
//             <div style="overflow-x:auto; margin-bottom:20px;">
//               <table style="border-collapse:collapse; margin:0 auto;">
//                 ${rows.join('')}
//               </table>
//             </div>
//           `;
//         }
//         const parsedFeatures = parseMarkdownBold(features);
//         const featureLines = parsedFeatures
//           .split('\n')
//           .map(line => line.trim())
//           .filter(line => line.length > 0)
//           .slice(0, 7);
//         const featuresListHtml = featureLines
//           .map(feature => {
//             const highlighted = highlightKeywords(feature);
//             return `<li style="margin-bottom:10px;">${highlighted}</li>`;
//           })
//           .join('');
//         const specsEntries = Object.entries(specifications)
//           .filter(([_, value]) => value !== 'NA' && value !== '');
//         let specsHtml = '';
//         if (specsEntries.length > 0) {
//           specsHtml = `
//             <table style="border-collapse:collapse; width:100%; color:#fff;">
//               ${specsEntries
//                 .map(([key, value], index) => {
//                   const label = key.replace(/^C:/, '');
//                   return `
//                     <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.07)' : 'rgba(255,255,255,0.05)'};">
//                       <td style="padding:8px; font-weight:bold; border:1px solid rgba(255,255,255,0.1);">${label}</td>
//                       <td style="padding:8px; border:1px solid rgba(255,255,255,0.1);">${value}</td>
//                     </tr>
//                   `;
//                 })
//                 .join('')}
//             </table>
//           `;
//         }
//         return `
//           <div style="font-family:'Roboto', 'Arial', sans-serif; background:#0f0f0f; color:#fff; padding:30px; position:relative; overflow:hidden;">
//             <style>
//               @keyframes floatingLines {
//                 0% { transform: translateX(-100%) skewX(-15deg); }
//                 100% { transform: translateX(100%) skewX(-15deg); }
//               }
//             </style>
//             <div style="position:absolute; top:0; left:0; width:200%; height:2px; background:linear-gradient(90deg, #d800ff, #00fff9, #ff8c00); animation:floatingLines 3s linear infinite; opacity:0.5;"></div>
//             <div style="max-width:1000px; margin:0 auto; background:rgba(0,0,0,0.7); border-radius:10px; padding:20px; box-shadow:0 0 30px rgba(255,0,255,0.3); position:relative; z-index:1;">
//               ${bannerHtml}
//               ${picHtml}
//               <h1 style="text-align:center; font-size:34px; margin-bottom:30px; color:#00e6e6; text-shadow:0 0 5px #00fff9, 0 0 10px #00fff9;">${title}</h1>
//               <div style="display:flex; flex-wrap:wrap; gap:20px;">
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
//                   <h2 style="font-size:24px; margin-bottom:15px; color:#ff00ff; text-shadow:0 0 3px #ff00ff;">Key Features</h2>
//                   <ul style="list-style-type:none; padding:0;">
//                     ${featuresListHtml}
//                   </ul>
//                 </div>
//                 <div style="flex:1; min-width:300px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
//                   <h2 style="font-size:24px; margin-bottom:15px; color:#ff00ff; text-shadow:0 0 3px #ff00ff;">Specifications</h2>
//                   ${specsHtml ? specsHtml : '<p style="margin:0;">(No Specifications)</p>'}
//                 </div>
//               </div>
//               <div style="margin-top:30px; background-color:rgba(255,255,255,0.05); border:1px solid rgba(255,255,255,0.1); border-radius:8px; padding:20px;">
//                 <h2 style="font-size:24px; margin-bottom:15px; color:#00fff9; text-shadow:0 0 3px #00fff9;">Product Description</h2>
//                 <p style="line-height:1.7;">${description}</p>
//               </div>
//             </div>
//           </div>
//         `;
//       }
//     },
//   };
  
//   export default autoDesignTemplates;
  