// EditableSpreadsheet.js

import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import "./EditableSpreadsheet.css";
import BulkColumnEdit from "./BulkColumnEdit";

// モーダルのアクセシビリティ設定
Modal.setAppElement("#root");

function EditableSpreadsheet({
  data,
  setData,
  columns,
  setColumns,
  setIsDataModified,
  userId,
  initialData,
  setInitialData,
}) {
  // ステート変数の定義
  const [error, setError] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  // セル編集用のステートを追加
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [cellModalValue, setCellModalValue] = useState("");
  const [cellModalRowIndex, setCellModalRowIndex] = useState(null);
  const [cellModalColKey, setCellModalColKey] = useState(null);
  const [isUpdatingCell, setIsUpdatingCell] = useState(false);
  const [isPriceCheckModalOpen, setIsPriceCheckModalOpen] = useState(false); // 価格確認モーダル

    // StartPriceの直前に「変更前価格」カラムを挿入するuseEffect
  useEffect(() => {
    if (columns.includes("StartPrice") && !columns.includes("変更前価格")) {
      const startPriceIndex = columns.indexOf("StartPrice");
      const newColumns = [
        ...columns.slice(0, startPriceIndex),
        "変更前価格",
        ...columns.slice(startPriceIndex),
      ];
      setColumns(newColumns);
    }
  }, [columns, setColumns]);

  // 必須変更カラム
  const requiredColumns = [
    "Title",
    "StartPrice",
    "Description",
    "Category",
    "PaymentProfileName",
    "ReturnProfileName",
    "ShippingProfileName",
    "MinimumBestOfferPrice",
    "BestOfferAutoAcceptPrice",
  ];

  // 推奨変更カラム（'C:' で始まるカラム）
  const recommendedColumns = columns.filter((col) => col.startsWith("C:"));

  // セルの値が空または 'NA' かを判定する関数
  const isCellValueEmptyOrNA = (value) => {
    return (
      value === undefined || value === null || value === "" || value === "NA"
    );
  };

  // プレビュー用のステート
  const [previewItem, setPreviewItem] = useState(null); // プレビューするアイテム
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // プレビューモーダルの開閉

  // カラム幅を一律に管理するステートを追加
  const [columnWidth, setColumnWidth] = useState(150); // デフォルトの幅を150pxに設定

  // カラム順序を管理するステートを追加
  const [columnOrder, setColumnOrder] = useState(columns);

  // カラム順序テンプレートを管理する状態
  const [columnOrderTemplates, setColumnOrderTemplates] = useState({});

  // 選択されたテンプレート名
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  // ローカルストレージからテンプレートを読み込む
  useEffect(() => {
    const savedTemplates = localStorage.getItem("columnOrderTemplates");
    if (savedTemplates) {
      setColumnOrderTemplates(JSON.parse(savedTemplates));
    }
  }, []);

  // テンプレートをローカルストレージに保存
  useEffect(() => {
    localStorage.setItem(
      "columnOrderTemplates",
      JSON.stringify(columnOrderTemplates)
    );
  }, [columnOrderTemplates]);

  // dataが更新されたときにcolumnsを更新
  useEffect(() => {
    if (data.length > 0) {
      const dataColumns = Object.keys(data[0])
        .map((col) => col.trim().replace(/[\r\n]+/g, ""))
        .filter((col) => col !== "_id" && col !== "");
      setColumns(dataColumns);
    }
  }, [data, setColumns]);
  

  // columnsまたはcolumnOrderが更新された場合、columnOrderを更新
  useEffect(() => {
    const newColumnOrder = columnOrder.filter((col) => columns.includes(col));
    const addedColumns = columns.filter((col) => !newColumnOrder.includes(col));
    setColumnOrder([...newColumnOrder, ...addedColumns]);
  }, [columns]);

  // カラム順序テンプレートを適用する関数
  const applyColumnTemplate = (templateName) => {
    if (!templateName) {
      // テンプレート名が空の場合、デフォルトのカラム順序を適用
      setColumnOrder(columns);
      return;
    }
    const templateOrder = columnOrderTemplates[templateName];
    if (templateOrder) {
      const newColumnOrder = templateOrder.filter((col) =>
        columns.includes(col)
      );
      const remainingColumns = columns.filter(
        (col) => !newColumnOrder.includes(col)
      );
      // 残りのカラムを末尾に追加
      setColumnOrder([...newColumnOrder, ...remainingColumns]);
    }
  };

  // セルの更新
  const updateCell = (rowIndex, colKey, newValue) => {
    try {
      setIsUpdatingCell(true); // 更新開始
      const newData = [...data];
      newData[rowIndex] = { ...newData[rowIndex], [colKey]: newValue };
      setData(newData);
      // セルの値が変更された場合、initialDataは変更しない（不変）
      setIsDataModified(true);
      setIsUpdatingCell(false); // 更新終了
    } catch (err) {
      console.error("Error updating cell:", err);
      setError("セルの更新に失敗しました");
      setIsUpdatingCell(false);
    }
  };

  // カラムのクリックハンドラー
  const handleColumnClick = (colIndex) => {
    setSelectedColumn(colIndex);
  };

  // ★★★ ここがポイント ★★★
  // initialDataを_idをキーとしたマップに変換
  const initialDataById = useMemo(() => {
    const map = {};
    initialData.forEach(item => {
      if (item._id) {
        map[item._id] = item;
      }
    });
    return map;
  }, [initialData]);

  // 必要に応じて、データから旧値を参照する関数を作成
  const getOldValue = (rowId, colKey) => {
    const oldItem = initialDataById[rowId];
    if (!oldItem) return undefined;
    return oldItem[colKey];
  };

  // セルのレンダリング関数
// 引数にrow(行データ)を追加して、row._idを参照できるようにする
const renderCell = (cell, rowIndex, colKey, row) => {
  const header = colKey.toLowerCase();
  let cellStyle = {
    cursor: "pointer",
    width: "100%",
    boxSizing: "border-box",
  };

  // 変更前価格カラム
  if (colKey === "変更前価格") {
    let oldPrice = "";
    if (row._id && initialDataById[row._id] && initialDataById[row._id]["StartPrice"] !== undefined) {
      oldPrice = initialDataById[row._id]["StartPrice"];
    }
    return (
      <input
        value={oldPrice || ""}
        readOnly
        style={{
          cursor: "default",
          width: "100%",
          boxSizing: "border-box",
          backgroundColor: "#f0f0f0"
        }}
      />
    );
  }

  const cellValue = cell;
  const oldItem = row._id ? initialDataById[row._id] : null;
  const oldVal = oldItem ? oldItem[colKey] : undefined;

  // 必須カラムでNAまたは空
  if (requiredColumns.includes(colKey) && isCellValueEmptyOrNA(cellValue)) {
    cellStyle.backgroundColor = "#ffcccc";
  }
  // 推奨カラムでNAまたは空
  else if (
    recommendedColumns.includes(colKey) &&
    isCellValueEmptyOrNA(cellValue)
  ) {
    cellStyle.backgroundColor = "#ffffcc";
  }

  // 初期値と異なり、'NA'でも空でもない場合は差分表示
  if (oldVal !== undefined && oldVal !== cellValue && !isCellValueEmptyOrNA(cellValue)) {
    cellStyle.backgroundColor = "#ccffcc";
  }

  if (header === "picurl" || header === "画像") {
    const imageUrls = cellValue?.split("|") || [];
    return (
      <img
        src={imageUrls[0]}
        alt="Product"
        style={{ maxWidth: "100px", maxHeight: "100px" }}
      />
    );
  } else {
    return (
      <input
        value={cellValue || ""}
        onClick={() => {
          setCellModalValue(cellValue || "");
          setCellModalRowIndex(rowIndex);
          setCellModalColKey(colKey);
          setIsCellModalOpen(true);
        }}
        readOnly
        style={cellStyle}
      />
    );
  }
};

  // 単一行を削除
  const handleDeleteRow = (rowIndex) => {
    if (rowIndex < 0) {
      setError("ヘッダー行は削除できません。");
      return;
    }

    try {
      // initialDataは不変なので更新しない
      // ここでdataのみ削除を行う
      const newData = data.filter((_, index) => index !== rowIndex);
      setData(newData);
      setIsDataModified(true);
      setError(null);
    } catch (err) {
      console.error("Error deleting row:", err);
      setError("行の削除に失敗しました");
    }
  };

  // プレビューを表示する関数
  const handlePreview = (row) => {
    setPreviewItem(row);
    setIsPreviewOpen(true);
  };

  // プレビューモーダルを閉じる関数
  const closePreviewModal = () => {
    setIsPreviewOpen(false);
    setPreviewItem(null);
  };

  // モーダルのスタイル設定
  const cellModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "600px",
      maxHeight: "80%",
      overflow: "auto",
      padding: "20px",
      boxSizing: "border-box",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const previewModalStyles = {
    content: {
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: "1000px",
      maxHeight: "90%",
      overflow: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
  };

  const warningModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "600px",
      maxHeight: "80%",
      overflow: "auto",
      padding: "20px",
      boxSizing: "border-box",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  // ページネーションのステート
  const [itemsPerPage, setItemsPerPage] = useState(50); // 表示件数
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ
  const [isLoading, setIsLoading] = useState(false); // ローディング状態

  const itemsPerPageValue = itemsPerPage === "All" ? data.length : itemsPerPage;
  const totalPages =
    itemsPerPage === "All" ? 1 : Math.ceil(data.length / itemsPerPageValue);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [itemsPerPage, currentPage, data]);

  const handleItemsPerPageChange = (e) => {
    const value = e.target.value;
    if (value === "All") {
      setItemsPerPage("All");
    } else {
      setItemsPerPage(parseInt(value));
    }
    setCurrentPage(1); // ページをリセット
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  let currentData = [];
  if (itemsPerPage === "All") {
    currentData = data;
  } else {
    const startIndex = (currentPage - 1) * itemsPerPageValue;
    const endIndex = startIndex + itemsPerPageValue;
    currentData = data.slice(startIndex, endIndex);
  }

  // StartPriceの値をチェックし、該当商品のリストを返す関数（IDベースで対応付け）
const getProductsWithLowerStartPrice = () => {
  if (!columns || columns.length === 0) {
    return [];
  }

  const startPriceKey = columns.find(
    (key) => key.toLowerCase() === "startprice"
  );

  if (!startPriceKey) {
    return [];
  }

  const warningProducts = [];

  // dataに存在する商品のみに対して比較する
  for (let i = 0; i < data.length; i++) {
    const currentItem = data[i];
    const currentId = currentItem._id;
    const currentPriceStr = currentItem[startPriceKey];

    // initialDataByIdから対応する初期データを取得
    const oldItem = currentId ? initialDataById[currentId] : null;
    if (!oldItem) {
      // initialDataに存在しない（新規追加など）の場合はスキップ
      continue;
    }

    const initialPriceStr = oldItem[startPriceKey];

    const currentPrice = parseFloat(currentPriceStr);
    const initialPrice = parseFloat(initialPriceStr);

    if (!isNaN(currentPrice) && !isNaN(initialPrice)) {
      // 条件を調整可能: ここではinitialPrice以上の場合に警告
      if (currentPrice <= initialPrice) {
        warningProducts.push({
          ...currentItem,
          index: i + 1, // 行番号（1から始まる）
        });
      }
    }
  }
  return warningProducts;
};


  // CSVダウンロード関連処理
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningProducts, setWarningProducts] = useState([]);
  const [pendingFilename, setPendingFilename] = useState("data.csv");

  const handleDownloadCSV = () => {
    const filename = prompt("保存するCSVファイル名を入力してください（例: data.csv）", "data.csv");
    if (filename) {
      const warningProductsList = getProductsWithLowerStartPrice();
      if (warningProductsList.length > 0) {
        setWarningProducts(warningProductsList);
        setIsWarningModalOpen(true);
        setPendingFilename(filename);
      } else {
        proceedToDownloadCSV(filename);
      }
    }
  };

  const proceedToDownloadCSV = (filename = "data.csv") => {
    const csvContent = generateCSVContent(data);
    downloadCSV(csvContent, filename);
  };

  const generateCSVContent = (data) => {
    if (!data || data.length === 0) return "";

    // _idは内部管理用フィールドであれば除外可能
    const headers = Object.keys(data[0])
      .map(h => h.trim().replace(/[\r\n]+/g, ''))
      .filter((h) => h !== '_id');
    const csvRows = [];

    // ヘッダー行
    csvRows.push(headers.join(","));

    // データ行
    data.forEach((row) => {
      const values = headers.map((header) => {
        // 値が存在しない場合は空文字にする
      let value = row[header] !== undefined && row[header] !== null ? row[header] : "";
      // 改行文字（\r, \n）をスペースに置換または削除する
      value = ("" + value).replace(/[\r\n]+/g, " ").trim();
        // const value =
        //   row[header] !== undefined && row[header] !== null ? row[header] : "";
        const escaped = ("" + value).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  const downloadCSV = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  // 価格目視確認モーダル用のスタイル（任意で調整可能）
  const priceCheckModalStyles = {
    content: {
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxHeight: "80%",
      overflow: "auto",
      padding: "20px",
      boxSizing: "border-box",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 1000,
    },
  };

  return (
    <div className="editable-spreadsheet-container">
      <BulkColumnEdit
        data={data}
        setData={setData}
        columns={columns}
        setColumns={setColumns}
        columnOrder={columnOrder}
        setIsDataModified={setIsDataModified}
        userId={userId}
        columnOrderTemplates={columnOrderTemplates}
        setColumnOrderTemplates={setColumnOrderTemplates}
        applyColumnTemplate={applyColumnTemplate}
        selectedTemplateName={selectedTemplateName}
        setSelectedTemplateName={setSelectedTemplateName}
        setColumnOrder={setColumnOrder}
        columnWidth={columnWidth}
        setColumnWidth={setColumnWidth}
        initialData={initialData} // initialDataは不変
        setInitialData={setInitialData} // 基本的に初期セット以降変更しないようにするが、ここではpropとして残す
      />

      {error && <div className="error-message">{error}</div>}

      <div className="top-controls">
        <div className="pagination-controls">
          <label htmlFor="itemsPerPage">表示件数:</label>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value="All">すべて</option>
          </select>
        </div>

        {totalPages > 1 && (
          <div className="pagination-controls">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              前へ
            </button>
            <span>
              {currentPage} / {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              次へ
            </button>
          </div>
        )}

        <div className="csv-download-button-container">
          <button
            onClick={handleDownloadCSV}
            className="csv-download-button"
          >
            CSVをダウンロード
          </button>
          <button
            onClick={() => setIsPriceCheckModalOpen(true)}
            style={{ marginLeft: "10px" }}
          >
            価格目視確認ボタン
          </button>
        </div>
      </div>

      {isLoading && <div className="loading-indicator">読み込み中...</div>}

      <Modal
        isOpen={isPreviewOpen}
        onRequestClose={closePreviewModal}
        contentLabel="商品プレビュー"
        style={previewModalStyles}
      >
        {previewItem && (
          <div className="preview-modal-content">
            <button className="close-modal-button" onClick={closePreviewModal}>
              ×
            </button>
            <div className="preview-header">
              <h2>{previewItem["Title"] || previewItem["title"]}</h2>
            </div>
            <div className="preview-body">
              <div className="preview-images">
                {(() => {
                  const picUrl =
                    previewItem["PicURL"] || previewItem["picurl"] || "";
                  const imageUrls = picUrl.split("|").filter(Boolean);
                  if (imageUrls.length > 0) {
                    return (
                      <div className="image-gallery">
                        <div className="main-image">
                          <img
                            src={imageUrls[0]}
                            alt="Product Main"
                            style={{ maxWidth: "100%", maxHeight: "400px" }}
                          />
                        </div>
                        {imageUrls.length > 1 && (
                          <div className="additional-images">
                            {imageUrls.slice(1).map((url, index) => (
                              <img
                                key={index}
                                src={url}
                                alt={`Product ${index + 2}`}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                  margin: "5px",
                                }}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return <p>画像がありません。</p>;
                  }
                })()}
              </div>
              <div className="preview-details">
                <p>
                  <strong>価格：</strong>{" "}
                  {previewItem["StartPrice"] ||
                    previewItem["startprice"] ||
                    "N/A"}
                </p>
                <p>
                  <strong>説明：</strong>
                </p>
                <div
                  className="item-description"
                  dangerouslySetInnerHTML={{
                    __html:
                      previewItem["Description"] ||
                      previewItem["description"] ||
                      "",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={isCellModalOpen}
        onRequestClose={() => setIsCellModalOpen(false)}
        contentLabel="セルの編集"
        style={cellModalStyles}
      >
        <div>
          <h2 className="cell-modal-title">セルの編集</h2>
          <textarea
            value={cellModalValue}
            onChange={(e) => setCellModalValue(e.target.value)}
            style={{
              width: "100%",
              height: "200px",
              fontSize: "16px",
              lineHeight: "1.5",
              fontFamily: "Arial, sans-serif",
              color: "#333",
              padding: "10px",
              boxSizing: "border-box",
            }}
            disabled={isUpdatingCell}
          />
          <div style={{ marginTop: "20px", textAlign: "right" }}>
            <button
              onClick={() => {
                updateCell(cellModalRowIndex, cellModalColKey, cellModalValue);
                setIsCellModalOpen(false);
              }}
              className="cell-modal-button"
              style={{ marginRight: "10px" }}
              disabled={isUpdatingCell}
            >
              保存
            </button>
            <button
              onClick={() => setIsCellModalOpen(false)}
              className="cell-modal-button"
              disabled={isUpdatingCell}
            >
              キャンセル
            </button>
            {isUpdatingCell && (
              <div
                className="loader"
                style={{ display: "inline-block", marginLeft: "10px" }}
              ></div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isWarningModalOpen}
        onRequestClose={() => setIsWarningModalOpen(false)}
        contentLabel="警告"
        style={warningModalStyles}
      >
        <div>
          <h2>警告</h2>
          <p>
            StartPriceの値が初期インポートされた値と同一またはそれ以下になっている商品があります。CSVをダウンロードしますか？
          </p>
          <p>該当の商品:</p>
          <ul>
            {warningProducts.map((product, index) => (
              <li key={index}>
                {product["Title"] || product["title"] || "タイトルなし"}（行{" "}
                {product.index}）
              </li>
            ))}
          </ul>
          <div style={{ textAlign: "right" }}>
            <button
              onClick={() => {
                setIsWarningModalOpen(false);
                proceedToDownloadCSV(pendingFilename);
              }}
              style={{ marginRight: "10px" }}
            >
              ダウンロードを続行
            </button>
            <button onClick={() => setIsWarningModalOpen(false)}>
              キャンセル
            </button>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={isPriceCheckModalOpen}
        onRequestClose={() => setIsPriceCheckModalOpen(false)}
        contentLabel="価格目視確認"
        style={priceCheckModalStyles}
      >
        <div>
          <h2>価格目視確認</h2>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>#</th>
                <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>Title</th>
                <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>変更前価格</th>
                <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>StartPrice</th>
              </tr>
            </thead>
              <tbody>
                {data.map((row, idx) => {
                  const oldItem = row._id ? initialDataById[row._id] : null;
                  const oldPrice = (oldItem && oldItem["StartPrice"] !== undefined) ? oldItem["StartPrice"] : "";
                  const currentPrice = (row["StartPrice"] !== undefined) ? row["StartPrice"] : "";
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{row["Title"] || ""}</td>
                      <td>{oldPrice}</td>
                      <td>{currentPrice}</td>
                    </tr>
                  );
                })}
              </tbody>
          </table>
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <button onClick={() => setIsPriceCheckModalOpen(false)}>閉じる</button>
          </div>
        </div>
      </Modal>

      {currentData.length === 0 ? (
        <div className="no-data-message">
          データがありません。CSVファイルをインポートしてください。
        </div>
      ) : (
        <div className="spreadsheet-container">
          <div className="table-wrapper">
            <table className="editable-spreadsheet">
              <thead>
                <tr>
                  <th
                    className="row-number-header"
                    style={{
                      width: "50px",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    #
                  </th>
                  <th
                    style={{
                      width: "100px",
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    操作
                  </th>
                  {columnOrder.map((header, colIndex) => (
                    <th
                      key={colIndex}
                      onClick={() => handleColumnClick(colIndex)}
                      className={
                        selectedColumn === colIndex ? "selected-column" : ""
                      }
                      style={{
                        minWidth: "50px",
                        width: `${columnWidth}px`,
                        maxWidth: `${columnWidth}px`,
                        position: "relative",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      title={header}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, rowIndex) => {
                  const globalRowIndex =
                    (currentPage - 1) * itemsPerPageValue + rowIndex;
                  return (
                    <tr key={globalRowIndex}>
                      <td className="row-number" style={{ width: "50px" }}>
                        {globalRowIndex + 1}
                      </td>
                      <td style={{ width: "100px" }}>
                        <div className="action-buttons">
                          <button
                            className="preview-button"
                            onClick={() => handlePreview(row)}
                          >
                            プレビュー
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => handleDeleteRow(globalRowIndex)}
                          >
                            削除
                          </button>
                        </div>
                      </td>
                      {columnOrder.map((colKey, colIndex) => (
                        <td
                        key={colIndex}
                        className={selectedColumn === colIndex ? "selected-column" : ""}
                        style={{
                          minWidth: "50px",
                          width: `${columnWidth}px`,
                          maxWidth: `${columnWidth}px`,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          // ★背景色のロジックは削除
                        }}
                        title={row[colKey]}
                      >
                        {renderCell(row[colKey], globalRowIndex, colKey, row)}
                      </td>                      
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditableSpreadsheet;