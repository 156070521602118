import axios from 'axios';
import PQueue from 'p-queue';
import autoDesignTemplates from './auto_designTemplates';
import { applyFilters } from '../ai_description_func/filters';
import Papa from 'papaparse';
import { getImageUrls } from './CsvSchedulerUploader';

// --- 以下2つはOpenAIDescriptionGenerator.jsから持ってきたものと同じ ---
export const sanitizeDescription = (description) => {
  return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
};

export const cleanHTML = (html) => {
  let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
  cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
  cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
  cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
  return cleanedHtml;
};

// OpenAIDescriptionGenerator.jsの generateFormattedDescription と同じ実装
export const generateFormattedDescription = (
  title,
  features,
  description,
  specifications,
  selectedTemplate,
  userSettings,
  picUrl, // 新たにpicUrlを受け取るパラメータを追加
) => {
  const template = autoDesignTemplates[selectedTemplate];
  // 万が一テンプレが見つからない場合は簡易テンプレにフォールバック
  if (!template || typeof template.generateHTML !== 'function') {
    console.warn(`デザインテンプレート "${selectedTemplate}" が見つかりません。"professional" にフォールバックします。`);
    selectedTemplate = 'professional';
  }

  const finalTemplate = autoDesignTemplates[selectedTemplate];

  let html = finalTemplate.generateHTML(
    title,
    features,
    description,
    specifications,
    userSettings,
    selectedTemplate,
    picUrl // 新たにpicUrlを受け取るパラメータを追加
  );

  const cleanedHtml = cleanHTML(html);
  return cleanedHtml;
};

// キャッシュ機能（必要なければ外してOK）
const descriptionCache = {};
const getCachedDescription = (key) => descriptionCache[key];
const setCachedDescription = (key, value) => {
  descriptionCache[key] = value;
};

// ---------------------------------------------
// 文章削除・置換 (★ 大文字小文字を無視して置換)
// ---------------------------------------------
/**
 * debugUnicodeDifferences
 *  - 文字列strと "target" のUnicodeコードポイントを1文字ずつ出力
 */
function debugUnicodeDifferences(str, target) {
  console.log('=== Debug str ===');
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i).toString(16).padStart(4, '0');
    console.log(`str[${i}]: '${str[i]}' U+${code}`);
  }

  console.log('=== Debug target ===');
  for (let i = 0; i < target.length; i++) {
    const code = target.charCodeAt(i).toString(16).padStart(4, '0');
    console.log(`target[${i}]: '${target[i]}' U+${code}`);
  }
}

/************************************
 * 1) 拡張版 normalizeText
 ************************************/
function extendedNormalizeText(text) {
  if (!text) return '';

  // 1) 改行コードをLFに統一
  let out = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');

  // 2) ノーブレークスペース・ゼロ幅スペース除去
  out = out
    .replace(/\u00A0/g, ' ')
    .replace(/[\u200B-\u200F\uFEFF]/g, '');

  // 3) 代表的な全角記号 → 半角記号
  out = out
    .replace(/\uFF0A/g, '*')  // 全角アスタリスク
    .replace(/\uFF1A/g, ':')  // 全角コロン
    .replace(/\uFF1F/g, '?')  // 全角？
    .replace(/\uFF01/g, '!'); // 全角！

  // 4) 全角英数字 → 半角英数字 (簡易版)
  out = out.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (ch) => {
    return String.fromCharCode(ch.charCodeAt(0) - 0xFEE0);
  });

  return out;
}

/************************************
 * 2) buildPartialMatchRegex
 *    "Product Description" → 柔軟に
 *    **Product Description:** や :** を許容する例
 ************************************/
function buildPartialMatchRegex(raw) {
  // まず正規表現メタ文字をエスケープ
  const escaped = raw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // 単語全体だけをマッチさせるため、前後に単語境界 \b を追加する
  const pattern = `\\b${escaped}\\b`;

  return new RegExp(pattern, 'gi');
}


/************************************
 * 3) DOMParser でタグを壊さずテキスト削除・置換
 ************************************/
function transformFinalHTMLWithDOMParser(html, sentencesToDelete, wordsToReplace) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  function traverse(node) {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        let text = child.nodeValue || '';

        // 3-A: 部分一致で削除
        sentencesToDelete.forEach((raw) => {
          if (raw.trim()) {
            const re = buildPartialMatchRegex(raw);
            // 大文字小文字無視 + 部分一致パターンでまとめて消す
            text = text.replace(re, '');
          }
        });

        // 3-B: 部分一致で置換
        wordsToReplace.forEach(({ from, to }) => {
          if (from.trim()) {
            const re = buildPartialMatchRegex(from);
            text = text.replace(re, to);
          }
        });

        child.nodeValue = text;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        traverse(child);
      }
    });
  }

  traverse(doc.body);

  return doc.body.innerHTML;
}

// ---------------------------------------------
// C: から始まるカラムを収集して item.Specifications にまとめる
// ---------------------------------------------
function collectSpecifications(item) {
  const specs = {};
  Object.keys(item).forEach((key) => {
    if (key.startsWith('C:') && item[key]) {
      specs[key] = item[key];
    }
  });
  return specs;
}

// ---------------------------------------------
// AI特徴文(英語)を生成する関数
// ---------------------------------------------
async function generateFeatures(sourceText, apiKey, mandatoryKeywords = [], maxTokens = 200) {

  console.log('[DEBUG] mandatoryKeywords:', mandatoryKeywords);
  
  const mandatoryKeywordsPrompt = mandatoryKeywords.length > 0
  ? `IMPORTANT: You MUST include the following mandatory keywords **exactly as they are** in the final output. 
If you cannot find a natural place in the text, please include them in the "Item Specifics" area. 
Do not omit or paraphrase them under any circumstances:
${mandatoryKeywords.join(', ')}`
  : '';

  const response = await axios.post(
    'https://api.openai.com/v1/chat/completions',
    {
      model: 'gpt-4o-mini', // 用途に応じてモデルを変更
      messages: [
        {
          role: 'system',
          content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information.
          
          ${mandatoryKeywordsPrompt}

          You are an AI assistant specialized in creating optimized product descriptions for eBay listings. Your task is to analyze the provided Japanese product information and generate a clear, concise, and engaging English product description. Follow these guidelines:

1. Focus solely on describing the product itself—include details such as brand, model, size, color, material, and any distinctive features. Do not include shipping or payment information.

2. If the input contains information corresponding to columns that start with "C:" (for example, "C:Material", "C:Color", "C:Character"), you must output these item specifics in the following exact format at the end of your description:

   C:Character : naruto  
   C:Material : Acrylic  
   C:Color : red  

   - Include each relevant "C:" field as "C:[Field Name] : [Value]".
   - If there is no information for a specific "C:" field, omit that line.
   - If the same "C:" field appears multiple times, aggregate all corresponding values into a single line, separating each value with a comma.
   - Ensure that these mandatory keywords are included exactly as provided.
   - Additionally, if any information closely corresponds to one of the designated "C:" fields, please assign it accordingly. The specified "C:" fields take priority; however, if the information clearly does not fit any of those fields, please create a separate field for that item.

3. Use natural, grammatically correct English. Avoid adding extra or speculative information; only include what is clearly indicated by the input.

For example, if the product information includes details that correspond to the "C:" fields, your output should look like this:

[English product description text here...]

C:Character : naruto  
C:Material : Acrylic  
C:Color : red  

Now, analyze the following Japanese product information and produce the best possible English product description according to these rules:

[Insert Japanese product information here]


**Notes:**
- Ensure that the item specifics are accurate based on the product information.
- If an item specific is not available or unclear, omit it.
- No content other than the above will be output.
- Additional information is not required.
- Do not output information related to shipping or options.
- Use clear and concise English.
- Use bullet points to ensure readability.
- If information is unclear or ambiguous, omit the item.
- Use only reliable information and do not include speculative or uncertain information.
- If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.
- There are places where the same information is output in duplicate, so don't duplicate it.
  Extracting only important information
  Be sure to extract only the parts of the Japanese text that describe the product.

Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`
        },
        {
          role: 'user',
          content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。また、指定された item specifics を抽出してください。出力は${maxTokens}トークン以内に収めてください：「${sourceText}」`
        },
      ],
      max_tokens: maxTokens,
      temperature: 0.1,
    },
    {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return response.data.choices[0].message.content.trim();
}

// 429エラー再試行などにも対応した generateFeaturesWithRetry
async function generateFeaturesWithRetry(
  jpDesc,
  apiKey,
  mandatoryKeywords,
  queue,
  retryCount = 0
) {
  try {
    return await generateFeatures(jpDesc, apiKey, mandatoryKeywords);
  } catch (error) {
    // 429レートリミットエラーが発生した場合、リトライ（最大5回）
    if (error.response && error.response.status === 429 && retryCount < 5) {
      const retryAfter = error.response.headers['retry-after'];
      const delay = retryAfter
        ? parseInt(retryAfter) * 1000
        : 1000 * Math.pow(2, retryCount);
      await new Promise((resolve) => setTimeout(resolve, delay));
      return generateFeaturesWithRetry(jpDesc, apiKey, mandatoryKeywords, queue, retryCount + 1);
    }
    throw error;
  }
}

// ---------------------------------------------
// メインのAI商品説明適用関数
// ---------------------------------------------
export const applyAIProductDescriptions = async (
  currentData,
  apiKey,
  progressCallback,
  selectedDesignTemplate,
  userSettings = {},
  picUrl
) => {

  const { imageAnalysisStrategy } = userSettings;
  if (!apiKey) {
    throw new Error('OpenAI APIキーが設定されていません');
  }

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  let totalItems = 0;
  let completedItems = 0;
  const errorItems = [];

  // 全ファイル分を処理
  const updatedData = await Promise.all(
    currentData.map(async (file) => {
      const parsedResult = Papa.parse(file.content, { header: true });
      const data = parsedResult.data;

      totalItems += data.length;

      // 行ごとにAI生成
       // 各行に対してAI生成を行うタスクを作成
       const generateDescriptionTasks = data.map((item, index) => async () => {
        try {
          const originalTitle = item.Title || '';

          // ★ ここで、画像解析利用戦略に応じて元テキストを決定する
          let sourceText = "";
          if (imageAnalysisStrategy === "alwaysUseImage") {
            sourceText = item.jp_image_analysis || "";
          } else { // "preferExisting"
            sourceText =
              item.jp_desc && item.jp_desc.trim() !== ""
                ? item.jp_desc
                : (item.jp_image_analysis || "");
          }

          // applyFilters で加工
          const filteredSourceText = applyFilters(
            sourceText,
            userSettings.selectedFilters || [],
            userSettings.customFilters || []
          );

          if (!filteredSourceText.trim()) {
            // 十分な日本語情報がなければ、デフォルト値または空文字を設定
            item.Description = item.Description || '';
            completedItems++;
            progressCallback?.({ totalItems, completedItems });
            return;
          }

          // キャッシュキー作成
          const cacheKey = `${originalTitle}_${filteredSourceText.slice(0, 100)}_${selectedDesignTemplate}`;
          const cached = getCachedDescription(cacheKey);
          if (cached) {
            item.Description = cached;
            completedItems++;
            progressCallback?.({ totalItems, completedItems });
            return;
          }

          // AIで英語説明を生成
          let generatedFeatures = await generateFeaturesWithRetry(
            filteredSourceText,
            apiKey,
            userSettings.mandatoryKeywords || [],
            queue
          );
          generatedFeatures = generatedFeatures
            .replace(/I'm sorry[^.]*\.\s*/i, '')
            .replace(/it seems that the product information[^.]*\.\s*/i, '')
            .trim();

          const sanitizedOriginalDesc = sanitizeDescription(item.Description || '');
          const combinedDesc = sanitizedOriginalDesc;
          const specs = collectSpecifications(item);

          // テンプレートに従って最終HTML生成（picUrlはそのまま使用）
          const updatedDescription = generateFormattedDescription(
            item.Title || '',
            generatedFeatures,
            combinedDesc,
            specs,
            selectedDesignTemplate,
            userSettings,
            item.PicURL ? getImageUrls(item.PicURL) : null
          );
          // 7) ★ 最終HTMLを DOMParser でパースし、「部分一致」でテキスト削除・置換
  
          const finalDescription = transformFinalHTMLWithDOMParser(
            updatedDescription,
            userSettings.sentencesToDelete || [],
            userSettings.wordsToReplace || []
          );

          // 8) CSVのDescriptionに反映
          item.Description = finalDescription;

          // 9) キャッシュ保存
          setCachedDescription(cacheKey, finalDescription);

        } catch (err) {
          console.error(`商品説明生成エラー（行 ${index + 1}）:`, err);
          errorItems.push({
            fileName: file.name,
            rowIndex: index + 1,
            error: err.message || String(err),
          });
        } finally {
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
        }
      });

      await queue.addAll(generateDescriptionTasks);

      // CSVに変換して返す
      const csvContent = Papa.unparse(data);
      return {
        ...file,
        content: csvContent,
      };
    })
  );

  return { updatedData, errorItems };
};




// import axios from 'axios';
// import PQueue from 'p-queue';
// import autoDesignTemplates from './auto_designTemplates';
// import { applyFilters } from '../ai_description_func/filters';
// import Papa from 'papaparse';
// import { getImageUrls } from './CsvSchedulerUploader';

// // --- 以下2つはOpenAIDescriptionGenerator.jsから持ってきたものと同じ ---
// export const sanitizeDescription = (description) => {
//   return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
// };

// export const cleanHTML = (html) => {
//   let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
//   cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
//   cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
//   cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
//   return cleanedHtml;
// };

// // OpenAIDescriptionGenerator.jsの generateFormattedDescription と同じ実装
// export const generateFormattedDescription = (
//   title,
//   features,
//   description,
//   specifications,
//   selectedTemplate,
//   userSettings,
//   picUrl // 新たにpicUrlを受け取るパラメータを追加
// ) => {
//   const template = autoDesignTemplates[selectedTemplate];
//   // 万が一テンプレが見つからない場合は簡易テンプレにフォールバック
//   if (!template || typeof template.generateHTML !== 'function') {
//     console.warn(`デザインテンプレート "${selectedTemplate}" が見つかりません。"professional" にフォールバックします。`);
//     selectedTemplate = 'professional';
//   }

//   const finalTemplate = autoDesignTemplates[selectedTemplate];

//   let html = finalTemplate.generateHTML(
//     title,
//     features,
//     description,
//     specifications,
//     userSettings,
//     selectedTemplate,
//     picUrl // 新たにpicUrlを受け取るパラメータを追加
//   );

//   const cleanedHtml = cleanHTML(html);
//   return cleanedHtml;
// };

// // キャッシュ機能（必要なければ外してOK）
// const descriptionCache = {};
// const getCachedDescription = (key) => descriptionCache[key];
// const setCachedDescription = (key, value) => {
//   descriptionCache[key] = value;
// };

// // ---------------------------------------------
// // 文章削除・置換 (★ 大文字小文字を無視して置換)
// // ---------------------------------------------
// /**
//  * debugUnicodeDifferences
//  *  - 文字列strと "target" のUnicodeコードポイントを1文字ずつ出力
//  */
// function debugUnicodeDifferences(str, target) {
//   console.log('=== Debug str ===');
//   for (let i = 0; i < str.length; i++) {
//     const code = str.charCodeAt(i).toString(16).padStart(4, '0');
//     console.log(`str[${i}]: '${str[i]}' U+${code}`);
//   }

//   console.log('=== Debug target ===');
//   for (let i = 0; i < target.length; i++) {
//     const code = target.charCodeAt(i).toString(16).padStart(4, '0');
//     console.log(`target[${i}]: '${target[i]}' U+${code}`);
//   }
// }

// /************************************
//  * 1) 拡張版 normalizeText
//  ************************************/
// function extendedNormalizeText(text) {
//   if (!text) return '';

//   // 1) 改行コードをLFに統一
//   let out = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');

//   // 2) ノーブレークスペース・ゼロ幅スペース除去
//   out = out
//     .replace(/\u00A0/g, ' ')
//     .replace(/[\u200B-\u200F\uFEFF]/g, '');

//   // 3) 代表的な全角記号 → 半角記号
//   out = out
//     .replace(/\uFF0A/g, '*')  // 全角アスタリスク
//     .replace(/\uFF1A/g, ':')  // 全角コロン
//     .replace(/\uFF1F/g, '?')  // 全角？
//     .replace(/\uFF01/g, '!'); // 全角！

//   // 4) 全角英数字 → 半角英数字 (簡易版)
//   out = out.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (ch) => {
//     return String.fromCharCode(ch.charCodeAt(0) - 0xFEE0);
//   });

//   return out;
// }

// /************************************
//  * 2) buildPartialMatchRegex
//  *    "Product Description" → 柔軟に
//  *    **Product Description:** や :** を許容する例
//  ************************************/
// function buildPartialMatchRegex(raw) {
//   // まず正規表現メタ文字をエスケープ
//   const escaped = raw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

//   // 単語全体だけをマッチさせるため、前後に単語境界 \b を追加する
//   const pattern = `\\b${escaped}\\b`;

//   return new RegExp(pattern, 'gi');
// }


// /************************************
//  * 3) DOMParser でタグを壊さずテキスト削除・置換
//  ************************************/
// function transformFinalHTMLWithDOMParser(html, sentencesToDelete, wordsToReplace) {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(html, 'text/html');

//   function traverse(node) {
//     node.childNodes.forEach((child) => {
//       if (child.nodeType === Node.TEXT_NODE) {
//         let text = child.nodeValue || '';

//         // 3-A: 部分一致で削除
//         sentencesToDelete.forEach((raw) => {
//           if (raw.trim()) {
//             const re = buildPartialMatchRegex(raw);
//             // 大文字小文字無視 + 部分一致パターンでまとめて消す
//             text = text.replace(re, '');
//           }
//         });

//         // 3-B: 部分一致で置換
//         wordsToReplace.forEach(({ from, to }) => {
//           if (from.trim()) {
//             const re = buildPartialMatchRegex(from);
//             text = text.replace(re, to);
//           }
//         });

//         child.nodeValue = text;
//       } else if (child.nodeType === Node.ELEMENT_NODE) {
//         traverse(child);
//       }
//     });
//   }

//   traverse(doc.body);

//   return doc.body.innerHTML;
// }

// // ---------------------------------------------
// // C: から始まるカラムを収集して item.Specifications にまとめる
// // ---------------------------------------------
// function collectSpecifications(item) {
//   const specs = {};
//   Object.keys(item).forEach((key) => {
//     if (key.startsWith('C:') && item[key]) {
//       specs[key] = item[key];
//     }
//   });
//   return specs;
// }

// // ---------------------------------------------
// // AI特徴文(英語)を生成する関数
// // ---------------------------------------------
// async function generateFeatures(jpDesc, apiKey, mandatoryKeywords = [], maxTokens = 200) {

//   console.log('[DEBUG] mandatoryKeywords:', mandatoryKeywords);
  
//   const mandatoryKeywordsPrompt = mandatoryKeywords.length > 0
//   ? `IMPORTANT: You MUST include the following mandatory keywords **exactly as they are** in the final output. 
// If you cannot find a natural place in the text, please include them in the "Item Specifics" area. 
// Do not omit or paraphrase them under any circumstances:
// ${mandatoryKeywords.join(', ')}`
//   : '';

//   const response = await axios.post(
//     'https://api.openai.com/v1/chat/completions',
//     {
//       model: 'gpt-4o-mini', // 用途に応じてモデルを変更
//       messages: [
//         {
//           role: 'system',
//           content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information.
          
//           ${mandatoryKeywordsPrompt}

//           You are an AI assistant specialized in creating optimized product descriptions for eBay listings. Your task is to analyze the provided Japanese product information and generate a clear, concise, and engaging English product description. Follow these guidelines:

// 1. Focus solely on describing the product itself—include details such as brand, model, size, color, material, and any distinctive features. Do not include shipping or payment information.

// 2. If the input contains information corresponding to columns that start with "C:" (for example, "C:Material", "C:Color", "C:Character"), you must output these item specifics in the following exact format at the end of your description:

//    C:Character : naruto  
//    C:Material : Acrylic  
//    C:Color : red  

//    - Include each relevant "C:" field as "C:[Field Name] : [Value]".
//    - If there is no information for a specific "C:" field, omit that line.
//    - Ensure that these mandatory keywords are included exactly as provided.

// 3. The final output should consist of two parts:
//    - The main English product description.
//    - A separate section at the end listing the "C:" fields in the exact format described above.

// 4. Use natural, grammatically correct English. Avoid adding extra or speculative information; only include what is clearly indicated by the input.

// For example, if the product information includes details that correspond to the "C:" fields, your output should look like this:

// [English product description text here...]

// C:Character : naruto  
// C:Material : Acrylic  
// C:Color : red  

// Now, analyze the following Japanese product information and produce the best possible English product description according to these rules:

// [Insert Japanese product information here]


// **Notes:**
// - Ensure that the item specifics are accurate based on the product information.
// - If an item specific is not available or unclear, omit it.
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information.
// - If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.
// - There are places where the same information is output in duplicate, so don't duplicate it.
//   Extracting only important information
//   Be sure to extract only the parts of the Japanese text that describe the product.

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`
//         },
//         {
//           role: 'user',
//           content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。また、指定された item specifics を抽出してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`
//         },
//       ],
//       max_tokens: maxTokens,
//       temperature: 0.1,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${apiKey}`,
//         'Content-Type': 'application/json',
//       },
//     }
//   );

//   return response.data.choices[0].message.content.trim();
// }

// // 429エラー再試行などにも対応した generateFeaturesWithRetry
// async function generateFeaturesWithRetry(
//   jpDesc,
//   apiKey,
//   mandatoryKeywords,
//   queue,
//   retryCount = 0
// ) {
//   try {
//     return await generateFeatures(jpDesc, apiKey, mandatoryKeywords);
//   } catch (error) {
//     // 429レートリミットエラーが発生した場合、リトライ（最大5回）
//     if (error.response && error.response.status === 429 && retryCount < 5) {
//       const retryAfter = error.response.headers['retry-after'];
//       const delay = retryAfter
//         ? parseInt(retryAfter) * 1000
//         : 1000 * Math.pow(2, retryCount);
//       await new Promise((resolve) => setTimeout(resolve, delay));
//       return generateFeaturesWithRetry(jpDesc, apiKey, mandatoryKeywords, queue, retryCount + 1);
//     }
//     throw error;
//   }
// }

// // ---------------------------------------------
// // メインのAI商品説明適用関数
// // ---------------------------------------------
// export const applyAIProductDescriptions = async (
//   currentData,
//   apiKey,
//   progressCallback,
//   selectedDesignTemplate,
//   userSettings = {},
//   picUrl
// ) => {
//   if (!apiKey) {
//     throw new Error('OpenAI APIキーが設定されていません');
//   }

//   // p-queueの設定
//   const CONCURRENCY = 2;
//   const INTERVAL = 1000;
//   const INTERVAL_CAP = 5;
//   const queue = new PQueue({
//     concurrency: CONCURRENCY,
//     interval: INTERVAL,
//     intervalCap: INTERVAL_CAP,
//   });

//   let totalItems = 0;
//   let completedItems = 0;
//   const errorItems = [];

//   // 全ファイル分を処理
//   const updatedData = await Promise.all(
//     currentData.map(async (file) => {
//       const parsedResult = Papa.parse(file.content, { header: true });
//       const data = parsedResult.data;

//       totalItems += data.length;

//       // 行ごとにAI生成
//       const generateDescriptionTasks = data.map((item, index) => async () => {
//         try {
//           const originalTitle = item.Title || '';
//           const jpDesc = item.jp_desc || item.JP_Description || '';

//           // (A) もし日本語説明が空なら、AI呼び出しをスキップ
//           const filteredJpDesc = applyFilters(
//             jpDesc,
//             userSettings.selectedFilters || [],
//             userSettings.customFilters || []
//           );
//           if (!filteredJpDesc.trim()) {
//             // 日本語情報が全然ないので「AIを呼ばずに空のままにする」などの処理をする
//             item.Description = item.Description || ''; // あるいは何らかのデフォルト文言
//             completedItems++;
//             progressCallback?.({ totalItems, completedItems });
//             return;
//           }

//           // ❷ キャッシュ判定
//           const cacheKey = `${originalTitle}_${filteredJpDesc.slice(0, 100)}_${selectedDesignTemplate}`;
//           const cached = getCachedDescription(cacheKey);
//           if (cached) {
//             item.Description = cached;
//             completedItems++;
//             progressCallback?.({ totalItems, completedItems });
//             return;
//           }

//           // ❸ AIで英語説明を生成
//           let generatedFeatures = await generateFeaturesWithRetry(
//             filteredJpDesc,
//             apiKey,
//             userSettings.mandatoryKeywords || [],
//             queue
//           );

//           // (B) 返ってきたAI応答に「I'm sorry...」が混ざっていれば削除
//           generatedFeatures = generatedFeatures
//             .replace(/I'm sorry[^.]*\.\s*/i, '')
//             .replace(/it seems that the product information[^.]*\.\s*/i, '')
//             .trim();

//           // ❹ もとのDescriptionと結合するなどの処理
//           const sanitizedOriginalDesc = sanitizeDescription(item.Description || '');
//           const combinedDesc = sanitizedOriginalDesc;


//           // 5) C:~~~ の項目をまとめる
//           const specs = collectSpecifications(item);

//           // 6) テンプレートで最終HTML生成
//           console.log('[DEBUG] Before generateFormattedDescription, combinedDesc:', combinedDesc);
//           const updatedDescription = generateFormattedDescription(
//             item.Title || '',
//             generatedFeatures,
//             combinedDesc,
//             specs,
//             selectedDesignTemplate,
//             userSettings,
//             item.PicURL ? getImageUrls(item.PicURL) : null  // ここでPicURLの最初の画像URLを渡す
//           );
//           console.log('[DEBUG] After generateFormattedDescription, updatedDescription:', updatedDescription);

//           // 7) ★ 最終HTMLを DOMParser でパースし、「部分一致」でテキスト削除・置換
//           console.log('[DEBUG] Before final DOMParser transform:', updatedDescription);
//           const finalDescription = transformFinalHTMLWithDOMParser(
//             updatedDescription,
//             userSettings.sentencesToDelete || [],
//             userSettings.wordsToReplace || []
//           );
//           console.log('[DEBUG] After final DOMParser transform:', finalDescription);

//           // 8) CSVのDescriptionに反映
//           item.Description = finalDescription;

//           // 9) キャッシュ保存
//           setCachedDescription(cacheKey, finalDescription);

//         } catch (err) {
//           console.error(`商品説明生成エラー（行 ${index + 1}）:`, err);
//           errorItems.push({
//             fileName: file.name,
//             rowIndex: index + 1,
//             error: err.message || String(err),
//           });
//         } finally {
//           completedItems++;
//           if (progressCallback) {
//             progressCallback({ totalItems, completedItems });
//           }
//         }
//       });

//       await queue.addAll(generateDescriptionTasks);

//       // CSVに変換して返す
//       const csvContent = Papa.unparse(data);
//       return {
//         ...file,
//         content: csvContent,
//       };
//     })
//   );

//   return { updatedData, errorItems };
// };