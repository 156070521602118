// ColumnNameBasedTemplateManager.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  CardActions,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Snackbar,
  Alert,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Folder as FolderIcon,
  Description as DescriptionIcon,
  Add as AddIcon,
  Edit as EditIcon,
  PlayArrow as ApplyIcon,
  FileCopy as DuplicateIcon,
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
  FolderOpen as FolderOpenIcon,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

import conditionData from '../eBay_info/conditionData.json';
import itemSpecificsData from '../eBay_info/item_specifics.json';

function ColumnNameBasedTemplateManager({ data, setData, columns, setColumns }) {
  const [isDataModified, setIsDataModified] = useState(false);

  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newOperation, setNewOperation] = useState({
    columnName: '',
    action: '',
    value: '',
    category: '',
    itemSpecificName: '',
  });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteCategoryDialogOpen, setIsDeleteCategoryDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [isNewTemplateDialogOpen, setIsNewTemplateDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuTarget, setMenuTarget] = useState(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [renameTarget, setRenameTarget] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [user, setUser] = useState(null);
  const [hasUserModifiedCategories, setHasUserModifiedCategories] = useState(false);

  const [itemSpecificsCategories, setItemSpecificsCategories] = useState([]);
  const [itemSpecificsNames, setItemSpecificsNames] = useState([]);
  const [recommendedValues, setRecommendedValues] = useState([]);

  const [deleteTarget, setDeleteTarget] = useState(null);

  // Accordion開閉管理用
  const [expandedCategory, setExpandedCategory] = useState(null);
  const handleAccordionToggle = (categoryName) => {
    setExpandedCategory((prev) => (prev === categoryName ? null : categoryName));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      loadTemplatesFromFirestore();
    } else {
      const storedCategories = localStorage.getItem('categories');
      if (storedCategories) {
        setCategories(JSON.parse(storedCategories));
      } else {
        setCategories({});
      }
    }
  }, [user]);

  const loadTemplatesFromFirestore = async () => {
    try {
      const docRef = doc(db, 'userTemplates', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        const userCategories = userData.categories || {};
        setCategories(userCategories);
      } else {
        setCategories({});
      }
    } catch (error) {
      console.error('テンプレートの読み込みエラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの読み込みに失敗しました',
        severity: 'error',
      });
      setCategories({});
    }
  };

  const saveTemplatesToFirestore = async (updatedCategories) => {
    try {
      const docRef = doc(db, 'userTemplates', user.uid);
      await setDoc(docRef, { categories: updatedCategories });
    } catch (error) {
      console.error('テンプレートの保存エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの保存に失敗しました',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (hasUserModifiedCategories) {
      if (user) {
        saveTemplatesToFirestore(categories);
      } else {
        localStorage.setItem('categories', JSON.stringify(categories));
      }
      setHasUserModifiedCategories(false);
    }
  }, [categories, user, hasUserModifiedCategories]);

  useEffect(() => {
    setItemSpecificsCategories(
      itemSpecificsData.categories.map((category) => category.category)
    );
  }, []);

  useEffect(() => {
    const categoryData = itemSpecificsData.categories.find(
      (cat) => cat.category === newOperation.category
    );
    if (categoryData) {
      setItemSpecificsNames(
        categoryData.item_specifics.map((specific) => specific.name)
      );
    } else {
      setItemSpecificsNames([]);
    }
  }, [newOperation.category]);

  useEffect(() => {
    const categoryData = itemSpecificsData.categories.find(
      (cat) => cat.category === newOperation.category
    );
    if (categoryData) {
      const specific = categoryData.item_specifics.find(
        (spec) => spec.name === newOperation.itemSpecificName
      );
      if (specific) {
        setRecommendedValues(specific.recommended_values);
      } else {
        setRecommendedValues([]);
      }
    } else {
      setRecommendedValues([]);
    }
  }, [newOperation.category, newOperation.itemSpecificName]);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleNewCategory = () => {
    if (newCategoryName && !categories[newCategoryName]) {
      const updatedCategories = { ...categories, [newCategoryName]: [] };
      setCategories(updatedCategories);
      setNewCategoryName('');
      setSelectedCategory(newCategoryName);
      setIsNewCategoryDialogOpen(false);
      setHasUserModifiedCategories(true);
      setSnackbar({
        open: true,
        message: '新しいカテゴリーを作成しました',
        severity: 'success',
      });
    } else {
      setSnackbar({
        open: true,
        message: 'カテゴリー名が無効です',
        severity: 'error',
      });
    }
  };

  const handleNewTemplate = () => {
    if (newTemplateName && selectedCategory) {
      const newTemplate = { name: newTemplateName, operations: [] };
      const updatedCategories = { ...categories };

      if (!Array.isArray(updatedCategories[selectedCategory])) {
        updatedCategories[selectedCategory] = [];
      }

      updatedCategories[selectedCategory] = [
        ...updatedCategories[selectedCategory],
        newTemplate,
      ];

      setCategories(updatedCategories);
      setNewTemplateName('');
      setSelectedTemplate(newTemplate);
      setIsNewTemplateDialogOpen(false);
      setHasUserModifiedCategories(true);
      setSnackbar({
        open: true,
        message: '新しいテンプレートを作成しました',
        severity: 'success',
      });
    } else {
      setSnackbar({
        open: true,
        message: 'テンプレート名が無効です',
        severity: 'error',
      });
    }
  };

  const handleDeleteTemplate = (categoryName, template) => {
    setDeleteTarget({ type: 'template', categoryName, template });
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteCategory = (categoryName) => {
    setDeleteTarget({ type: 'category', categoryName });
    setIsDeleteCategoryDialogOpen(true);
  };

  const confirmDeleteTemplate = () => {
    const { categoryName, template } = deleteTarget;
    const updatedTemplates = categories[categoryName].filter(
      (t) => t.name !== template.name
    );
    const updatedCategories = {
      ...categories,
      [categoryName]: updatedTemplates,
    };
    setCategories(updatedCategories);
    setHasUserModifiedCategories(true);

    if (selectedTemplate?.name === template.name && selectedCategory === categoryName) {
      setSelectedTemplate(null);
    }

    setDeleteTarget(null);
    setIsDeleteDialogOpen(false);
    setSnackbar({
      open: true,
      message: 'テンプレートを削除しました',
      severity: 'success',
    });
  };

  const confirmDeleteCategory = () => {
    const { categoryName } = deleteTarget;
    const { [categoryName]: deletedCategory, ...remainingCategories } = categories;
    setCategories(remainingCategories);
    setHasUserModifiedCategories(true);

    if (selectedCategory === categoryName) {
      setSelectedCategory('');
      setSelectedTemplate(null);
    }

    setDeleteTarget(null);
    setIsDeleteCategoryDialogOpen(false);
    setSnackbar({
      open: true,
      message: 'カテゴリーを削除しました',
      severity: 'success',
    });
  };

  const handleAddOperation = () => {
    if (
      selectedTemplate &&
      newOperation.columnName &&
      newOperation.action &&
      (newOperation.action !== 'setConditionID' && newOperation.action !== 'setItemSpecifics'
        ? newOperation.value
        : true) &&
      (newOperation.action === 'setConditionID'
        ? newOperation.category && newOperation.value
        : true) &&
      (newOperation.action === 'setItemSpecifics'
        ? newOperation.category && newOperation.itemSpecificName && newOperation.value
        : true)
    ) {
      const updatedTemplate = {
        ...selectedTemplate,
        operations: [...selectedTemplate.operations, newOperation],
      };
      updateTemplate(updatedTemplate);
      setNewOperation({
        columnName: '',
        action: '',
        value: '',
        category: '',
        itemSpecificName: '',
      });
      setSnackbar({
        open: true,
        message: '操作を追加しました',
        severity: 'success',
      });
    } else {
      setSnackbar({
        open: true,
        message: 'すべてのフィールドを入力してください',
        severity: 'error',
      });
    }
  };

  const handleDeleteOperation = (index) => {
    if (selectedTemplate) {
      const updatedOperations = selectedTemplate.operations.filter((_, i) => i !== index);
      const updatedTemplate = { ...selectedTemplate, operations: updatedOperations };
      updateTemplate(updatedTemplate);
      setSnackbar({
        open: true,
        message: '操作を削除しました',
        severity: 'success',
      });
    }
  };

  const updateTemplate = (updatedTemplate) => {
    const updatedTemplates = categories[selectedCategory].map((t) =>
      t.name === updatedTemplate.name ? updatedTemplate : t
    );
    const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
    setCategories(updatedCategories);
    setHasUserModifiedCategories(true);
    setSelectedTemplate(updatedTemplate);
  };

  const handleApplyTemplate = () => {
    setIsConfirmDialogOpen(true);
  };

  const confirmApplyTemplate = () => {
    setIsConfirmDialogOpen(false);
    if (!selectedTemplate) return;

    try {
      let updatedData = [...data];
      let updatedColumns = [...columns];

      for (const op of selectedTemplate.operations) {
        if (!updatedColumns.includes(op.columnName)) {
          updatedColumns.push(op.columnName);
          updatedData = updatedData.map((row) => ({ ...row, [op.columnName]: '' }));
        }

        updatedData = updatedData.map((row) => {
          let cell = row[op.columnName] || '';
          switch (op.action) {
            case 'replace':
              cell = op.value;
              break;
            case 'prefix':
              cell = op.value + cell;
              break;
            case 'suffix':
              cell = cell + op.value;
              break;
            case 'setConditionID':
              cell = op.value;
              break;
            case 'setItemSpecifics':
              cell = op.value;
              break;
            default:
              break;
          }
          return { ...row, [op.columnName]: cell };
        });
      }

      setData(updatedData);
      setColumns(updatedColumns);
      setIsDataModified(true);

      setSnackbar({
        open: true,
        message: 'テンプレートが適用されました',
        severity: 'success',
      });
    } catch (error) {
      console.error('テンプレートの適用エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの適用に失敗しました',
        severity: 'error',
      });
    }
  };

  const exportTemplates = () => {
    const blob = new Blob([JSON.stringify(categories, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'templates_export.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setSnackbar({
      open: true,
      message: 'テンプレートをエクスポートしました',
      severity: 'success',
    });
  };

  const importTemplates = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function (e) {
      try {
        const importedCategories = JSON.parse(e.target.result);
        setCategories(importedCategories);
        setHasUserModifiedCategories(true);
        setSnackbar({
          open: true,
          message: 'テンプレートをインポートしました',
          severity: 'success',
        });
      } catch (error) {
        console.error('テンプレートのインポートエラー:', error);
        setSnackbar({
          open: true,
          message: 'テンプレートのインポートに失敗しました。ファイルを確認してください。',
          severity: 'error',
        });
      }
    };
    reader.readAsText(file);
  };

  const handleMenuOpen = (event, target) => {
    setAnchorEl(event.currentTarget);
    setMenuTarget(target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTarget(null);
  };

  const handleDuplicate = () => {
    handleMenuClose();
    if (menuTarget.type === 'category') {
      duplicateCategory(menuTarget.name);
    } else if (menuTarget.type === 'template') {
      duplicateTemplate(menuTarget.category, menuTarget.template);
    }
  };

  const duplicateCategory = (categoryName) => {
    const newCategoryName = `${categoryName} (Copy)`;
    const updatedCategories = {
      ...categories,
      [newCategoryName]: JSON.parse(JSON.stringify(categories[categoryName])),
    };
    setCategories(updatedCategories);
    setHasUserModifiedCategories(true);
    setSnackbar({
      open: true,
      message: 'カテゴリーを複製しました',
      severity: 'success',
    });
  };

  const duplicateTemplate = (categoryName, template) => {
    const newTemplateName = `${template.name} (Copy)`;
    const updatedCategories = { ...categories };
    updatedCategories[categoryName] = [
      ...updatedCategories[categoryName],
      {
        ...JSON.parse(JSON.stringify(template)),
        name: newTemplateName,
      },
    ];
    setCategories(updatedCategories);
    setHasUserModifiedCategories(true);
    setSnackbar({
      open: true,
      message: 'テンプレートを複製しました',
      severity: 'success',
    });
  };

  const handleRename = () => {
    handleMenuClose();
    if (menuTarget) {
      setRenameTarget(menuTarget);
      setNewName(
        menuTarget.type === 'category' ? menuTarget.name : menuTarget.template.name
      );
      setIsRenameDialogOpen(true);
    }
  };

  const confirmRename = () => {
    if (renameTarget && newName) {
      if (renameTarget.type === 'category') {
        renameCategory(renameTarget.name, newName);
      } else if (renameTarget.type === 'template') {
        renameTemplate(renameTarget.category, renameTarget.template, newName);
      }
    }
    setIsRenameDialogOpen(false);
    setRenameTarget(null);
    setNewName('');
  };

  const renameCategory = (oldName, newName) => {
    if (oldName !== newName && !categories[newName]) {
      const updatedCategories = { ...categories };
      updatedCategories[newName] = updatedCategories[oldName];
      delete updatedCategories[oldName];
      setCategories(updatedCategories);
      setHasUserModifiedCategories(true);
      if (selectedCategory === oldName) {
        setSelectedCategory(newName);
      }
      setSnackbar({
        open: true,
        message: 'カテゴリー名を変更しました',
        severity: 'success',
      });
    } else {
      setSnackbar({
        open: true,
        message: 'そのカテゴリー名は既に存在します',
        severity: 'error',
      });
    }
  };

  const renameTemplate = (categoryName, template, newName) => {
    if (template.name !== newName) {
      const updatedCategories = { ...categories };
      const templateIndex = updatedCategories[categoryName].findIndex(
        (t) => t.name === template.name
      );
      if (templateIndex !== -1) {
        updatedCategories[categoryName][templateIndex] = {
          ...template,
          name: newName,
        };
        setCategories(updatedCategories);
        setHasUserModifiedCategories(true);
        if (selectedTemplate && selectedTemplate.name === template.name) {
          setSelectedTemplate({ ...selectedTemplate, name: newName });
        }
        setSnackbar({
          open: true,
          message: 'テンプレート名を変更しました',
          severity: 'success',
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: 'そのテンプレート名は既に存在します',
        severity: 'error',
      });
    }
  };

  const handleDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (type === 'CATEGORY') {
      const categoryKeys = Object.keys(categories);
      const newCategoryKeys = Array.from(categoryKeys);
      const [movedCategoryKey] = newCategoryKeys.splice(source.index, 1);
      newCategoryKeys.splice(destination.index, 0, movedCategoryKey);

      const newCategories = {};
      newCategoryKeys.forEach((key) => {
        newCategories[key] = categories[key];
      });

      setCategories(newCategories);
      setHasUserModifiedCategories(true);
    } else if (type === 'TEMPLATE') {
      const sourceCategoryName = source.droppableId.replace('templates-', '');
      const destCategoryName = destination.droppableId.replace('templates-', '');

      const sourceTemplates = Array.from(categories[sourceCategoryName]);
      const [movedTemplate] = sourceTemplates.splice(source.index, 1);

      if (sourceCategoryName === destCategoryName) {
        sourceTemplates.splice(destination.index, 0, movedTemplate);
        const updatedCategories = {
          ...categories,
          [sourceCategoryName]: sourceTemplates,
        };
        setCategories(updatedCategories);
      } else {
        const destTemplates = Array.from(categories[destCategoryName]);
        destTemplates.splice(destination.index, 0, movedTemplate);

        const updatedCategories = {
          ...categories,
          [sourceCategoryName]: sourceTemplates,
          [destCategoryName]: destTemplates,
        };
        setCategories(updatedCategories);
      }
      setHasUserModifiedCategories(true);
    }
  };

  if (categories === null) {
    return (
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h6">テンプレートをロード中...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* AppBar */}
      <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          テンプレート管理
        </Typography>
        <Tooltip title="利用可能なテンプレートを表示します" arrow>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsDrawerOpen(true)}
            startIcon={<FolderOpenIcon />}
            sx={{
              borderRadius: "8px", // 角丸で柔らかい印象に
              fontSize: "1rem",    // 少し大きめのフォント
              px: 2,               // 横のパディング
              py: 1,               // 縦のパディング
              textTransform: "none", // 文字の大文字化を抑制
            }}
          >
            テンプレートを開く
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>

      {/* Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{ width: 350 }}
      >
        <Box sx={{ width: 350, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            カテゴリーとテンプレート
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setIsNewCategoryDialogOpen(true)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          >
            新しいカテゴリー
          </Button>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="categories" type="CATEGORY">
              {(provided) => (
                <List ref={provided.innerRef} {...provided.droppableProps}>
                  {Object.keys(categories).map((categoryName, index) => (
                    <Draggable
                      key={categoryName}
                      draggableId={`category-${categoryName}`}
                      index={index}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          {/* カテゴリーをAccordionで表示 */}
                          <Accordion
                            expanded={expandedCategory === categoryName}
                            onChange={() => handleAccordionToggle(categoryName)}
                            sx={{ mb: 1 }}
                          >
                            <AccordionSummary
                              {...provided.dragHandleProps}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${categoryName}-content`}
                              id={`panel-${categoryName}-header`}
                            >
                              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                <FolderIcon sx={{ mr: 1 }} />
                                {categoryName}
                              </Typography>
                              <IconButton
                                edge="end"
                                aria-label="more"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleMenuOpen(event, {
                                    type: 'category',
                                    name: categoryName,
                                  });
                                }}
                                sx={{ ml: 'auto' }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Droppable
                                droppableId={`templates-${categoryName}`}
                                type="TEMPLATE"
                              >
                                {(provided) => (
                                  <List ref={provided.innerRef} {...provided.droppableProps}>
                                    {categories[categoryName].map((template, index) => (
                                      <Draggable
                                        key={template.name}
                                        draggableId={`template-${categoryName}-${template.name}`}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{ pl: 4 }}
                                            secondaryAction={
                                              <IconButton
                                                edge="end"
                                                aria-label="more"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleMenuOpen(event, {
                                                    type: 'template',
                                                    category: categoryName,
                                                    template: template,
                                                  });
                                                }}
                                              >
                                                <MoreVertIcon />
                                              </IconButton>
                                            }
                                            onClick={() => {
                                              setSelectedCategory(categoryName);
                                              handleTemplateSelect(template);
                                              setIsDrawerOpen(false);
                                            }}
                                          >
                                            <ListItemIcon>
                                              <DescriptionIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={template.name} />
                                          </ListItem>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    <ListItem sx={{ pl: 4 }}>
                                      <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                          setSelectedCategory(categoryName);
                                          setIsNewTemplateDialogOpen(true);
                                        }}
                                      >
                                        新しいテンプレート
                                      </Button>
                                    </ListItem>
                                  </List>
                                )}
                              </Droppable>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {/* インポートボタン */}
            <input
              accept=".json"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={importTemplates}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                インポート
              </Button>
            </label>
          </Box>
        </Box>
      </Drawer>

      {/* Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleRename}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>名前変更</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>複製</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            if (menuTarget.type === 'category') {
              handleDeleteCategory(menuTarget.name);
            } else if (menuTarget.type === 'template') {
              handleDeleteTemplate(menuTarget.category, menuTarget.template);
            }
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>削除</ListItemText>
        </MenuItem>
      </Menu>

      {/* Main Content */}
      <Box sx={{ p: 3 }}>
        {selectedTemplate ? (
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedTemplate.name} の詳細
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                overflowX: 'auto',
                pb: 1,
                mb: 2,
                '&::-webkit-scrollbar': { height: 6 },
                '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' },
              }}
            >
              {selectedTemplate.operations.map((operation, index) => (
                <Card
                  key={index}
                  sx={{
                    width: 160,
                    minWidth: 160,
                    mr: 1,
                    mb: 1,
                    flexShrink: 0,
                  }}
                >
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="subtitle2" component="div" noWrap>
                      {operation.columnName}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      display="block"
                      noWrap
                    >
                      {operation.action === 'setConditionID'
                        ? 'Condition ID設定'
                        : operation.action === 'setItemSpecifics'
                        ? 'アイテムスペシフィックス設定'
                        : operation.action}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ fontSize: '0.75rem' }}>
                      {operation.value}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
                    <IconButton size="small" onClick={() => handleDeleteOperation(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                新しい操作を追加
              </Typography>
              <Grid container spacing={2}>
                {/* カラム名 */}
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    freeSolo
                    options={columns}
                    value={newOperation.columnName}
                    onInputChange={(event, newInputValue) => {
                      setNewOperation({ ...newOperation, columnName: newInputValue });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="カラム名" fullWidth />
                    )}
                  />
                </Grid>
                {/* アクション */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>アクション</InputLabel>
                    <Select
                      value={newOperation.action}
                      onChange={(e) =>
                        setNewOperation({
                          ...newOperation,
                          action: e.target.value,
                          category:
                            e.target.value === 'setConditionID' || e.target.value === 'setItemSpecifics'
                              ? newOperation.category
                              : '',
                          value:
                            e.target.value === 'setConditionID' || e.target.value === 'setItemSpecifics'
                              ? newOperation.value
                              : '',
                          itemSpecificName:
                            e.target.value === 'setItemSpecifics' ? newOperation.itemSpecificName : '',
                        })
                      }
                    >
                      <MenuItem value="replace">置換</MenuItem>
                      <MenuItem value="prefix">接頭辞追加</MenuItem>
                      <MenuItem value="suffix">接尾辞追加</MenuItem>
                      <MenuItem value="setConditionID">Condition ID設定</MenuItem>
                      <MenuItem value="setItemSpecifics">アイテムスペシフィックス設定</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* 値 */}
                {newOperation.action === 'setConditionID' ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>カテゴリー</InputLabel>
                        <Select
                          value={newOperation.category}
                          onChange={(e) => {
                            setNewOperation({
                              ...newOperation,
                              category: e.target.value,
                              value: '',
                            });
                          }}
                        >
                          {Object.keys(conditionData).map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth disabled={!newOperation.category}>
                        <InputLabel>Condition ID</InputLabel>
                        <Select
                          value={newOperation.value}
                          onChange={(e) =>
                            setNewOperation({ ...newOperation, value: e.target.value })
                          }
                        >
                          {newOperation.category &&
                            conditionData[newOperation.category].map((condition) => (
                              <MenuItem key={condition.id} value={condition.id}>
                                {condition.id} - {condition.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : newOperation.action === 'setItemSpecifics' ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>カテゴリー</InputLabel>
                        <Select
                          value={newOperation.category}
                          onChange={(e) => {
                            setNewOperation({
                              ...newOperation,
                              category: e.target.value,
                              itemSpecificName: '',
                              value: '',
                            });
                          }}
                        >
                          {itemSpecificsCategories.map((category) => (
                            <MenuItem key={category} value={category}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        freeSolo
                        options={itemSpecificsNames}
                        value={newOperation.itemSpecificName}
                        onInputChange={(event, newInputValue) => {
                          setNewOperation({ ...newOperation, itemSpecificName: newInputValue });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="アイテムスペシフィックス名" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {recommendedValues.length > 0 ? (
                        <Autocomplete
                          freeSolo
                          options={recommendedValues}
                          value={newOperation.value}
                          onInputChange={(event, newInputValue) => {
                            setNewOperation({ ...newOperation, value: newInputValue });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="値" fullWidth />
                          )}
                        />
                      ) : (
                        <TextField
                          label="値"
                          value={newOperation.value}
                          onChange={(e) =>
                            setNewOperation({ ...newOperation, value: e.target.value })
                          }
                          fullWidth
                        />
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="値"
                      value={newOperation.value}
                      onChange={(e) =>
                        setNewOperation({ ...newOperation, value: e.target.value })
                      }
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
              <Button
                variant="contained"
                onClick={handleAddOperation}
                sx={{ mt: 2 }}
                disabled={
                  !newOperation.columnName ||
                  !newOperation.action ||
                  (newOperation.action === 'setConditionID'
                    ? !newOperation.category || !newOperation.value
                    : newOperation.action === 'setItemSpecifics'
                    ? !newOperation.category || !newOperation.itemSpecificName || !newOperation.value
                    : !newOperation.value)
                }
              >
                操作を追加
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ApplyIcon />}
              onClick={handleApplyTemplate}
              sx={{ mt: 4 }}
            >
              テンプレートを適用
            </Button>
          </Paper>
        ) : (
          <Typography variant="body1">
            右側の「テンプレートを開く」からテンプレートを選択してください。
          </Typography>
        )}
      </Box>

      {/* Dialogs */}
      <Dialog
        open={isNewCategoryDialogOpen}
        onClose={() => setIsNewCategoryDialogOpen(false)}
      >
        <DialogTitle>新しいカテゴリー</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="カテゴリー名"
            fullWidth
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewCategoryDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleNewCategory}>作成</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isNewTemplateDialogOpen}
        onClose={() => setIsNewTemplateDialogOpen(false)}
      >
        <DialogTitle>新しいテンプレート</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="テンプレート名"
            fullWidth
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewTemplateDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleNewTemplate}>作成</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            このテンプレートを適用しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmApplyTemplate} autoFocus>
            適用
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>テンプレート削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            テンプレート "{deleteTarget?.template?.name}" を削除しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmDeleteTemplate} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteCategoryDialogOpen}
        onClose={() => setIsDeleteCategoryDialogOpen(false)}
      >
        <DialogTitle>カテゴリー削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            カテゴリー "{selectedCategory}" とそれに含まれるすべてのテンプレートを削除しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteCategoryDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmDeleteCategory} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isRenameDialogOpen}
        onClose={() => setIsRenameDialogOpen(false)}
      >
        <DialogTitle>
          {renameTarget?.type === 'category' ? 'カテゴリー名の変更' : 'テンプレート名の変更'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="新しい名前"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenameDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmRename}>変更</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ColumnNameBasedTemplateManager;