import axios from 'axios';
import PQueue from 'p-queue';

const titleCache = {}; // メモリキャッシュ (必要ならlocalStorageなどに変更可)

const getCachedTitle = (key) => titleCache[key];
const setCachedTitle = (key, value) => {
  titleCache[key] = value;
};

const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 1000;


// タイトル生成のための関数
export const applyAITitles = async (
  currentData,
  apiKey,
  progressCallback,
  customizationOptions,
  apiKeys
) => {
  if (!apiKey) {
    throw new Error('OpenAI APIキーが設定されていません');
  }

  // カスタマイズオプションを展開
  const {
    deleteStrings = [],
    replacePairs = [],
    prependText = '',
    appendText = '',
    limitTitleLength = false, // trueなら80文字制限を行う
    additionalPrompt = '',    // **追加点: 必要があればUIからの追加プロンプトを受け取る
  } = customizationOptions || {};

  // "UIオプションを文字列化" → キャッシュキーに含める
  const optionsKey = JSON.stringify({
    deleteStrings,
    replacePairs,
    prependText,
    appendText,
    limitTitleLength,
    additionalPrompt,
  });

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  let totalItems = 0;
  let completedItems = 0;
  const errorItems = [];

  // 全ファイルに対して処理
  const updatedData = await Promise.all(
    currentData.map(async (file) => {
      // papaparse を require
      const Papa = require('papaparse');
      const parsedResult = Papa.parse(file.content, { header: true });
      const data = parsedResult.data;

      totalItems += data.length;

      // タイトル生成タスクの配列
      const generateTitleTasks = data.map((item, index) => async () => {
        const originalTitle = item.Title || '';
        const jpDesc = item.jp_desc || item.JP_Description || '';
        const imgAnalysis = item.jp_image_analysis || '';


        // キャッシュキー（Title + 説明 + オプション）
        const cacheKey = originalTitle + jpDesc.slice(0, 150) + optionsKey;

        // 1) キャッシュにあるかチェック
        const cachedTitle = getCachedTitle(cacheKey);

        if (cachedTitle) {
          // キャッシュ済みタイトルに再度カスタマイズのみ適用
          const customizedTitle = applyTitleCustomizations(
            cachedTitle,
            deleteStrings,
            replacePairs,
            prependText,
            appendText,
            limitTitleLength
          );
          item.Title = customizedTitle;
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
          return;
        }

        // 2) キャッシュがなければAI呼び出し
        try {
          const generatedTitle = await generateSingleTitle(
            originalTitle,
            jpDesc,
            imgAnalysis,
            apiKey,
            additionalPrompt // **追加点
          );

          // カスタマイズを適用
          const customizedTitle = applyTitleCustomizations(
            generatedTitle,
            deleteStrings,
            replacePairs,
            prependText,
            appendText,
            limitTitleLength
          );

          item.Title = customizedTitle;
          // キャッシュに格納 ("AIの生生成結果"を保存する例)
          setCachedTitle(cacheKey, generatedTitle);

        } catch (error) {
          console.error(`タイトル生成エラー（行 ${index + 1}）:`, error);
          // エラー時は元のタイトルをそのまま使用
          errorItems.push({
            fileName: file.name,
            rowIndex: index + 1,
            error: error.message || error,
          });
        } finally {
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
        }
      });

      // タスクをキューに追加して実行
      await queue.addAll(generateTitleTasks);

      // CSVに変換
      const csvContent = Papa.unparse(data);

      return {
        ...file,
        content: csvContent,
      };
    })
  );

  return { updatedData, errorItems };
};

/**
 * 単一の商品タイトルを生成する関数 (★プロンプトを新しい内容に変更)
 */
async function generateSingleTitle(originalTitle, jpDesc, imgAnalysis, apiKey, additionalPrompt = '') {
  const url = 'https://api.openai.com/v1/chat/completions';
  const limitedJpDesc = jpDesc.slice(0, 150);
  const limitedImgAnalysis = imgAnalysis ? imgAnalysis.slice(0, 150) : '';

  // 追加のプロンプトを含める
  const additionalPromptContent = additionalPrompt ? `\n${additionalPrompt}` : '';

  // system メッセージ
  const systemContent = `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.${additionalPromptContent}
Order of title composition:
1. brand name (if applicable)
2. model name or product name/character name (if applicable)
3. product type (if applicable)
4. key features (color, material, size) (if applicable)
5. distinctive elements or uniqueness (if applicable)
6. condition (new/used) (if applicable)
7. important keywords (if applicable)

NOTES:
- Add or optimize new information while retaining important information from the original title
- Avoid unnecessary adjectives and words such as “eBay”
- Use abbreviations and common names appropriately
- Think from the searcher's perspective and include search terms that buyers are likely to use
- If information is unclear or ambiguous, omit it.
- Use only reliable information and do not include guesswork or uncertain information.
- Do not extract from Japanese descriptions that are a list of words.
- Titles should be generated in English and should not exceed 80 characters.
`;

  // user メッセージ
  const userContent = `元のタイトル：「${originalTitle}」
日本語の商品説明：「${limitedJpDesc}」 画像解析結果：「${limitedImgAnalysis}」
この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`;

  const requestData = {
    model: 'gpt-4o-mini', // 必要に応じてモデルを変更
    messages: [
      {
        role: 'system',
        content: systemContent,
      },
      {
        role: 'user',
        content: userContent,
      },
    ],
    max_tokens: 100,
    // temperatureや他パラメータが必要であれば適宜追加
  };

  const response = await axios.post(url, requestData, {
    headers: { Authorization: `Bearer ${apiKey}` },
  });

  const generatedTitle = response.data.choices[0].message.content.trim();
  return generatedTitle;
}

/**
 * タイトルカスタマイズ関数
 */
function applyTitleCustomizations(
  title,
  deleteStrings,
  replacePairs,
  prependText,
  appendText,
  limitTitleLength
) {
  let newTitle = title;

  // 1) 削除文字列
  deleteStrings.forEach((deleteStr) => {
    if (deleteStr) {
      const reg = new RegExp(escapeRegExp(deleteStr), 'g');
      newTitle = newTitle.replace(reg, '');
    }
  });

  // 2) 置換ペア
  replacePairs.forEach(({ from, to }) => {
    if (from) {
      const reg = new RegExp(escapeRegExp(from), 'g');
      newTitle = newTitle.replace(reg, to);
    }
  });

  // 3) 先頭と末尾の追加
  if (prependText) newTitle = `${prependText} ${newTitle}`;
  if (appendText) newTitle = `${newTitle} ${appendText}`;

  // 不要な空白をトリム
  newTitle = newTitle.trim();

  // 4) 80文字制限（UIと一致させる）
  if (limitTitleLength && newTitle.length > 80) {
    newTitle = newTitle.slice(0, 80).trim();
  }

  return newTitle;
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}