
//ーーーーーーーーーー一番安定版ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー


import axios from 'axios';

/**
 * 画像を解析して説明文を生成する関数
 * @param {string} imageUrl - 画像のURL
 * @param {string} apiKey - OpenAI APIキー
 * @returns {Promise<string>} - 生成された説明文
 */
const ImageAnalysis = async (imageUrl, apiKey) => {
  try {
    console.log('imageUrl=', imageUrl);
    // OpenAI APIにリクエスト
    const apiResponse = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini', // 正しいモデル名
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'この画像の内容を日本語で詳しく説明してください。原則箇条書きで商品の特徴をわかりやすく出力してください。'
              },
              {
                type: 'image_url',
                image_url: {
                  url: imageUrl,
                }
              }
            ]
          }
        ],
        max_tokens: 500
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
        },
      }
    );

    const description = apiResponse.data.choices[0].message.content.trim();
    return description;
  } catch (error) {
    if (error.response) {
      console.error('ステータスコード:', error.response.status);
      console.error('レスポンスデータ:', error.response.data);
    } else if (error.request) {
      console.error('リクエストが送信されましたが、レスポンスがありませんでした:', error.request);
    } else {
      console.error('エラーを生成した設定で問題が発生しました:', error.message);
    }
    console.error('エラーコンフィグ:', error.config);
    throw error;
  }  
};

export default ImageAnalysis;