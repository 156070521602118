// calculatePrices.js


// 価格を計算する関数
export const calculatePrices = (data, settings) => {
  if (!settings) {
    throw new Error('価格設定が提供されていません。');
  }

  const {
    fees,
    targetProfitMargin,
    discountRate, 
    shippingRateTemplates,
    categories,
    bestOfferAutoAcceptPercentage,
    minimumBestOfferPercentage,
    exchangeRate,
    selectedShippingTemplateId
  } = settings;

  console.log('★★ calculatePrices:', {
    selectedShippingTemplateId,
    shippingRateTemplates,
  });

  const foundTemplate = shippingRateTemplates.find(t => t.id === selectedShippingTemplateId);
  console.log('★★ foundTemplate = ', foundTemplate);

  

  // 選択された送料テンプレートを取得
  const selectedShippingTemplate =
    shippingRateTemplates.find(
      (template) => template.id === selectedShippingTemplateId
    ) ;

  if (!selectedShippingTemplate) {
    throw new Error('選択された送料テンプレートが見つかりません。');
  }

  const newPrices = [];

  data.forEach((item, index) => {
    try {
      const startPrice = parseFloat(item.StartPrice);
      if (isNaN(startPrice)) {
        throw new Error(`StartPriceが数値ではありません（行 ${index + 1}）`);
      }

      let totalCost = startPrice;
      let totalPercentage = 0;

      // カテゴリーの項目を適用
      if (categories && typeof categories === 'object') {
        Object.values(categories)
          .flat()
          .forEach((categoryItem) => {
            if (categoryItem.type === 'fixed') {
              totalCost += parseFloat(categoryItem.value) || 0;
            } else if (categoryItem.type === 'percentage') {
              totalPercentage += parseFloat(categoryItem.value) || 0;
            }
          });
      }

      // 手数料を追加
      totalPercentage +=
        parseFloat(fees.ebayFinalValue || 0) +
        parseFloat(fees.payoneer || 0) +
        parseFloat(fees.promotedListing || 0);
      totalCost += parseFloat(fees.ebayTransactionFee || 0);

      // 送料を追加
      const shippingRate = selectedShippingTemplate?.shippingRates?.find(
        (rate) =>
          startPrice >= parseFloat(rate.minPriceUSD) &&
          (parseFloat(rate.maxPriceUSD) === Infinity ||
            startPrice < parseFloat(rate.maxPriceUSD))
      );
      if (shippingRate) {
        totalCost += parseFloat(shippingRate.costUSD) || 0;
      }

      // 目標利益率を追加
      totalPercentage += parseFloat(targetProfitMargin) || 0;

      // 販売価格を計算
      let calculatedPrice = totalCost / (1 - totalPercentage / 100);
      calculatedPrice = adjustPriceTo99(calculatedPrice);

      // 割引後価格を計算
      const discount = parseFloat(discountRate) || 0;
      const discountedPrice = calculatedPrice * (1 - discount / 100);
      const finalPrice = adjustPriceTo99(discountedPrice);

      // ベストオファー価格を計算
      const bestOfferAutoAcceptPrice =
        finalPrice * (bestOfferAutoAcceptPercentage / 100);
      const minimumBestOfferPrice =
        finalPrice * (minimumBestOfferPercentage / 100);

      // 利益と利益率を計算
      const profit = finalPrice - totalCost;
      const profitMargin = (profit / finalPrice) * 100;

      // 計算結果を保存
      newPrices.push({
        originalStartPrice: startPrice.toFixed(2),
        calculatedPrice: calculatedPrice.toFixed(2),
        discountedPrice: finalPrice.toFixed(2),
        bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
        minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
        profit: profit.toFixed(2),
        profitMargin: profitMargin.toFixed(2),
      });
    } catch (error) {
      console.error(
        `アイテムの処理中にエラーが発生しました（行 ${index + 1}）:`,
        error
      );
      newPrices.push(null); // エラーが発生したアイテムは null を設定
    }
  });

  return newPrices;
};

// 価格を99で終わるように調整する関数
const adjustPriceTo99 = (price) => {
  return Math.floor(price) + 0.99;
};




// // calculatePrices.js

// // 価格を計算する関数
// export const calculatePrices = (data, settings, selectedShippingTemplateId) => {
//     if (!settings) {
//       throw new Error('価格設定が提供されていません。');
//     }
  
//     const {
//       fees,
//       targetProfitMargin,
//       discountRate,
//       shippingRateTemplates,
//       categories,
//       bestOfferAutoAcceptPercentage,
//       minimumBestOfferPercentage,
//       exchangeRate,
//     } = settings;
  
//     // 選択された送料テンプレートを取得
//     const selectedShippingTemplate =
//       shippingRateTemplates.find(
//         (template) => template.id === selectedShippingTemplateId
//       ) || shippingRateTemplates[0];
  
//     if (!selectedShippingTemplate) {
//       throw new Error('選択された送料テンプレートが見つかりません。');
//     }
  
//     const newPrices = [];
  
//     data.forEach((item, index) => {
//       try {
//         const startPrice = parseFloat(item.StartPrice);
//         if (isNaN(startPrice)) {
//           throw new Error(`StartPriceが数値ではありません（行 ${index + 1}）`);
//         }
  
//         let totalCost = startPrice;
//         let totalPercentage = 0;
  
//         // カテゴリーの項目を適用
//         if (categories && typeof categories === 'object') {
//           Object.values(categories)
//             .flat()
//             .forEach((categoryItem) => {
//               if (categoryItem.type === 'fixed') {
//                 totalCost += parseFloat(categoryItem.value) || 0;
//               } else if (categoryItem.type === 'percentage') {
//                 totalPercentage += parseFloat(categoryItem.value) || 0;
//               }
//             });
//         }
  
//         // 手数料を追加
//         totalPercentage +=
//           parseFloat(fees.ebayFinalValue || 0) +
//           parseFloat(fees.payoneer || 0) +
//           parseFloat(fees.promotedListing || 0);
//         totalCost += parseFloat(fees.ebayTransactionFee || 0);
  
//         // 送料を追加
//         const shippingRate = selectedShippingTemplate?.shippingRates?.find(
//           (rate) =>
//             startPrice >= parseFloat(rate.minPriceUSD) &&
//             (parseFloat(rate.maxPriceUSD) === Infinity ||
//               startPrice < parseFloat(rate.maxPriceUSD))
//         );
//         if (shippingRate) {
//           totalCost += parseFloat(shippingRate.costUSD) || 0;
//         }
  
//         // 目標利益率を追加
//         totalPercentage += parseFloat(targetProfitMargin) || 0;
  
//         // 販売価格を計算
//         let calculatedPrice = totalCost / (1 - totalPercentage / 100);
//         calculatedPrice = adjustPriceTo99(calculatedPrice);
  
//         // 割引後価格を計算
//         const discount = parseFloat(discountRate) || 0;
//         const discountedPrice = calculatedPrice * (1 - discount / 100);
//         const finalPrice = adjustPriceTo99(discountedPrice);
  
//         // ベストオファー価格を計算
//         const bestOfferAutoAcceptPrice =
//           finalPrice * (bestOfferAutoAcceptPercentage / 100);
//         const minimumBestOfferPrice =
//           finalPrice * (minimumBestOfferPercentage / 100);
  
//         // 利益と利益率を計算
//         const profit = finalPrice - totalCost;
//         const profitMargin = (profit / finalPrice) * 100;
  
//         // 計算結果を保存
//         newPrices.push({
//           originalStartPrice: startPrice.toFixed(2),
//           calculatedPrice: calculatedPrice.toFixed(2),
//           discountedPrice: finalPrice.toFixed(2),
//           bestOfferAutoAcceptPrice: bestOfferAutoAcceptPrice.toFixed(2),
//           minimumBestOfferPrice: minimumBestOfferPrice.toFixed(2),
//           profit: profit.toFixed(2),
//           profitMargin: profitMargin.toFixed(2),
//         });
//       } catch (error) {
//         console.error(
//           `アイテムの処理中にエラーが発生しました（行 ${index + 1}）:`,
//           error
//         );
//         newPrices.push(null); // エラーが発生したアイテムは null を設定
//       }
//     });
  
//     return newPrices;
//   };
  
//   // 価格を99で終わるように調整する関数
//   const adjustPriceTo99 = (price) => {
//     return Math.floor(price) + 0.99;
//   };