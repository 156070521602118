// src/AccountSettings.js

import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Switch,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// テンプレート名 + 画像URL をこちらで定義する例
const bannerTemplates = [
  {
    key: "antique",
    label: "アンティーク",
    imageUrl: "https://i.postimg.cc/VkX1s85p/2.png",
  },
  {
    key: "modern",
    label: "コレクティブ",
    imageUrl: "https://i.postimg.cc/xTwnSCjL/1.png",
  },
  {
    key: "simple",
    label: "時計",
    imageUrl: "https://i.postimg.cc/J7dRPx96/3.png",
  },
  {
    key: "business",
    label: "ファッション",
    imageUrl: "https://i.postimg.cc/c4g0jYWs/4.png",
  },
];

function AccountSettings({ user }) {
  const [loading, setLoading] = useState(true);

  // Firestoreから読み込むユーザー設定
  const [bannerEnabled, setBannerEnabled] = useState(false);
  const [bannerUrl, setBannerUrl] = useState("");
  const [picInsertEnabled, setPicInsertEnabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    const fetchUserSettings = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setBannerEnabled(data.bannerEnabled ?? false);
          setBannerUrl(data.bannerUrl ?? "");
          setPicInsertEnabled(data.picInsertEnabled ?? false);
          setSelectedTemplate(data.selectedTemplate ?? ""); // Firestoreにあれば読み込み
        }
      } catch (error) {
        console.error("ユーザー設定の読み込み中にエラー:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserSettings();
  }, [user]);

  const handleSave = async () => {
    if (!user) return;
    setLoading(true);

    try {
      const docRef = doc(db, "users", user.uid);
      await setDoc(
        docRef,
        {
          bannerEnabled,
          bannerUrl,
          picInsertEnabled,
          selectedTemplate,
        },
        { merge: true }
      );

      setSnackbar({
        open: true,
        message: "設定を保存しました",
        severity: "success",
      });
    } catch (error) {
      console.error("設定の保存中にエラー:", error);
      setSnackbar({
        open: true,
        message: `設定の保存に失敗しました: ${error.message}`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // テンプレートを選択したときの処理
  const handleSelectTemplate = (templateKey, templateImageUrl) => {
    setSelectedTemplate(templateKey);
    // バナー画像URLをテンプレートのURLに合わせる等
    setBannerUrl(templateImageUrl);
  };

  if (!user) {
    return <Typography>ログインしてください。</Typography>;
  }

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 1200, mx: "auto" }}>
      {/* 戻るボタン + タイトル */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={() => navigate("/")} sx={{ mr: 1 }}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          アカウント設定
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* 左側カラム */}
        <Grid item xs={12} md={8}>
          {/* 基本情報 */}
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              基本情報
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                メールアドレス
              </Typography>
              <TextField
                size="small"
                fullWidth
                disabled
                value={user.email || "(未取得)"}
                sx={{ mt: 1 }}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" color="text.secondary">
                ユーザーID (UID)
              </Typography>
              <TextField
                size="small"
                fullWidth
                disabled
                value={user.uid}
                sx={{ mt: 1 }}
              />
            </Box>
          </Paper>

          {/* バナー設定 */}
          <Paper variant="outlined" sx={{ mb: 3 }}>
            <Box sx={{ p: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                バナー設定
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  バナー画像URL
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="https://example.com/banner.jpg"
                  value={bannerUrl}
                  onChange={(e) => setBannerUrl(e.target.value)}
                  sx={{ mt: 1 }}
                />
              </Box>

              {/* バナー画像プレビュー */}
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                {bannerUrl ? (
                  <img
                    src={bannerUrl}
                    alt="Banner Preview"
                    style={{ width: "100%", display: "block" }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: 160,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "#f9f9f9",
                      color: "#999",
                    }}
                  >
                    バナー画像のプレビュー
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>

          {/* 表示設定 */}
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              表示設定
            </Typography>
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={bannerEnabled}
                    onChange={(e) => setBannerEnabled(e.target.checked)}
                  />
                }
                label="バナー画像を先頭に表示"
              />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                ページの最上部にバナー画像を表示します
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={picInsertEnabled}
                    onChange={(e) => setPicInsertEnabled(e.target.checked)}
                  />
                }
                label="複数商品画像をDescriptionに挿入"
              />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                商品説明文に複数の画像を表示します
              </Typography>
            </Box>
          </Paper>

          {/* 保存ボタン */}
          <Box sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleSave}>
              設定を保存
            </Button>
          </Box>
        </Grid>

        {/* 右側カラム: バナーテンプレート一覧 */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
            バナーテンプレート
          </Typography>

          <Grid container spacing={2}>
            {bannerTemplates.map((tpl) => (
              <Grid item xs={12} key={tpl.key}>
                <Card
                  variant="outlined"
                  sx={{
                    cursor: "pointer",
                    borderColor:
                      selectedTemplate === tpl.key ? "primary.main" : "#ccc",
                  }}
                  onClick={() => handleSelectTemplate(tpl.key, tpl.imageUrl)}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="120"
                      image={tpl.imageUrl}
                      alt={tpl.label}
                    />
                    <CardContent>
                      <Typography
                        variant="body1"
                        color={
                          selectedTemplate === tpl.key
                            ? "primary"
                            : "text.primary"
                        }
                      >
                        {tpl.label}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Snackbar通知 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AccountSettings;