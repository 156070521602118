// ItemSpecificsProcessor.js

import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Papa from 'papaparse';

// Item Specificsを適用する関数
export const applyItemSpecificsProcessor = async (
  currentData,
  userId,
  options,
  setProgress
) => {
  console.log('Received options:', options);

  const { selectedCategory, selectedColumns, matchingOptions } = options || {};

  console.log(
    'applyItemSpecificsProcessor内のselectedCategory:',
    selectedCategory
  );

  if (!userId) {
    throw new Error('ユーザー情報が取得できません。');
  }

  if (!selectedCategory) {
    throw new Error('適用するカテゴリーを選択してください。');
  }

  if (!selectedColumns || selectedColumns.length === 0) {
    throw new Error('適用するカラムを選択してください。');
  }

  try {
    // FirebaseからユーザーのItem Specifics設定を取得
    const db = getFirestore();
    const docRef = doc(db, 'itemSpecificsSettings', userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error('Item Specificsの設定が見つかりませんでした。');
    }

    const itemSpecificsSettings = docSnap.data();
    const categories = itemSpecificsSettings.categories || {};

    // カテゴリー名の正規化
    const normalizedSelectedCategory = selectedCategory.trim().toLowerCase();

    // カテゴリーキーの正規化
    const normalizedCategories = Object.keys(categories).reduce((acc, key) => {
      acc[key.trim().toLowerCase()] = categories[key];
      return acc;
    }, {});

    if (!normalizedCategories[normalizedSelectedCategory]) {
      throw new Error(
        `選択されたカテゴリー「${selectedCategory}」の設定が見つかりませんでした。`
      );
    }

    const categorySettings = normalizedCategories[normalizedSelectedCategory];

    // 処理の進捗を初期化
    let totalItems = 0;
    let completedItems = 0;

    // currentDataはファイルの配列なので、各ファイルを処理
    const updatedData = await Promise.all(
      currentData.map(async (file) => {
        // CSVデータをパース
        const parsedResult = Papa.parse(file.content, { header: true });
        const data = parsedResult.data;
        const headers = parsedResult.meta.fields;

        totalItems += data.length;

        // データにItem Specificsを適用
        const newData = applyItemSpecificsToData(
          data,
          headers,
          categorySettings,
          selectedColumns,
          matchingOptions,
          () => {
            completedItems += 1;
            if (setProgress) {
              setProgress({ totalItems, completedItems });
            }
          }
        );

        // CSVに再変換
        const csvContent = Papa.unparse(newData);

        return {
          ...file,
          content: csvContent,
        };
      })
    );

    return updatedData;
  } catch (error) {
    console.error('Item Specifics適用中にエラーが発生しました:', error);
    throw error;
  }
};

// データにItem Specificsを適用するロジック
const applyItemSpecificsToData = (
  data,
  headers,
  categorySettings,
  selectedColumns,
  matchingOptions,
  updateProgress
) => {
  const {
    caseSensitive = false,
    partialMatch = true,
    matchSource = 'title',
    matchingOption = 'first',
    overwriteExisting = true,
    // 追加: 新規カラムを作成するかどうか
    allowCreateNewColumns = true,
    // 追加: 最大カラム数を設定
    maxColumns = 44,
  } = matchingOptions || {};

  const titleKey = headers.find((header) => header.toLowerCase() === 'title');
  const descriptionKey = headers.find(
    (header) => header.toLowerCase() === 'description'
  );

  // 追加: 新しく作るカラム名を管理する配列
  //       最後に headers と合体させる
  const newColumns = [];

  const newData = data.map((item) => {
    const title = item[titleKey] || '';
    let description = item[descriptionKey] || '';
    let sourceText = '';

    // 商品説明からAI生成部分を抽出
    const aiContent = extractAIGeneratedContent(description);

    // マッチングソースに応じてテキストを設定
    if (matchSource === 'title') {
      sourceText = title;
    } else if (matchSource === 'description') {
      sourceText = aiContent;
    } else if (matchSource === 'both') {
      sourceText = `${title} ${aiContent}`;
    }

    const newItem = { ...item };

    // 商品説明から KEY: Value ペアを抽出
    const keyValuePairs = extractKeyValuePairs(aiContent);

    // KEY とカラム名を照合して一致すれば値を設定、なければ新規カラムを作る
    Object.keys(keyValuePairs).forEach((key) => {
      const normalizedKey = normalizeString(key);
      let matchingHeader = headers.find(
        (header) =>
          header.startsWith('C:') &&
          normalizeString(header.slice(2)) === normalizedKey
      );

      // もし既存カラムが無ければ、新規作成を試みる
      if (!matchingHeader && allowCreateNewColumns) {
        // すでに newColumns に同じものを作っていないか確認
        matchingHeader = newColumns.find(
          (col) =>
            col.startsWith('C:') &&
            normalizeString(col.slice(2)) === normalizedKey
        );

        // それでも無いなら本当に作成する
        if (!matchingHeader) {
          // これ以上列を増やせるか確認
          const currentTotalColumns = headers.length + newColumns.length;
          if (currentTotalColumns < maxColumns) {
            matchingHeader = `C:${key}`;
            newColumns.push(matchingHeader);
          } else {
            console.warn(
              `最大列数(${maxColumns})を超えるため、新規列「C:${key}」を作成できません。`
            );
          }
        }
      }

      // 新規作成または既存が見つかったなら値を入れる
      if (matchingHeader) {
        newItem[matchingHeader] = keyValuePairs[key];
      }
    });

    // 選択されたカラムに対してItem Specificsを適用 (既存のロジックはそのまま)
    selectedColumns.forEach((column) => {
      const value = newItem[column];

      if (
        !overwriteExisting &&
        value &&
        value.trim() !== '' &&
        value !== 'NA' &&
        value !== 'N/A' &&
        value !== 'No Brand'
      ) {
        return;
      }

      const columnData = categorySettings.columns[column];
      if (!columnData) {
        console.warn(`カラム「${column}」の設定が見つかりません。`);
        return;
      }

      const conversionRules = columnData.conversionRules || [];
      const values = columnData.values || [];

      let matchedValues = new Set();
      const flags = caseSensitive ? 'g' : 'gi';

      // 変換ルールの適用
      for (const rule of conversionRules) {
        const keyword = rule.keyword;
        const ruleValue = rule.value;

        if (!keyword || !ruleValue) continue;

        const escapedKeyword = escapeRegExp(keyword);
        const regex = new RegExp(
          partialMatch ? escapedKeyword : `\\b${escapedKeyword}\\b`,
          flags
        );

        if (regex.test(sourceText)) {
          matchedValues.add(ruleValue);
          if (matchingOption === 'first' || matchingOption === 'priority') {
            newItem[column] = ruleValue;
            break;
          }
        }
      }

      // 変換ルールでマッチしなかった場合、値リストでマッチング
      if (matchedValues.size === 0) {
        for (const val of values) {
          if (!val) continue;

          const escapedValue = escapeRegExp(val);
          const regex = new RegExp(
            partialMatch ? escapedValue : `\\b${escapedValue}\\b`,
            flags
          );

          if (regex.test(sourceText)) {
            matchedValues.add(val);
            if (matchingOption === 'first') {
              newItem[column] = val;
              break;
            }
          }
        }
      }

      const existingValue = newItem[column];
      if (
        matchedValues.size > 0 &&
        (overwriteExisting ||
          !existingValue ||
          existingValue === 'NA' ||
          existingValue === 'N/A' ||
          existingValue === 'No Brand')
      ) {
        let finalValue = '';
        if (matchingOption === 'first' || matchingOption === 'priority') {
          finalValue = matchedValues.values().next().value;
        } else if (matchingOption === 'all') {
          finalValue = Array.from(matchedValues).join(', ');
        }
        if (finalValue.length > 64) {
          finalValue = finalValue.substring(0, 64);
        }
        newItem[column] = finalValue;
      }
    });

    if (updateProgress) {
      updateProgress();
    }

    return newItem;
  });

  // ここで「新しい列」を headers に追加してあげる
  // 重複しないように concat / set など好きな方法で結合
  const finalHeaders = [...headers, ...newColumns];

  // 最終的に「newData」と「finalHeaders」をまとめて返す
  // ※ この返し方はあなたの呼び出し元に合わせて変更してください
  return { data: newData, headers: finalHeaders };
};


// 商品説明からAI生成部分を抽出する関数
const extractAIGeneratedContent = (description) => {
  if (!description) return '';

  // 商品説明全体を取得
  return description;
};

// 商品説明から KEY: Value のペアを抽出する関数
const extractKeyValuePairs = (content) => {
  const keyValuePairs = {};
  if (!content) return keyValuePairs;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  // <li> 要素をすべて取得
  const listItems = doc.querySelectorAll('li');

  // 先頭の不要な記号（✔, ✓, •, -, * など）を除去するための正規表現
  const bulletRegex = /^[\-\•\✓\✔\*\s]+/;
  // 「C:」で始まり、セパレータが「:」や「=」になっている行を抽出するための正規表現
  //   例: "C:Franchise : Mario + Rabbids" や "C:Includes = Some value" など
  const mainRegex = /^C[:：]\s*([^:=：]+?)\s*[:：=]\s*(.+)$/;

  listItems.forEach((li) => {
    // liのテキストを取得し、先頭の箇条書き記号を除去
    let text = li.textContent.trim();
    text = text.replace(bulletRegex, '').trim();

    // 1) まず "C:Key : Value" パターンを試す
    const cMatch = text.match(mainRegex);
    if (cMatch) {
      const key = cMatch[1].trim();
      const value = cMatch[2].trim();
      if (key && value) {
        keyValuePairs[key] = value;
      }
      return; // このliは処理済みなので次へ
    }

    // 2) もし C:パターンでなければ、<strong>タグを探して "Key: Value" を抜き出す
    const strongElement = li.querySelector('strong');
    if (strongElement) {
      // strong要素のテキスト (例: "Material:")
      // 末尾のコロンを取り除く
      const rawKey = strongElement.textContent.trim().replace(/[:：]\s*$/, '');
      // strong要素の直後のテキストノードをValueとみなす
      const rawValue = strongElement.nextSibling?.textContent?.trim() || '';
      // もし Value 側が ": something" となっていれば先頭の":"を除去
      const cleanValue = rawValue.replace(/^:\s*/, '');

      if (rawKey && cleanValue) {
        keyValuePairs[rawKey] = cleanValue;
      }
    }
  });

  return keyValuePairs;
};


// 文字列を正規化する関数
const normalizeString = (str) =>
  str
    .toLowerCase()
    .replace(/\s+/g, '')
    .replace(/[^a-z0-9]/g, '');

// 正規表現の特殊文字をエスケープする関数
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};