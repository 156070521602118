// CustomColumnSettings.js

import React, { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import {
  Box,
  Typography,
  Slider,
  TextField,
  Button,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import EditIcon from '@mui/icons-material/Edit';
import PriceCalculationHelp from './PriceCalculationHelp'; // 追加

const CustomColumnSettings = ({ onSave, initialSettings, userId }) => {
  console.log('Received userId in CustomColumnSettings:', userId); // デバッグ用ログ

  const [targetProfitMargin, setTargetProfitMargin] = useState(25);
  const [categories, setCategories] = useState({
    additionalCosts: [],
    expenses: [],
    fees: [],
    shipping: []
  });
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false); // ヘルプダイアログの状態
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(140);
  const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(false);
  const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
  const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);
  const [fees, setFees] = useState({
    ebayFinalValue: 10,
    payoneer: 2,
    promotedListing: 3,
    ebayTransactionFee: 0.3
  });
  const [discountRate, setDiscountRate] = useState(0);
  const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
  const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);

  // 複数の送料テンプレートを管理する状態を追加
  const [shippingRateTemplates, setShippingRateTemplates] = useState([
    {
      id: 'default',
      name: 'デフォルト送料設定',
      shippingRates: [
        { minPriceJPY: 0, maxPriceJPY: 7000, costJPY: 700, minPriceUSD: 0, maxPriceUSD: 50, costUSD: 5 },
        { minPriceJPY: 7000, maxPriceJPY: 14000, costJPY: 1400, minPriceUSD: 50, maxPriceUSD: 100, costUSD: 10 },
        { minPriceJPY: 14000, maxPriceJPY: Infinity, costJPY: 2100, minPriceUSD: 100, maxPriceUSD: Infinity, costUSD: 15 }
      ]
    }
  ]);
  const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('default');

  const selectedShippingTemplate = shippingRateTemplates.find(
    template => template.id === selectedShippingTemplateId
  );

  // テンプレート名編集用の状態を追加
  const [isEditTemplateNameDialogOpen, setIsEditTemplateNameDialogOpen] = useState(false);
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState('');

  const db = getFirestore();

  useEffect(() => {
    if (initialSettings) {
      setTargetProfitMargin(initialSettings.targetProfitMargin !== undefined ? initialSettings.targetProfitMargin : 25);
      setCategories(prevCategories => ({
        ...prevCategories,
        ...(initialSettings.categories || {})
      }));
      setShippingRateTemplates(initialSettings.shippingRateTemplates || shippingRateTemplates);
      setSelectedShippingTemplateId(initialSettings.selectedShippingTemplateId || 'default');
      setFees(prevFees => ({
        ...prevFees,
        ...(initialSettings.fees || {})
      }));
      setDiscountRate(initialSettings.discountRate !== undefined ? initialSettings.discountRate : 0);
      setBestOfferAutoAcceptPercentage(initialSettings.bestOfferAutoAcceptPercentage !== undefined ? initialSettings.bestOfferAutoAcceptPercentage : 95);
      setMinimumBestOfferPercentage(initialSettings.minimumBestOfferPercentage !== undefined ? initialSettings.minimumBestOfferPercentage : 80);
      setExchangeRate(initialSettings.exchangeRate !== undefined ? initialSettings.exchangeRate : 140);
    }
  }, [initialSettings]);

  useEffect(() => {
    if (userId) {
      loadUserSettings(userId);
    } else {
      console.warn('userId is null or undefined in CustomColumnSettings');
    }
  }, [userId]);

  const loadUserSettings = async (userId) => {
    try {
      const docRef = doc(db, "userSettings", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        console.log('Loaded settings from Firestore:', userSettings);

        setTargetProfitMargin(userSettings.targetProfitMargin !== undefined ? userSettings.targetProfitMargin : 25);
        setCategories(prevCategories => ({
          ...prevCategories,
          ...(userSettings.categories || {})
        }));
        setShippingRateTemplates(userSettings.shippingRateTemplates || shippingRateTemplates);
        setSelectedShippingTemplateId(userSettings.selectedShippingTemplateId || 'default');
        setFees(prevFees => ({
          ...prevFees,
          ...(userSettings.fees || {})
        }));
        setDiscountRate(userSettings.discountRate !== undefined ? userSettings.discountRate : 0);
        setBestOfferAutoAcceptPercentage(userSettings.bestOfferAutoAcceptPercentage !== undefined ? userSettings.bestOfferAutoAcceptPercentage : 95);
        setMinimumBestOfferPercentage(userSettings.minimumBestOfferPercentage !== undefined ? userSettings.minimumBestOfferPercentage : 80);
        setExchangeRate(userSettings.exchangeRate !== undefined ? userSettings.exchangeRate : 140);
      } else {
        console.log('No user settings found in Firestore for userId:', userId);
      }
    } catch (error) {
      console.error('Failed to load user settings:', error);
      setSnackbar({ open: true, message: 'ユーザー設定の読み込みに失敗しました', severity: 'error' });
    }
  };

  const saveUserSettings = async () => {
    if (!userId) {
      console.warn('Cannot save settings: userId is null or undefined');
      return;
    }

    const settings = {
      categories,
      targetProfitMargin,
      shippingRateTemplates,
      selectedShippingTemplateId,
      fees,
      discountRate,
      bestOfferAutoAcceptPercentage,
      minimumBestOfferPercentage,
      exchangeRate
    };

    try {
      console.log('Saving settings to Firestore for userId:', userId);
      const docRef = doc(db, "userSettings", userId);
      await setDoc(docRef, settings);
      setSnackbar({ open: true, message: 'ユーザー設定を保存しました', severity: 'success' });
    } catch (error) {
      console.error('Failed to save user settings:', error);
      setSnackbar({ open: true, message: 'ユーザー設定の保存に失敗しました', severity: 'error' });
    }
  };

  const fetchExchangeRate = async () => {
    setIsLoadingExchangeRate(true);
    try {
      const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
      const newRate = response.data.rates.JPY;
      setExchangeRate(newRate);
      updateShippingRatesWithNewExchangeRate(newRate);
      setSnackbar({ open: true, message: `為替レートを更新しました: 1 USD = ${newRate} JPY`, severity: 'info' });
    } catch (error) {
      console.error('Failed to fetch exchange rate:', error);
      setSnackbar({ open: true, message: '為替レートの取得に失敗しました。デフォルトのレートを使用します。', severity: 'error' });
    } finally {
      setIsLoadingExchangeRate(false);
    }
  };

  const updateShippingRatesWithNewExchangeRate = (newRate) => {
    setShippingRateTemplates(prevTemplates =>
      prevTemplates.map(template => ({
        ...template,
        shippingRates: template.shippingRates.map(rate => ({
          ...rate,
          minPriceUSD: (rate.minPriceJPY / newRate).toFixed(2),
          maxPriceUSD: rate.maxPriceJPY === Infinity ? Infinity : (rate.maxPriceJPY / newRate).toFixed(2),
          costUSD: (rate.costJPY / newRate).toFixed(2)
        }))
      }))
    );
  };

  useEffect(() => {
    updateShippingRatesWithNewExchangeRate(exchangeRate);
  }, [exchangeRate]);

  const calculateSellingPrice = useCallback((startPrice) => {
    let totalCost = startPrice;
    let totalPercentage = 0;

    Object.values(categories).flat().forEach(item => {
      if (item.type === 'fixed') {
        totalCost += item.value;
      } else if (item.type === 'percentage') {
        totalPercentage += item.value;
      }
    });

    totalPercentage += targetProfitMargin;
    const sellingPrice = totalCost / (1 - totalPercentage / 100);
    return sellingPrice.toFixed(2);
  }, [categories, targetProfitMargin]);

  const handleProfitMarginChange = (event, newValue) => {
    setTargetProfitMargin(newValue);
  };

  const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
    setCategories(prevCategories => ({
      ...prevCategories,
      [categoryKey]: prevCategories[categoryKey].map(item =>
        item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
      )
    }));
  };

  const handleAddItem = (categoryKey) => {
    const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
    setCategories(prevCategories => ({
      ...prevCategories,
      [categoryKey]: [...prevCategories[categoryKey], newItem]
    }));
    setSnackbar({ open: true, message: '新しい項目を追加しました', severity: 'success' });
  };

  const handleRemoveItem = (categoryKey, itemId) => {
    setCategories(prevCategories => ({
      ...prevCategories,
      [categoryKey]: prevCategories[categoryKey].filter(item => item.id !== itemId)
    }));
    setSnackbar({ open: true, message: '項目を削除しました', severity: 'info' });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceCategory = result.source.droppableId;
    const destCategory = result.destination.droppableId;

    setCategories(prevCategories => {
      const newCategories = { ...prevCategories };
      const [reorderedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
      newCategories[destCategory].splice(result.destination.index, 0, reorderedItem);
      return newCategories;
    });

    setSnackbar({ open: true, message: '項目の順序を変更しました', severity: 'info' });
  };

  // 新しい送料テンプレートを追加
  const handleAddShippingTemplate = () => {
    const newTemplateId = `template-${Date.now()}`;
    setShippingRateTemplates(prevTemplates => [
      ...prevTemplates,
      {
        id: newTemplateId,
        name: `新しいテンプレート ${prevTemplates.length + 1}`,
        shippingRates: []
      }
    ]);
    setSelectedShippingTemplateId(newTemplateId);
    setSnackbar({ open: true, message: '新しい送料テンプレートを追加しました', severity: 'success' });
  };

  // 送料テンプレートを削除
  const handleRemoveShippingTemplate = (templateId) => {
    setShippingRateTemplates(prevTemplates => prevTemplates.filter(template => template.id !== templateId));
    if (selectedShippingTemplateId === templateId && shippingRateTemplates.length > 1) {
      setSelectedShippingTemplateId(shippingRateTemplates[0].id);
    }
    setSnackbar({ open: true, message: '送料テンプレートを削除しました', severity: 'info' });
  };

  // 送料テンプレートの名前を変更
  const handleShippingTemplateNameChange = (templateId, newName) => {
    setShippingRateTemplates(prevTemplates =>
      prevTemplates.map(template =>
        template.id === templateId ? { ...template, name: newName } : template
      )
    );
  };

  // テンプレート名編集開始
  const handleEditTemplateName = (templateId) => {
    const template = shippingRateTemplates.find(t => t.id === templateId);
    if (template) {
      setEditingTemplateId(templateId);
      setNewTemplateName(template.name);
      setIsEditTemplateNameDialogOpen(true);
    }
  };

  // テンプレート名の更新
  const handleTemplateNameUpdate = () => {
    handleShippingTemplateNameChange(editingTemplateId, newTemplateName);
    setIsEditTemplateNameDialogOpen(false);
    setSnackbar({ open: true, message: 'テンプレート名を更新しました', severity: 'success' });
  };

  // 選択されたテンプレートの送料レートを更新
  const handleShippingRateChange = (index, field, value) => {
    setShippingRateTemplates(prevTemplates =>
      prevTemplates.map(template =>
        template.id === selectedShippingTemplateId
          ? {
              ...template,
              shippingRates: template.shippingRates.map((rate, i) =>
                i === index
                  ? {
                      ...rate,
                      [field]: parseFloat(value),
                      [field.endsWith('JPY') ? field.replace('JPY', 'USD') : field.replace('USD', 'JPY')]:
                        field.endsWith('JPY')
                          ? (parseFloat(value) / exchangeRate).toFixed(2)
                          : Math.round(parseFloat(value) * exchangeRate)
                    }
                  : rate
              )
            }
          : template
      )
    );
  };

  // 新しい送料レートを追加
  const handleAddShippingRate = () => {
    setShippingRateTemplates(prevTemplates =>
      prevTemplates.map(template =>
        template.id === selectedShippingTemplateId
          ? {
              ...template,
              shippingRates: [
                ...template.shippingRates,
                { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 }
              ]
            }
          : template
      )
    );
  };

  // 送料レートを削除
  const handleRemoveShippingRate = (index) => {
    setShippingRateTemplates(prevTemplates =>
      prevTemplates.map(template =>
        template.id === selectedShippingTemplateId
          ? {
              ...template,
              shippingRates: template.shippingRates.filter((_, i) => i !== index)
            }
          : template
      )
    );
  };

  const handleFeeChange = (feeType, value) => {
    setFees(prevFees => ({
      ...prevFees,
      [feeType]: parseFloat(value)
    }));
  };

  const handleDiscountRateChange = (event, newValue) => {
    setDiscountRate(newValue);
  };

  const handleBestOfferAutoAcceptChange = (event) => {
    setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
  };

  const handleMinimumBestOfferChange = (event) => {
    setMinimumBestOfferPercentage(parseFloat(event.target.value));
  };

  const handleExchangeRateChange = (event) => {
    const newRate = parseFloat(event.target.value);
    if (!isNaN(newRate) && newRate > 0) {
      setExchangeRate(newRate);
    }
  };

  const handleSaveSettings = () => {
    const settings = {
      categories,
      targetProfitMargin,
      shippingRateTemplates,
      selectedShippingTemplateId,
      fees,
      discountRate,
      bestOfferAutoAcceptPercentage,
      minimumBestOfferPercentage,
      exchangeRate,
      calculateSellingPrice: (startPrice) => calculateSellingPrice(startPrice)
    };
    onSave(settings);
    saveUserSettings();
    setSnackbar({ open: true, message: '設定を保存しました', severity: 'success' });
  };

  const handleResetSettings = () => {
    setIsResetDialogOpen(true);
  };

  const confirmResetSettings = () => {
    setCategories({
      additionalCosts: [],
      expenses: [],
      fees: [],
      shipping: []
    });
    setTargetProfitMargin(25);
    setShippingRateTemplates([
      {
        id: 'default',
        name: 'デフォルト送料設定',
        shippingRates: [
          { minPriceJPY: 0, maxPriceJPY: 7000, costJPY: 700, minPriceUSD: 0, maxPriceUSD: 50, costUSD: 5 },
          { minPriceJPY: 7000, maxPriceJPY: 14000, costJPY: 1400, minPriceUSD: 50, maxPriceUSD: 100, costUSD: 10 },
          { minPriceJPY: 14000, maxPriceJPY: Infinity, costJPY: 2100, minPriceUSD: 100, maxPriceUSD: Infinity, costUSD: 15 }
        ]
      }
    ]);
    setSelectedShippingTemplateId('default');
    setFees({
      ebayFinalValue: 10,
      payoneer: 2,
      promotedListing: 3,
      ebayTransactionFee: 0.3
    });
    setDiscountRate(0);
    setBestOfferAutoAcceptPercentage(95);
    setMinimumBestOfferPercentage(80);
    setExchangeRate(140);
    setIsResetDialogOpen(false);
    setSnackbar({ open: true, message: '設定をデフォルトに戻しました', severity: 'info' });
  };

  const renderCategory = (categoryKey, categoryName) => (
    <Accordion key={categoryKey}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{categoryName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Droppable droppableId={categoryKey}>
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {categories[categoryKey].map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                    >
                      <TextField
                        label={item.name}
                        value={item.name}
                        onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
                        size="small"
                        sx={{ flexGrow: 1, mr: 1 }}
                      />
                      <TextField
                        label="値"
                        value={item.value}
                        onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
                        type="number"
                        size="small"
                        sx={{ width: 100, mr: 1 }}
                      />
                      <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
                        <InputLabel>タイプ</InputLabel>
                        <Select
                          value={item.type}
                          onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
                          label="タイプ"
                        >
                          <MenuItem value="fixed">固定</MenuItem>
                          <MenuItem value="percentage">割合 (%)</MenuItem>
                        </Select>
                      </FormControl>
                      <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
          項目を追加
        </Button>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        価格計算設定
        <Tooltip title="ヘルプを表示">
          <IconButton onClick={() => setIsHelpDialogOpen(true)}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>目標利益率: {targetProfitMargin}%</Typography>
        <Slider
          value={targetProfitMargin}
          onChange={handleProfitMarginChange}
          aria-labelledby="profit-margin-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={100}
        />
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>為替レート設定</Typography>
        <Typography>
          為替レート設定: 1 USD = {exchangeRate.toFixed(2)} JPY
        </Typography>
        <TextField
          label="為替レート (1 USD = X JPY)"
          value={exchangeRate}
          onChange={handleExchangeRateChange}
          type="number"
          inputProps={{ step: "0.01" }}
          sx={{ width: 200, mt: 2 }}
        />
      </Paper>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsShippingDialogOpen(true)}
            fullWidth
          >
            送料設定
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsFeeDialogOpen(true)}
            fullWidth
          >
            手数料設定
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsDiscountDialogOpen(true)}
            fullWidth
          >
            割引設定
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsBestOfferDialogOpen(true)}
            fullWidth
          >
            ベストオファー設定
          </Button>
        </Grid>
      </Grid>

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderCategory('additionalCosts', '上乗せ金額')}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderCategory('fees', '手数料')}
          </Grid>
        </Grid>
      </DragDropContext>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
          設定を保存
        </Button>
        <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
          デフォルトに戻す
        </Button>
      </Box>

      {/* ヘルプダイアログ */}
      <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>価格計算の具体的な説明</DialogTitle>
        <DialogContent>
          <PriceCalculationHelp />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* リセット確認ダイアログ */}
      <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
        <DialogTitle>設定をリセット</DialogTitle>
        <DialogContent>
          <Typography>
            本当に設定をデフォルトに戻しますか？この操作は元に戻せません。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmResetSettings} color="error">リセット</Button>
        </DialogActions>
      </Dialog>

      {/* 送料設定ダイアログ */}
      <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>送料設定</DialogTitle>
        <DialogContent>
          {/* テンプレートの選択と管理 */}
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>送料テンプレートを選択</InputLabel>
              <Select
                value={selectedShippingTemplateId}
                onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
                label="送料テンプレートを選択"
              >
                {shippingRateTemplates.map(template => (
                  <MenuItem key={template.id} value={template.id} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {template.name}
                    </Typography>
                    {/* 編集ボタンを追加 */}
                    <IconButton size="small" onClick={() => handleEditTemplateName(template.id)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
              <Button startIcon={<AddIcon />} onClick={handleAddShippingTemplate}>
                新しいテンプレートを追加
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                onClick={() => handleRemoveShippingTemplate(selectedShippingTemplateId)}
                disabled={shippingRateTemplates.length <= 1}
              >
                テンプレートを削除
              </Button>
            </Box>
          </Box>

          {/* 選択されたテンプレートの送料設定 */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>最小仕入れ価格 (JPY)</TableCell>
                  <TableCell>最大仕入れ価格 (JPY)</TableCell>
                  <TableCell>送料 (JPY)</TableCell>
                  <TableCell>最小仕入れ価格 (USD)</TableCell>
                  <TableCell>最大仕入れ価格 (USD)</TableCell>
                  <TableCell>送料 (USD)</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedShippingTemplate.shippingRates.map((rate, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.minPriceJPY}
                        onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.maxPriceJPY === Infinity ? '' : rate.maxPriceJPY}
                        onChange={(e) => handleShippingRateChange(index, 'maxPriceJPY', e.target.value || Infinity)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.costJPY}
                        onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.minPriceUSD}
                        onChange={(e) => handleShippingRateChange(index, 'minPriceUSD', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.maxPriceUSD === Infinity ? '' : rate.maxPriceUSD}
                        onChange={(e) => handleShippingRateChange(index, 'maxPriceUSD', e.target.value || Infinity)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.costUSD}
                        onChange={(e) => handleShippingRateChange(index, 'costUSD', e.target.value)}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRemoveShippingRate(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
            送料設定を追加
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート名編集ダイアログを追加 */}
      <Dialog open={isEditTemplateNameDialogOpen} onClose={() => setIsEditTemplateNameDialogOpen(false)}>
        <DialogTitle>テンプレート名の編集</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="テンプレート名"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditTemplateNameDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleTemplateNameUpdate} color="primary">更新</Button>
        </DialogActions>
      </Dialog>

      {/* 手数料設定ダイアログ */}
      <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>手数料設定</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary="eBay最終価値手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.ebayFinalValue}
                  onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Payoneer手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.payoneer}
                  onChange={(e) => handleFeeChange('payoneer', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="広告出品手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.promotedListing}
                  onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="eBay取引手数料 ($)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.ebayTransactionFee}
                  onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* ベストオファー設定ダイアログ */}
      <Dialog open={isBestOfferDialogOpen} onClose={() => setIsBestOfferDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>ベストオファー設定</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ベストオファー自動承認価格 (%)"
                type="number"
                value={bestOfferAutoAcceptPercentage}
                onChange={handleBestOfferAutoAcceptChange}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="最小ベストオファー価格 (%)"
                type="number"
                value={minimumBestOfferPercentage}
                onChange={handleMinimumBestOfferChange}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomColumnSettings;