/**
 * ImprovedBulkListingFromScratch.js
 * 画像アップロード機能を残しつつ、CsvSchedulerUploader 相当の「自動編集機能」を
 * ファイルアップロードなしで、画像アップロード側のデータ（data state）に直接適用する改善版
 * UI/UXの大幅強化版
 */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  IconButton,
  AppBar,
  Toolbar,
  Grid,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Pagination,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Chip,
  Badge,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  CircularProgress,
  Backdrop,
  Breadcrumbs,
  Link,
  RadioGroup,
  Radio,
} from '@mui/material';

// アイコンのインポート
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Visibility';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SettingsIcon from '@mui/icons-material/Settings';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DownloadIcon from '@mui/icons-material/Download';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PhotoIcon from '@mui/icons-material/Photo';
import DescriptionIcon from '@mui/icons-material/Description';
import SaveIcon from '@mui/icons-material/Save';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TuneIcon from '@mui/icons-material/Tune';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import InfoIcon from '@mui/icons-material/Info';
import InputBase from '@mui/material/InputBase';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import { styled, alpha } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
// 正しい修正方法:
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { doc, getDoc, updateDoc, setDoc, arrayUnion, collection, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { getAuth } from 'firebase/auth';
import CryptoJS from 'crypto-js';

import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';

import EditableSpreadsheet from '../data_sheet/EditableSpreadsheet';
import ColumnNameBasedTemplateManager from '../template_func/ColumnNameBasedTemplateManager';
import APIKeyInput from '../setting_func/APIKeyInput';
import TitleGenerationComponent from '../ai_title_func/TitleGenerationComponent';
import OpenAIDescriptionGenerator from '../ai_description_func/OpenAIDescriptionGenerator';
import ItemSpecificsManager from '../itemspecifics_func/ItemSpecificsManager';
import UserTemplateManager from '../template_func/UserTemplateManager';
import { categoryTemplates } from '../eBay_info/CategoryTemplates';
import PriceManager from '../price_func/PriceManager';
import ImageOperations from '../image_func/ImageOperations';
import ImageUploader from '../image_func/ImageUploader';
import ColumnDeletion from '../template_func/ColumnDeletion';

// StandaloneImageAnalysis：画像解析用の独立ファイル（複数画像対応版）
import StandaloneImageAnalysis from '../image_func/StandaloneImageAnalysis';

// 旧テンプレート等の自動編集関数（すべて CSV 文字列を前提）
import { applyTemplate } from '../Auto_edit_func/functions';
import { calculatePrices } from '../Auto_edit_func/calculatePrices';
import { applyAITitles } from '../Auto_edit_func/applyAITitles';
import { applyAIProductDescriptions } from '../Auto_edit_func/applyAIProductDescriptions';
import { transformFinalHTMLWithDOMParser } from '../Auto_edit_func/domTransform';
import { applyItemSpecificsProcessor } from '../Auto_edit_func/ItemSpecificsProcessor';

// =======================
// ▼ ユーティリティ関数
// =======================
const getImageUrls = (picUrlString) => {
  if (!picUrlString) return [];
  return picUrlString.split('|').filter(Boolean);
};

async function loadTitleSettings(uid) {
  const docRef = doc(db, 'userTitleSettings', uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log('userTitleSettings が見つかりません');
    return null;
  }
}

// 画像解析用ラッパー関数（空の説明のみ解析）
const applyImageAnalysisIfEmpty = async (currentData, apiKey, setSnackbar) => {
  try {
    const updatedData = await Promise.all(
      currentData.map(async (file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        const rows = parsedResult.data;
        const updatedRows = await Promise.all(
          rows.map(async (row) => {
            if (!row['jp_desc'] || row['jp_desc'].trim() === '') {
              const picUrlString = row['PicURL'] || '';
              const picUrls = picUrlString.split('|').filter(Boolean);
              if (picUrls.length > 0) {
                // 複数画像の場合、picUrls 配列全体を渡す
                const description = await StandaloneImageAnalysis(picUrls, apiKey);
                row['jp_image_description'] = description;
              }
            }
            return row;
          })
        );
        const csvContent = Papa.unparse(updatedRows);
        return { ...file, content: csvContent };
      })
    );
    return updatedData;
  } catch (error) {
    console.error('画像解析でエラー:', error);
    setSnackbar({
      open: true,
      message: '画像解析中にエラーが発生しました。',
      severity: 'error',
    });
    throw error;
  }
};

const applyImageAnalysisForAll = async (currentData, apiKey, setSnackbar) => {
  try {
    const updatedData = await Promise.all(
      currentData.map(async (file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        const rows = parsedResult.data;
        const updatedRows = await Promise.all(
          rows.map(async (row) => {
            const picUrlString = row['PicURL'] || '';
            const picUrls = picUrlString.split('|').filter(Boolean);
            if (picUrls.length > 0) {
              const description = await StandaloneImageAnalysis(picUrls, apiKey);
              row['jp_image_description'] = description;
            }
            return row;
          })
        );
        const csvContent = Papa.unparse(updatedRows);
        return { ...file, content: csvContent };
      })
    );
    return updatedData;
  } catch (error) {
    console.error('画像解析でエラー:', error);
    setSnackbar({
      open: true,
      message: '画像解析中にエラーが発生しました。',
      severity: 'error',
    });
    throw error;
  }
};

// 価格変更用の関数
const applyPriceChanges = async (currentData, selectedPriceSettings, setSnackbar) => {
  try {
    if (!selectedPriceSettings) {
      throw new Error('価格設定が取得されていません。');
    }
    const updatedSettings = { ...selectedPriceSettings };
    const updatedData = await Promise.all(
      currentData.map(async (file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        const rows = parsedResult.data;
        const newPrices = calculatePrices(rows, updatedSettings);
        const updatedRows = rows.map((item, index) => {
          const calculatedPrice = newPrices[index];
          if (calculatedPrice) {
            return {
              ...item,
              StartPrice: calculatedPrice.discountedPrice,
              BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
              MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
            };
          }
          return item;
        });
        const csvContent = Papa.unparse(updatedRows);
        return { ...file, content: csvContent };
      })
    );
    return updatedData;
  } catch (error) {
    console.error('価格適用中エラー:', error);
    setSnackbar({
      open: true,
      message: '価格適用中にエラーが発生しました',
      severity: 'error',
    });
    throw error;
  }
};

// ItemSpecifics 適用用ラッパー関数
async function applyItemSpecificsWrapper(
  currentData,
  userUid,
  {
    selectedItemSpecificsCategory,
    selectedItemSpecificsColumns,
    itemSpecificsMatchingOptions,
    overwriteExisting,
    setSnackbar,
    setProgress,
  }
) {
  try {
    if (!selectedItemSpecificsCategory) {
      setSnackbar?.({
        open: true,
        message: '適用するItem Specificsのカテゴリーが選択されていません。',
        severity: 'warning',
      });
      throw new Error('Item Specificsカテゴリー未選択');
    }
    if (setProgress) {
      setProgress({ totalItems: 0, completedItems: 0 });
    }
    let updatedData = await applyItemSpecificsProcessor(
      currentData,
      userUid,
      {
        selectedCategory: selectedItemSpecificsCategory,
        selectedColumns: selectedItemSpecificsColumns,
        matchingOptions: itemSpecificsMatchingOptions,
      },
      (prog) => {
        if (setProgress) {
          setProgress(prog);
        }
      }
    );
    return updatedData;
  } catch (error) {
    console.error('applyItemSpecificsWrapperエラー:', error);
    setSnackbar?.({
      open: true,
      message: 'Item Specifics適用中にエラーが発生しました',
      severity: 'error',
    });
    throw error;
  }
}

// JSONを折りたたみ表示するためのコンポーネント
function TruncatedText({ data, maxLines = 5 }) {
  const [expanded, setExpanded] = useState(false);
  if (!data) {
    return <Typography variant="body2" sx={{ color: 'text.secondary' }}>データがありません</Typography>;
  }
  const jsonString = JSON.stringify(data, null, 2);
  const lines = jsonString.split('\n');
  const displayedLines = expanded ? lines : lines.slice(0, maxLines);
  const isLong = lines.length > maxLines;
  return (
    <Box sx={{ position: 'relative', mt: 1 }}>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
        {displayedLines.join('\n')}
      </Typography>
      {isLong && (
        <Button
          variant="text"
          size="small"
          onClick={() => setExpanded(!expanded)}
          sx={{ mt: 1 }}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Box>
  );
}

// 追加：拡張された画像選択ダイアログ
const EnhancedImageSelectionDialog = ({ open, images, onClose, onConfirm }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // 画像選択時の処理
  const handleImageSelect = (imageUrl) => {
    if (selectedImages.includes(imageUrl)) {
      setSelectedImages(selectedImages.filter(url => url !== imageUrl));
    } else {
      setSelectedImages([...selectedImages, imageUrl]);
    }
  };

  // 拡大プレビュー表示
  const handleImagePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  // プレビューを閉じる
  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  // 確認ボタン押下時の処理
  const handleConfirm = () => {
    onConfirm(selectedImages);
    setSelectedImages([]);
  };

  // キャンセル時の処理
  const handleCancel = () => {
    setSelectedImages([]);
    onClose();
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleCancel}
        fullWidth
        maxWidth="md"
        fullScreen={fullScreen}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h6">画像を選択</Typography>
            <Typography variant="body2" color="text.secondary">
              AIで解析する画像を選択してください（複数選択可）
            </Typography>
          </Box>
          <IconButton onClick={handleCancel} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <Divider />
        
        <DialogContent>
          {images.length === 0 ? (
            <Box sx={{ p: 4, textAlign: 'center' }}>
              <Typography variant="body1">解析できる画像がありません</Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {images.map((imageUrl, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Paper 
                    elevation={3} 
                    sx={{ 
                      p: 1, 
                      position: 'relative',
                      border: selectedImages.includes(imageUrl) 
                        ? `2px solid ${theme.palette.primary.main}` 
                        : 'none',
                      transition: 'all 0.2s ease'
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <img 
                        src={imageUrl} 
                        alt={`商品画像 ${index + 1}`}
                        style={{ 
                          width: '100%', 
                          height: 150, 
                          objectFit: 'cover',
                          cursor: 'pointer',
                          borderRadius: 4
                        }}
                        onClick={() => handleImageSelect(imageUrl)}
                      />
                      
                      {/* 選択状態のチェックマーク */}
                      {selectedImages.includes(imageUrl) && (
                        <CheckCircleIcon 
                          color="primary" 
                          sx={{ 
                            position: 'absolute', 
                            top: 8, 
                            right: 8,
                            fontSize: 28,
                            backgroundColor: 'rgba(255,255,255,0.7)',
                            borderRadius: '50%'
                          }} 
                        />
                      )}
                      
                      {/* 拡大表示アイコン */}
                      <Tooltip title="拡大表示">
                        <IconButton 
                          size="small"
                          onClick={() => handleImagePreview(imageUrl)}
                          sx={{ 
                            position: 'absolute', 
                            bottom: 8, 
                            right: 8,
                            backgroundColor: 'rgba(255,255,255,0.7)',
                            '&:hover': {
                              backgroundColor: 'rgba(255,255,255,0.9)',
                            }
                          }}
                        >
                          <ZoomInIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'space-between',
                      mt: 1 
                    }}>
                      <Typography variant="body2" noWrap>
                        画像 {index + 1}
                      </Typography>
                      <Checkbox 
                        checked={selectedImages.includes(imageUrl)}
                        onChange={() => handleImageSelect(imageUrl)}
                        size="small"
                      />
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        
        <Divider />
        
        <DialogActions sx={{ px: 3, py: 2, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon fontSize="small" color="info" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              {selectedImages.length}枚の画像が選択されています
            </Typography>
          </Box>
          <Box>
            <Button onClick={handleCancel} sx={{ mr: 1 }}>
              キャンセル
            </Button>
            <Button 
              variant="contained" 
              onClick={handleConfirm}
              disabled={selectedImages.length === 0}
            >
              選択した画像を解析
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      
      {/* 拡大プレビューダイアログ */}
      <Dialog
        open={!!previewImage}
        onClose={handleClosePreview}
        maxWidth="lg"
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleClosePreview}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewImage && (
            <img 
              src={previewImage} 
              alt="拡大表示"
              style={{ 
                maxWidth: '100%', 
                maxHeight: 'calc(90vh - 120px)',
                display: 'block',
                margin: '0 auto'
              }} 
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

// カスタムスタイル
const drawerWidth = 200;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    overflowX: 'auto',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

// カスタム検索フィールド
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

// ファイルドロップ用エリア
const DropZone = styled('div')(({ theme, isDragging }) => ({
  border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: isDragging 
    ? alpha(theme.palette.primary.main, 0.1) 
    : alpha(theme.palette.background.default, 0.5),
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
}));

// ======================
// メインコンポーネント
// ======================
function BulkListingFromScratch() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  // アクティブなステップ
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  // (1) Sidebar / Template 関連
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeSection, setActiveSection] = useState('main');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [columns, setColumns] = useState([]);
  // ★ 画像アップロード機能で編集するテーブルデータ
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [previewDialogMode, setPreviewDialogMode] = useState('edit');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [viewMode, setViewMode] = useState('table');

  // APIキー・認証
  const [apiKey, setApiKey] = useState(null);
  const [user, setUser] = useState(null);

  // CSVアップロードダイアログ（テンプレート作成用）
  const [isCSVUploadDialogOpen, setIsCSVUploadDialogOpen] = useState(false);
  const [csvFile, setCsvFile] = useState(null);

  const [userTemplates, setUserTemplates] = useState([]);
  const [userCategoryTemplates, setUserCategoryTemplates] = useState([]);
  const [isDataModified, setIsDataModified] = useState(false);

  // (2) 自動編集機能用状態（※ファイルアップロード不要なので、data を直接対象）
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [functionDialogOpen, setFunctionDialogOpen] = useState(false);

  // スケジュール関連（必要に応じて）
  const [isImmediate, setIsImmediate] = useState(true);
  const [scheduledTime, setScheduledTime] = useState('');
  const [isScheduling, setIsScheduling] = useState(false);

  // パターン管理
  const [patterns, setPatterns] = useState([]);
  const [selectedPatternName, setSelectedPatternName] = useState('');
  const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
  const [newPatternName, setNewPatternName] = useState('');
  const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

  // AI タイトル生成関連
  const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
  const [errorItems, setErrorItems] = useState([]);
  const [deleteStrings, setDeleteStrings] = useState([]);
  const [replacePairs, setReplacePairs] = useState([]);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [limitTitleLength, setLimitTitleLength] = useState(false);
  const [customCategories, setCustomCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [additionalPrompt, setAdditionalPrompt] = useState('');
  const [useImageAnalysisForTitle, setUseImageAnalysisForTitle] = useState(true);

  // AI 商品説明生成
  const [templates, setTemplates] = useState([]);
  const [selectedAiDescTemplateId, setSelectedAiDescTemplateId] = useState('');
  const [aiDescTemplateData, setAiDescTemplateData] = useState(null);
  const [customFilters, setCustomFilters] = useState([]);
  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]);
  const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [templateLoading, setTemplateLoading] = useState(false);
  const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
  const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
  const [saveOption, setSaveOption] = useState('new');
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

  // 価格系
  const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);
  const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
  const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');
  const [exchangeRate, setExchangeRate] = useState(0);
  const [targetProfitMargin, setTargetProfitMargin] = useState(0);
  const [fees, setFees] = useState({});
  const [bestOfferSettings, setBestOfferSettings] = useState({});
  const [hasInitialized, setHasInitialized] = useState(false);

  // プレビュー関連
  const [activeTab, setActiveTab] = useState(0);
  const [itemsPerPageForPreview, setItemsPerPageForPreview] = useState(50);
  const [previewCurrentPage, setPreviewCurrentPage] = useState(1);
  const [previewDialogOpen2, setPreviewDialogOpen2] = useState(false);
  const [previewRowData, setPreviewRowData] = useState(null);

  // バナー設定
  const [bannerEnabled, setBannerEnabled] = useState(false);
  const [bannerUrl, setBannerUrl] = useState('');
  const [picInsertEnabled, setPicInsertEnabled] = useState(false);

  // 除外ワード関連
  const [deleteWordTemplates, setDeleteWordTemplates] = useState([]);
  const [selectedDeleteWordTemplateId, setSelectedDeleteWordTemplateId] = useState('');
  const [deleteWords, setDeleteWords] = useState([]);
  const [backupProcessedData, setBackupProcessedData] = useState([]);
  const [exclusionLog, setExclusionLog] = useState([]);
  const [isExclusionLogOpen, setIsExclusionLogOpen] = useState(false);

  // ItemSpecifics
  const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
  const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
  const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
    caseSensitive: false,
    partialMatch: true,
    matchSource: 'both',
    matchingOption: 'priority',
  });
  const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});
  const [overwriteExisting, setOverwriteExisting] = useState(true);

  // ▼ 選択されたカテゴリの columns (UI 表示用)
  const [availableColumns, setAvailableColumns] = useState([]);

  // 画像解析モード
  const [imageAnalysisMode, setImageAnalysisMode] = useState('all'); // or "all"
  const [imageAnalysisStrategy, setImageAnalysisStrategy] = useState('preferAnalysis');

  // ★ 追加：画像解析対象の画像選択用ダイアログの状態
  const [analysisDialogOpen, setAnalysisDialogOpen] = useState(false);
  const [analysisImageUrl, setAnalysisImageUrl] = useState("");
  const [analysisImageList, setAnalysisImageList] = useState([]);
  const [analysisResult, setAnalysisResult] = useState("");
  const [imageSelectionDialogOpen, setImageSelectionDialogOpen] = useState(false);
  const [selectedAnalysisImages, setSelectedAnalysisImages] = useState([]);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  // 1. 新しい状態変数を追加
const [showAnalysisPreview, setShowAnalysisPreview] = useState(false);
const [analysisPreviewData, setAnalysisPreviewData] = useState(null);


  // 商品ごとに選択された画像リストを保持する state
  const [productImageDialogOpen, setProductImageDialogOpen] = useState(false);
  const [productImages, setProductImages] = useState([]);  // 対象商品の画像URLリスト
  const [currentProduct, setCurrentProduct] = useState(null); // 現在解析対象の商品のデータ

  // ドラッグ＆ドロップの状態
  const [isDragging, setIsDragging] = useState(false);

  // 次のステップへ進む
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const newCompleted = { ...completed };
      newCompleted[prevActiveStep] = true;
      setCompleted(newCompleted);
      return prevActiveStep + 1;
    });
  };

  // 前のステップへ戻る
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // ステップのリセット
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // ステップを直接クリック
  const handleStepClick = (step) => {
    // 前のステップまでは直接進める
    if (step < activeStep || completed[step - 1]) {
      setActiveStep(step);
    }
  };

  // 各商品行の「解析」ボタン押下時のハンドラ
  const handleOpenProductImageDialog = (productData, rowIndex) => {
    setCurrentRowIndex(rowIndex); // ★ここでセット
    const images = getImageUrls(productData.PicURL);
    if (images.length === 0) {
      showSnackbar("この商品の画像がありません。", "warning");
      return;
    }
    setCurrentProduct(productData);
    setProductImages(images);
    setProductImageDialogOpen(true);
  };

  const handleConfirmProductImages = async (selectedImages) => {
    try {
      const analysisResult = await StandaloneImageAnalysis(selectedImages, apiKey);

      // 解析結果を既存の jp_image_description に追加（改行区切りで連結する例）
      setData((prevData) => {
        const newData = prevData.map((row, idx) => {
          if (idx === currentRowIndex) {
            // 既存の値があれば連結し、なければそのまま解析結果をセット
            const newAnalysis = row.jp_image_description
              ? `${row.jp_image_description}\n${analysisResult}`
              : analysisResult;
            return {
              ...row,
              jp_image_description: newAnalysis,
            };
          }
          return row;
        });
        // もしカラム配列に "jp_image_description" が含まれていなければ追加
        setColumns((prevColumns) => {
          if (!prevColumns.includes("jp_image_description")) {
            return [...prevColumns, "jp_image_description"];
          }
          return prevColumns;
        });
        return newData;
      });

      showSnackbar("画像解析結果を追加しました。", "success");
    } catch (error) {
      console.error("画像解析実行エラー:", error);
      showSnackbar("画像解析の実行中にエラーが発生しました。", "error");
    } finally {
      setProductImageDialogOpen(false);
    }
  };

  // -----------------------------
  // (3) ユーザー認証 & APIキー取得
  // -----------------------------
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return () => unsubscribe();
  }, []);

  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value';
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    const fetchApiKey = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'userApiKeys', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            const encryptionKey = generateEncryptionKey(user.uid);
            const decryptedBytes = CryptoJS.AES.decrypt(data.apiKey, encryptionKey);
            const decryptedKey = decryptedBytes.toString(CryptoJS.enc.Utf8);
            if (decryptedKey) {
              setApiKey(decryptedKey);
            } else {
              console.error('APIキーの復号化に失敗しました。');
              setApiKey(null);
              showSnackbar('APIキーの復号化に失敗しました。再度設定してください。', 'error');
            }
          } else {
            console.error('APIキーが設定されていません。');
            setApiKey(null);
            showSnackbar('APIキーが設定されていません。APIキーを入力してください。', 'warning');
          }
        } catch (error) {
          console.error('APIキーの取得に失敗しました:', error);
          setApiKey(null);
          showSnackbar('APIキーの取得に失敗しました。', 'error');
        }
      }
    };
    fetchApiKey();
  }, [user]);

  // -----------------------------
  // (4) テンプレート類の取得
  // -----------------------------
  useEffect(() => {
    const fetchUserTemplates = async () => {
      if (user) {
        try {
          const userTemplatesRef = doc(db, 'userTemplates', user.uid);
          const snap = await getDoc(userTemplatesRef);
          if (snap.exists()) {
            const data = snap.data();
            setUserTemplates(data.templates || []);
          }
        } catch (error) {
          console.error('テンプレートの取得に失敗しました:', error);
        }
      }
    };
    fetchUserTemplates();
  }, [user]);

  useEffect(() => {
    const fetchUserCategoryTemplates = async () => {
      if (user) {
        try {
          const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
          const snap = await getDoc(userCategoryTemplatesRef);
          if (snap.exists()) {
            const data = snap.data();
            setUserCategoryTemplates(data.templates || []);
          }
        } catch (error) {
          console.error('カテゴリーテンプレートの取得に失敗しました:', error);
        }
      }
    };
    fetchUserCategoryTemplates();
  }, [user]);

  // ItemSpecifics Settings
  useEffect(() => {
    const fetchItemSpecificsSettings = async () => {
      if (!user) return;
      try {
        const docRef2 = doc(db, 'itemSpecificsSettings', user.uid);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
          const data2 = docSnap2.data();
          setItemSpecificsCategories(data2.categories || {});
        }
      } catch (error) {
        console.error('Item Specifics設定取得エラー:', error);
      }
    };
    fetchItemSpecificsSettings();
  }, [user]);

  // -----------------------------
  // (5) userSettings: 価格設定など
  // -----------------------------
  const reloadPriceSettings = async () => {
    if (!user) return;
    try {
      const docRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userSettingsData = docSnap.data();
        setSelectedPriceSettings(userSettingsData);
        if (userSettingsData.shippingRateTemplates) {
          setShippingRateTemplates(userSettingsData.shippingRateTemplates);
        }
        setSelectedShippingTemplateId(userSettingsData.selectedShippingTemplateId || '');
        setExchangeRate(userSettingsData.exchangeRate || 0);
        setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
        setFees(userSettingsData.fees || {});
        setBestOfferSettings({
          bestOfferAutoAcceptPercentage: userSettingsData.bestOfferAutoAcceptPercentage || 0,
          minimumBestOfferPercentage: userSettingsData.minimumBestOfferPercentage || 0,
        });
        showSnackbar('最新の価格設定を読み込みました。', 'success');
      } else {
        showSnackbar('ユーザー設定が見つかりませんでした。', 'error');
      }
    } catch (error) {
      console.error('価格設定再読み込みエラー:', error);
      showSnackbar('価格設定の再読み込みに失敗しました', 'error');
    }
  };

  useEffect(() => {
    if (!user) return;
    const fetchUserSettings = async () => {
      try {
        const docRef = doc(db, 'userSettings', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userSettingsData = docSnap.data();
          setSelectedPriceSettings(userSettingsData);
          if (!hasInitialized) {
            setSelectedShippingTemplateId(userSettingsData.selectedShippingTemplateId || '');
            setHasInitialized(true);
            if (userSettingsData.shippingRateTemplates) {
              setShippingRateTemplates(userSettingsData.shippingRateTemplates);
            }
          }
          setExchangeRate(userSettingsData.exchangeRate || 0);
          setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
          setFees(userSettingsData.fees || {});
          setBestOfferSettings({
            bestOfferAutoAcceptPercentage: userSettingsData.bestOfferAutoAcceptPercentage || 0,
            minimumBestOfferPercentage: userSettingsData.minimumBestOfferPercentage || 0,
          });
          if (userSettingsData.patterns) {
            setPatterns(userSettingsData.patterns);
          }
        }
      } catch (error) {
        console.error('userSettings読み込みエラー:', error);
        showSnackbar('ユーザー設定の取得に失敗しました', 'error');
      }
    };

    // AIタイトル設定の読み込み
    const loadTitleSettingsFromFirestore = async () => {
      try {
        const titleSettingsData = await loadTitleSettings(user.uid);
        if (titleSettingsData) {
          if (titleSettingsData.deleteStrings) {
            setDeleteStrings(titleSettingsData.deleteStrings);
          }
          if (titleSettingsData.replacePairs) {
            setReplacePairs(titleSettingsData.replacePairs);
          }
          if (titleSettingsData.prependText) {
            setPrependText(titleSettingsData.prependText);
          }
          if (titleSettingsData.appendText) {
            setAppendText(titleSettingsData.appendText);
          }
          if (titleSettingsData.additionalPrompt) {
            setAdditionalPrompt(titleSettingsData.additionalPrompt);
          }
          if (typeof titleSettingsData.limitTitleLength === 'boolean') {
            setLimitTitleLength(titleSettingsData.limitTitleLength);
          }
          if (titleSettingsData.customCategories) {
            setCustomCategories(titleSettingsData.customCategories);
          }
        }
      } catch (error) {
        console.error('AIタイトル設定読み込みエラー:', error);
      }
    };

    // AI商品説明テンプレ subcollection の読み込み
    const loadTemplatesSubcollection = async () => {
      try {
        const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
        const snap = await getDocs(userTemplatesRef);
        const data = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTemplates(data);
      } catch (error) {
        console.error('AI説明用テンプレート取得エラー:', error);
        showSnackbar('AI説明用テンプレートの取得に失敗しました', 'error');
      }
    };

    fetchUserSettings();
    loadTitleSettingsFromFirestore();
    loadTemplatesSubcollection();

    

    // 除外ワードテンプレートの取得
    const docRef = doc(db, 'users', user.uid, 'deleteWordTemplates', 'list');
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const docData = docSnap.data();
          setDeleteWordTemplates(docData.templates || []);
        } else {
          setDeleteWordTemplates([]);
        }
      })
      .catch((err) => {
        console.error('テンプレート一覧取得エラー:', err);
        showSnackbar('除外ワードテンプレートの取得に失敗しました', 'error');
      });
  }, [user, hasInitialized]);

  // -----------------------------
  // (6) CSVアップロード処理（テンプレート作成用）
  // -----------------------------
  const openCSVUploadDialog = () => {
    setIsCSVUploadDialogOpen(true);
  };
  const handleCSVFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
    }
  };
  const handleCSVUpload = () => {
    if (!csvFile) {
      showSnackbar('CSVファイルを選択してください。', 'warning');
      return;
    }
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const columnsFromCSV = results.meta.fields;
        const templateName = prompt('テンプレート名を入力してください:');
        if (templateName && columnsFromCSV) {
          await saveUserCategoryTemplate(templateName, columnsFromCSV);
          setIsCSVUploadDialogOpen(false);
          setCsvFile(null);
          showSnackbar('テンプレートが保存されました', 'success');
        } else {
          showSnackbar('テンプレート名が無効です。', 'error');
        }
      },
      error: (error) => {
        console.error('CSVのパースに失敗しました:', error);
        showSnackbar('CSVファイルの読み込みに失敗しました。', 'error');
      },
    });
  };
  const saveUserCategoryTemplate = async (templateName, columns) => {
    if (!user) {
      showSnackbar('ユーザーが認証されていません。再度ログインしてください。', 'error');
      return;
    }
    try {
      const userCategoryTemplatesRef = doc(db, 'userCategoryTemplates', user.uid);
      const snap = await getDoc(userCategoryTemplatesRef);
      const newTemplate = { name: templateName, columns };
      if (snap.exists()) {
        const data = snap.data();
        const existingTemplates = data.templates || [];
        const duplicate = existingTemplates.find((t) => t.name === templateName);
        if (duplicate) {
          showSnackbar('同じ名前のテンプレートが既に存在します。別の名前を使用してください。', 'warning');
          return;
        }
        await updateDoc(userCategoryTemplatesRef, {
          templates: arrayUnion(newTemplate),
        });
        setUserCategoryTemplates([...existingTemplates, newTemplate]);
      } else {
        await setDoc(userCategoryTemplatesRef, { templates: [newTemplate] });
        setUserCategoryTemplates([newTemplate]);
      }
    } catch (error) {
      console.error('カテゴリーテンプレートの保存に失敗しました:', error);
      showSnackbar('カテゴリーテンプレートの保存に失敗しました。', 'error');
    }
  };

  // -----------------------------
  // (8) 機能選択ダイアログ（自動編集で実行する機能の選択）
  // -----------------------------
  const availableFunctions = [
    // { name: 'applyAITitles', displayName: 'AIタイトル生成を適用する', icon: <SmartToyIcon /> },
    // { name: 'applyAIProductDescriptions', displayName: 'AI商品説明生成を適用する', icon: <DescriptionIcon /> },
    { name: 'applyItemSpecifics', displayName: 'Item Specificsを適用する', icon: <PlaylistAddCheckIcon /> },
  ];
  const handleFunctionDialogOpen = () => setFunctionDialogOpen(true);
  const handleFunctionDialogClose = () => setFunctionDialogOpen(false);
  const handleFunctionToggle = (func) => () => {
    const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
    const newSelected = [...selectedFunctions];
    if (currentIndex === -1) {
      newSelected.push(func);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelectedFunctions(newSelected);
  };

  // -----------------------------
  // (9) パターン管理
  // -----------------------------
  const applyPatternSettings = (pattern) => {
    if (!pattern || !pattern.settings) {
      showSnackbar('選択されたパターンが正しくありません。', 'error');
      return;
    }
    const settings = pattern.settings;
    if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
      // 旧テンプレートの設定を反映
    }
    if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
      setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
      setSelectedPriceSettings((prev) => ({
        ...prev,
        selectedShippingTemplateId: settings.applyPriceChanges.selectedShippingTemplateId,
      }));
    }
    if (settings.applyAITitles) {
      const aiTitle = settings.applyAITitles;
      setSelectedCategory(aiTitle.selectedCategory || '');
      setDeleteStrings(aiTitle.deleteStrings || []);
      setReplacePairs(aiTitle.replacePairs || []);
      setPrependText(aiTitle.prependText || '');
      setAppendText(aiTitle.appendText || '');
      setAdditionalPrompt(aiTitle.additionalPrompt || '');
      setLimitTitleLength(aiTitle.limitTitleLength || false);
      setCustomCategories(aiTitle.customCategories || []);
    }
    if (settings.applyAIProductDescriptions) {
      const aiDesc = settings.applyAIProductDescriptions;
      setSelectedAiDescTemplateId(aiDesc.selectedAiDescTemplateId || '');
      setCustomFilters(aiDesc.customFilters || []);
      setSentencesToDelete(aiDesc.sentencesToDelete || []);
      setWordsToReplace(aiDesc.wordsToReplace || []);
      setMandatoryKeywords(aiDesc.mandatoryKeywords || []);
      setSelectedFilters(aiDesc.selectedFilters || []);
      setSelectedDesignTemplate(aiDesc.selectedTemplate || '');
      setBannerEnabled(aiDesc.bannerEnabled || false);
      setBannerUrl(aiDesc.bannerUrl || '');
      setPicInsertEnabled(aiDesc.picInsertEnabled || false);
    }
    if (settings.applyItemSpecifics) {
      setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory || '');
      setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
      setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || {});
      setOverwriteExisting(settings.applyItemSpecifics.overwriteExisting || false);
    }
    const newSelectedFunctions = [];
    if (settings.applyTemplate) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyTemplate'));
    } else {
      console.warn('applyTemplate が availableFunctions に存在しません');
    }
    if (settings.applyPriceChanges) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyPriceChanges'));
    } else {
      console.warn('applyPriceChanges が availableFunctions に存在しません');
    }
    if (settings.applyAITitles) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyAITitles'));
    } else {
      console.warn('applyAITitles が availableFunctions に存在しません');
    }
    if (settings.applyAIProductDescriptions) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyAIProductDescriptions'));
    } else {
      console.warn('applyAIProductDescriptions が availableFunctions に存在しません');
    }
    if (settings.applyItemSpecifics) {
      newSelectedFunctions.push(availableFunctions.find((f) => f.name === 'applyItemSpecifics'));
    } else    {
      console.warn('applyItemSpecifics が availableFunctions に存在しません');
    }
    setSelectedFunctions(newSelectedFunctions);
    showSnackbar(`パターン "${pattern.name}" が適用されました。`, 'success');
  };

  const saveCurrentSettingsAsPattern = async () => {
    if (!newPatternName.trim()) {
      showSnackbar('パターン名を入力してください', 'warning');
      return;
    }
    if (!user) {
      showSnackbar('ユーザーが認証されていません', 'error');
      return;
    }
    const newPattern = { name: newPatternName.trim(), settings: {} };
    if (selectedFunctions.some((f) => f.name === 'applyTemplate')) {
      newPattern.settings.applyTemplate = { selectedTemplateName };
    }
    if (selectedFunctions.some((f) => f.name === 'applyPriceChanges')) {
      newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
    }
    if (selectedFunctions.some((f) => f.name === 'applyAITitles')) {
      newPattern.settings.applyAITitles = {
        selectedCategory,
        deleteStrings,
        replacePairs,
        prependText,
        appendText,
        additionalPrompt,
        limitTitleLength,
        customCategories,
      };
    }
    if (selectedFunctions.some((f) => f.name === 'applyAIProductDescriptions')) {
      newPattern.settings.applyAIProductDescriptions = {
        selectedAiDescTemplateId,
        customFilters,
        sentencesToDelete,
        wordsToReplace,
        mandatoryKeywords,
        selectedFilters,
        selectedTemplate: selectedDesignTemplate,
        bannerEnabled,
        bannerUrl,
        picInsertEnabled,
      };
    }
    if (selectedFunctions.some((f) => f.name === 'applyItemSpecifics')) {
      newPattern.settings.applyItemSpecifics = {
        selectedItemSpecificsCategory,
        selectedItemSpecificsColumns,
        itemSpecificsMatchingOptions,
        overwriteExisting,
      };
    }
    try {
      const patternsRef = collection(db, 'users', user.uid, 'patterns');
      await addDoc(patternsRef, newPattern);
      const snapshot = await getDocs(patternsRef);
      const patternsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPatterns(patternsData);
      setIsSavePatternDialogOpen(false);
      setNewPatternName('');
      showSnackbar('パターンが保存されました。', 'success');
    } catch (error) {
      console.error('パターン保存中エラー:', error);
      showSnackbar('パターンの保存中にエラーが発生しました', 'error');
    }
  };

  const handleDeletePattern = async (patternId) => {
    if (!user) return;
    try {
      const patternDocRef = doc(db, 'users', user.uid, 'patterns', patternId);
      await deleteDoc(patternDocRef);
      const patternsRef = collection(db, 'users', user.uid, 'patterns');
      const snapshot = await getDocs(patternsRef);
      const patternsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPatterns(patternsData);
      showSnackbar('パターンが削除されました。', 'success');
    } catch (error) {
      console.error('パターン削除エラー:', error);
      showSnackbar('パターンの削除中にエラーが発生しました', 'error');
    }
  };

  // -----------------------------
  // (10) 除外ワードテンプレート
  // -----------------------------
  const handleApplySelectedDeleteWordTemplate = () => {
    if (!selectedDeleteWordTemplateId) {
      showSnackbar('適用するテンプレートを選択してください', 'warning');
      return;
    }
    const selectedDoc = deleteWordTemplates.find((tpl) => tpl.name === selectedDeleteWordTemplateId);
    if (!selectedDoc) {
      showSnackbar('選択されたテンプレートが見つかりません', 'error');
      return;
    }
    const newDeleteWords = (selectedDoc.words || []).map((w) => ({ value: w, label: w }));
    setDeleteWords(newDeleteWords);
    showSnackbar(`テンプレート「${selectedDoc.name}」を適用しました。`, 'success');
  };

  const handleDeleteRowsByExclusionWords = () => {
    if (data.length === 0) {
      showSnackbar('処理対象のデータがありません。', 'warning');
      return;
    }
    if (!deleteWords || deleteWords.length === 0) {
      showSnackbar('除外ワードが入力されていません。', 'warning');
      return;
    }
    if (backupProcessedData.length === 0) {
      setBackupProcessedData(JSON.parse(JSON.stringify(data)));
    }
    let totalRowsExcluded = 0;
    let newExclusionLog = [];
    const updatedData = data.filter((row, rowIndex) => {
      const titleText = row.Title || row.title || '';
      const jpTitleText = row.jp_title || row.JP_title || row.jpTitle || '';
      const jpDescText = row.jp_desc || row.JP_desc || row.jpDesc || '';
      const combinedText = (titleText + ' ' + jpTitleText + ' ' + jpDescText).toLowerCase();
      const hasExclusion = deleteWords.some((wordObj) =>
        combinedText.includes(wordObj.value.toLowerCase())
      );
      if (hasExclusion) {
        totalRowsExcluded += 1;
        newExclusionLog.push({
          rowIndex,
          rowData: row,
        });
      }
      return !hasExclusion;
    });
    setData(updatedData);
    setExclusionLog(newExclusionLog);
    showSnackbar(`除外ワードによって ${totalRowsExcluded} 行を除外しました。`, 'success');
  };

  // -----------------------------
  // (11) 【重要】自動編集機能を data に適用する処理
  // -----------------------------
  const handleApplyAutoEdits = async () => {
    if (!user) {
      showSnackbar('ユーザーが認証されていません。', 'error');
      return;
    }
    if (!data || data.length === 0) {
      showSnackbar('適用するデータがありません。', 'warning');
      return;
    }
    if (selectedFunctions.length === 0) {
      showSnackbar('実行する自動編集機能を選択してください。', 'warning');
      return;
    }
    try {
      setIsProcessing(true);
      const csvString = Papa.unparse(data);
      let fileData = [{ name: 'inMemoryData.csv', content: csvString }];
  
      for (const func of selectedFunctions) {
        if (func.name === 'applyTemplate') {
          fileData = await applyTemplate(fileData, user, selectedTemplate);
        } else if (func.name === 'applyPriceChanges') {
          fileData = await applyPriceChanges(fileData, selectedPriceSettings, showSnackbar);
        } else if (func.name === 'applyAITitles') {
          const aiTitleResult = await applyAITitles(
            fileData,
            apiKey,
            (prog) => setProgress(prog),
            {
              deleteStrings,
              replacePairs,
              prependText,
              appendText,
              limitTitleLength,
              additionalPrompt,
              useImageAnalysis: useImageAnalysisForTitle,
            }
          );
          fileData = aiTitleResult.updatedData;
          setErrorItems(aiTitleResult.errorItems || []);
        } else if (func.name === 'applyAIProductDescriptions') {
          const descResult = await applyAIProductDescriptions(
            fileData,
            apiKey,
            (prog) => setProgress(prog),
            selectedTemplate,
            {
              sentencesToDelete,
              wordsToReplace,
              mandatoryKeywords,
              customFilters,
              selectedFilters,
              selectedTemplate: selectedDesignTemplate,
              bannerEnabled,
              bannerUrl,
              picInsertEnabled,
              setSnackbar: showSnackbar,
              imageAnalysisStrategy,
            }
          );
          fileData = descResult.updatedData;
          setErrorItems(descResult.errorItems || []);
        /* 以下のブロックを削除
        } else if (func.name === 'applyImageAnalysis') {
          if (imageAnalysisMode === 'emptyOnly') {
            fileData = await applyImageAnalysisIfEmpty(fileData, apiKey, showSnackbar);
          } else {
            fileData = await applyImageAnalysisForAll(fileData, apiKey, showSnackbar);
          }
        */
        } else if (func.name === 'applyItemSpecifics') {
          fileData = await applyItemSpecificsWrapper(
            fileData,
            user.uid,
            {
              selectedItemSpecificsCategory,
              selectedItemSpecificsColumns,
              itemSpecificsMatchingOptions,
              overwriteExisting,
              setSnackbar: showSnackbar,
              setProgress,
            }
          );
        }
      }
  
      const updatedRows = Papa.parse(fileData[0].content, { header: true }).data;
      setData(updatedRows);
      
      // 代わりに解析結果プレビュー表示をオンにする
     setShowAnalysisPreview(true);
      
      showSnackbar('自動編集機能を適用しました。', 'success');
    } catch (error) {
      console.error('自動編集適用中エラー:', error);
      showSnackbar('自動編集適用中にエラーが発生しました', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

// AI商品説明用テンプレートを読み込む関数の例
const handleLoadAiDescTemplate = async (templateId) => {
  if (!templateId) {
    // 未選択の場合はステートをクリア
    setSelectedAiDescTemplateId('');
    setAiDescTemplateData(null);
    return;
  }
  
  // テンプレートをロード中の状態を表すフラグを true に
  setTemplateLoading(true);

  try {
    // Firestore の /users/{uid}/templates/{templateId} からドキュメントを取得
    const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
    const docRef = doc(userTemplatesRef, templateId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      // 取得したテンプレートの内容を state に反映
      setAiDescTemplateData(data);

      // 必要に応じて、削除ワード・置換ワードなどもここでステートにセットできる
      // 例:
      // setCustomFilters(data.customFilters || []);
      // setSentencesToDelete(data.sentencesToDelete || []);
      // setWordsToReplace(data.wordsToReplace || []);
      // setMandatoryKeywords(data.mandatoryKeywords || []);
      // setSelectedFilters(data.selectedFilters || []);
      // setSelectedDesignTemplate(data.selectedTemplate || '');
    } else {
      showSnackbar('選択したテンプレートが見つかりませんでした。', 'warning');
    }
  } catch (err) {
    console.error('AI説明テンプレートの読み込み中エラー:', err);
    showSnackbar('AI説明テンプレートの読み込み中にエラーが発生しました。', 'error');
  } finally {
    // ロード完了
    setTemplateLoading(false);
  }
};


  // -----------------------------
  // (12) Item Specifics 適用
  // -----------------------------
  // 追加のユーティリティ関数
  const extractAIGeneratedContent = (description) => {
    if (!description) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    const aiContentDiv = doc.getElementById('ai-generated-content');
    return aiContentDiv ? aiContentDiv.innerHTML : '';
  };

  const extractKeyValuePairs = (content) => {
    const keyValuePairs = {};
    if (!content) return keyValuePairs;

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    // <li> 要素を全て取得
    const listItems = doc.querySelectorAll('li');
    // 不要な bullet 記号を除去する正規表現
    const bulletRegex = /^[\-\•\✓\✔\*\s]+/;
    // 「C:Key : Value」や「C:Key = Value」にマッチする正規表現
    const mainRegex = /^C[:：]\s*([^:=：]+?)\s*[:：=]\s*(.+)$/;

    listItems.forEach((li) => {
      let text = li.textContent.trim();
      text = text.replace(bulletRegex, '').trim();
      const match = text.match(mainRegex);
      if (match) {
        const key = match[1].trim();
        const value = match[2].trim();
        if (key && value) {
          keyValuePairs[key] = value;
        }
      }
    });
    return keyValuePairs;
  };

  const truncateValueByWords = (value, maxLength) => {
    if (value.length <= maxLength) return value;
    const words = value.split(' ');
    let truncatedValue = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const separator = truncatedValue ? ' ' : '';
      if ((truncatedValue + separator + word).length > maxLength) break;
      truncatedValue += separator + word;
    }
    return truncatedValue;
  };

  // CSVデータの各行の、対象カラムの値をオブジェクト形式に変換する前処理関数
  const preprocessItemSpecificsData = (dataRows, columnsToWrap) => {
    return dataRows.map((row) => {
      const newRow = { ...row };
      columnsToWrap.forEach((col) => {
        const val = newRow[col];
        // 値が存在し、かつオブジェクトでなければラップする
        if (val && typeof val !== 'object') {
          newRow[col] = {
            readable: val,
            // toString() をオーバーライドして、常に readable を返すようにする
            toString() {
              return this.readable;
            }
          };
        }
        // 値が存在しない場合は何もしない（そのまま）
      });
      return newRow;
    });
  };

  // CSVから再パースした結果 updatedRows に対して、すべてのセルをチェックして文字列化する関数
  const convertObjectCellsToString = (rows) => {
    return rows.map((row) => {
      // 行の全カラムをチェック
      Object.keys(row).forEach((col) => {
        const val = row[col];
        if (val && typeof val === 'object') {
          // readable プロパティがあるならその値を使い、なければ JSON.stringify する
          row[col] = val.readable !== undefined ? val.readable : JSON.stringify(val);
        }
      });
      return row;
    });
  };

  // カテゴリ変更時
  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setSelectedItemSpecificsCategory(newCategory);

    const categoryData = itemSpecificsCategories[newCategory];
    if (categoryData && categoryData.columns) {
      // columns はオブジェクト想定
      const columnsObj = categoryData.columns;
      // ここでキーの配列を作る
      const colKeys = Object.keys(columnsObj);
      setAvailableColumns(colKeys);
    } else {
      setAvailableColumns([]);
    }

    // 選択リセット
    setSelectedItemSpecificsColumns([]);
  };

  // ▼ カラムを複数選択できるようにする
  const handleColumnsChange = (e) => {
    const value = e.target.value;
    // MenuItem のクリックで複数選択が行われる
    setSelectedItemSpecificsColumns(typeof value === 'string' ? value.split(',') : value);
  };

  const handleApplyItemSpecifics = async () => {
    if (!user) {
      showSnackbar('ユーザーが認証されていません。', 'error');
      return;
    }
    if (!selectedItemSpecificsCategory) {
      showSnackbar('カテゴリが未選択です', 'error');
      return;
    }
    if (!selectedItemSpecificsColumns || selectedItemSpecificsColumns.length === 0) {
      showSnackbar('適用するカラムを選択してください', 'error');
      return;
    }
    try {
      setIsProcessing(true);
      // ① CSVデータの各行の対象カラムを前処理でオブジェクトに変換（値がある場合のみ）
      const preprocessedRows = preprocessItemSpecificsData(data, selectedItemSpecificsColumns);
      // ② CSV文字列に変換し、ファイルオブジェクト形式にする
      const csvContent = Papa.unparse(preprocessedRows);
      const fileData = [{ name: 'inMemoryData.csv', content: csvContent }];
      
      // ③ applyItemSpecificsWrapper を呼び出す
      const updatedFileData = await applyItemSpecificsWrapper(
        fileData,
        user.uid,
        {
          selectedItemSpecificsCategory,
          selectedItemSpecificsColumns,
          itemSpecificsMatchingOptions: {
            caseSensitive: false,
            partialMatch: true,
            matchSource: 'both',
            matchingOption: 'priority',
          },
          overwriteExisting,
          setSnackbar: showSnackbar,
          setProgress,
        }
      );
      
      // ④ 処理結果 CSV を再度パースする
      const updatedRows = Papa.parse(updatedFileData[0].content, { header: true }).data;
      // ⑤ すべてのセルをチェックして、オブジェクトなら文字列に変換する
      const finalRows = convertObjectCellsToString(updatedRows);
      
      setData(finalRows);
      showSnackbar('Item Specificsを適用しました。', 'success');
    } catch (err) {
      console.error('Item Specifics適用中エラー:', err);
      showSnackbar('Item Specifics適用中にエラーが発生しました', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  // -----------------------------
  // (13) 再置換ボタン（Description の再加工）
  // -----------------------------
  const handleRefineDescription = () => {
    if (data.length === 0) {
      showSnackbar('処理対象のデータがありません。', 'warning');
      return;
    }
    const newData = data.map((row) => {
      const originalDesc = row.Description || '';
      const refinedHtml = transformFinalHTMLWithDOMParser(originalDesc, sentencesToDelete, wordsToReplace);
      return { ...row, Description: refinedHtml };
    });
    setData(newData);
    showSnackbar('再置換を適用しました！', 'success');
  };

  // -----------------------------
  // (14) プレビュー & ダウンロード
  // -----------------------------
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPreviewCurrentPage(1);
  };
  
  const handlePreviewRow = (row) => {
    setPreviewRowData(row);
    setPreviewDialogOpen2(true);
  };
  
  const handleDeleteRow = (actualRowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(actualRowIndex, 1);
      return newData;
    });
    showSnackbar('商品を削除しました', 'success');
  };

  // 表示形式の切り替え
  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  // -----------------------------
  // (15) その他のイベントハンドラ
  // -----------------------------
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };
  
  const handleGoHome = () => {
    navigate('/');
  };
  
  const handleTemplateChange = (e) => {
    const templateName = e.target.value;
    setSelectedTemplate(templateName);
    if (templateName.startsWith('user-category-')) {
      const userTemplateName = templateName.replace('user-category-', '');
      const selectedUserCategoryTemplate = userCategoryTemplates.find(
        (template) => template.name === userTemplateName
      );
      setColumns(selectedUserCategoryTemplate ? selectedUserCategoryTemplate.columns : []);
    } else {
      const selectedColumns = categoryTemplates[templateName] || [];
      setColumns(selectedColumns);
    }
    setData([]);
    setStartRow(1);
    setEndRow(1);
    
    // 次のステップへ自動的に進む
    const newCompleted = { ...completed };
    newCompleted[0] = true;
    setCompleted(newCompleted);
    setActiveStep(1);
  };

  // 既存の handleOpenAnalysisDialog の変更例
  const handleOpenAnalysisDialog = () => {
    if (analysisImageList.length === 0) {
      showSnackbar("解析対象の画像がありません。", "warning");
      return;
    }
    setImageSelectionDialogOpen(true);
  };

  const handleExecuteImageAnalysisWithSelection = async (selectedImages) => {
    if (!apiKey) {
      showSnackbar("APIキーが設定されていません。", "error");
      return;
    }
    if (selectedImages.length === 0) {
      showSnackbar("解析対象の画像が選択されていません。", "warning");
      return;
    }
    try {
      setIsProcessing(true);
      // 選択された画像リストを渡して解析を実行
      const result = await StandaloneImageAnalysis(selectedImages, apiKey);
      setAnalysisResult(result);
      showSnackbar("画像解析が完了しました。", "success");
      console.log("解析結果:", result);
      // 必要に応じて、解析結果を data の各商品へ反映する処理を追加可能
    } catch (error) {
      console.error("画像解析実行エラー:", error);
      showSnackbar("画像解析の実行中にエラーが発生しました。", "error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddAnalysisImage = () => {
    if (analysisImageUrl.trim() !== "") {
      setAnalysisImageList([...analysisImageList, analysisImageUrl.trim()]);
      setAnalysisImageUrl("");
    }
  };

  const handleRemoveAnalysisImage = (index) => {
    const newList = [...analysisImageList];
    newList.splice(index, 1);
    setAnalysisImageList(newList);
  };

  // 2. 画像解析実行関数を修正
const handleExecuteImageAnalysis = async () => {
  if (!apiKey) {
    showSnackbar("APIキーが設定されていません。", "error");
    return;
  }
  if (data.length === 0) {
    showSnackbar("解析対象のデータがありません。", "warning");
    return;
  }
  
  try {
    setIsProcessing(true);
    let fileData = [{ name: 'inMemoryData.csv', content: Papa.unparse(data) }];
    if (imageAnalysisMode === 'emptyOnly') {
      fileData = await applyImageAnalysisIfEmpty(fileData, apiKey, showSnackbar);
    } else {
      fileData = await applyImageAnalysisForAll(fileData, apiKey, showSnackbar);
    }
    
    const updatedRows = Papa.parse(fileData[0].content, { header: true }).data;
    setData(updatedRows);
    
    // 解析結果をプレビュー用に保存
    setAnalysisPreviewData(updatedRows);
    // プレビュー表示モードをオン
    setShowAnalysisPreview(true);
    
    showSnackbar("画像解析が完了しました。", "success");
  } catch (error) {
    console.error("画像解析実行エラー:", error);
    showSnackbar("画像解析の実行中にエラーが発生しました。", "error");
  } finally {
    setIsProcessing(false);
  }
};

// 3. 通常表示に戻すボタン用の関数
const handleReturnToNormalView = () => {
  setShowAnalysisPreview(false);
};

  // ドラッグ&ドロップ処理
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  // CSVファイルのダウンロード
  const handleDownloadCsv = () => {
    if (data.length === 0) {
      showSnackbar('ダウンロードできるデータがありません。', 'warning');
      return;
    }
    
    const csvContent = Papa.unparse(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    
    const today = new Date();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const formattedDate = `${month}${day}_${hours}${minutes}`;
    
    link.setAttribute('download', `bulk_listing_output_${formattedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    showSnackbar('CSVファイルをダウンロードしました。', 'success');
  };

  // -----------------------------
  // アプリケーションのレンダリング
  // -----------------------------
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
      {/* ヘッダー (AppBar) */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          ...(isSidebarOpen && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
          }),
        }}
      >
        <Toolbar>
          <IconButton color="inherit" onClick={() => setIsSidebarOpen(!isSidebarOpen)} edge="start" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          
          <IconButton color="inherit" onClick={handleGoHome} edge="start" sx={{ mr: 2 }}>
            <HomeIcon />
          </IconButton>
          
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            0から一括出品
          </Typography>
          
          {apiKey ? (
            <Chip
              icon={<CheckCircleIcon />}
              label="APIキー設定済み"
              color="success"
              variant="outlined"
              sx={{ mr: 2, color: 'white', borderColor: 'rgba(255,255,255,0.5)' }}
            />
          ) : (
            <Chip
              icon={<ErrorIcon />}
              label="APIキー未設定"
              color="error"
              variant="outlined"
              sx={{ mr: 2, borderColor: 'rgba(255,255,255,0.5)' }}
            />
          )}
          
          <Button 
            variant="outlined" 
            color="inherit" 
            startIcon={<SettingsIcon />}
            onClick={() => handleSectionChange('apiKeyInput')}
            sx={{ borderColor: 'rgba(255,255,255,0.5)' }}
          >
            設定
          </Button>
        </Toolbar>
      </AppBar>

      {/* サイドバー (Drawer) */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={isSidebarOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { 
            width: drawerWidth, 
            boxSizing: 'border-box',
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', mt: 2 }}>
          <List>
            <ListItem 
              button 
              selected={activeStep === 0} 
              onClick={() => handleStepClick(0)}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <CategoryIcon color={activeStep === 0 ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText 
                primary="①テンプレート選択" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
              {completed[0] && <CheckCircleIcon color="success" fontSize="small" sx={{ ml: 1 }} />}
            </ListItem>
            
            <ListItem 
              button 
              selected={activeStep === 1} 
              onClick={() => handleStepClick(1)}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
              disabled={!completed[0]}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <CloudUploadIcon color={activeStep === 1 ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText 
                primary="②画像アップロード" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
              {completed[1] && <CheckCircleIcon color="success" fontSize="small" sx={{ ml: 1 }} />}
            </ListItem>
            
            <ListItem 
              button 
              selected={activeStep === 2} 
              onClick={() => handleStepClick(2)}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
              disabled={!completed[1]}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <AutoFixHighIcon color={activeStep === 2 ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText 
                primary="③自動編集設定" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
              {completed[2] && <CheckCircleIcon color="success" fontSize="small" sx={{ ml: 1 }} />}
            </ListItem>
            
            <ListItem 
              button 
              selected={activeStep === 3} 
              onClick={() => handleStepClick(3)}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
              disabled={!completed[2]}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <PreviewIcon color={activeStep === 3 ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText 
                primary="④プレビュー・出力" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
              {completed[3] && <CheckCircleIcon color="success" fontSize="small" sx={{ ml: 1 }} />}
            </ListItem>
          </List>
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle2" sx={{ px: 3, mb: 1, fontWeight: 'bold' }}>
            機能メニュー
          </Typography>
          
          <List>
            <ListItem 
              button 
              selected={activeSection === 'main'} 
              onClick={() => handleSectionChange('main')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText 
                primary="メイン画面" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>

            <ListItem 
              button 
              selected={activeSection === 'templateManager'} 
              onClick={() => handleSectionChange('templateManager')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText 
                primary="テンプレート管理" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeSection === 'priceManager'} 
              onClick={() => handleSectionChange('priceManager')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText 
                primary="価格管理" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>
            
            
            <Divider sx={{ my: 1 }} />
            
            <ListItem 
              button 
              selected={activeSection === 'titleGeneration'} 
              onClick={() => handleSectionChange('titleGeneration')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <SmartToyIcon />
              </ListItemIcon>
              <ListItemText 
                primary="AIタイトル生成" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeSection === 'descriptionGeneration'} 
              onClick={() => handleSectionChange('descriptionGeneration')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText 
                primary="AI商品説明生成" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>


            <ListItem 
              button 
              selected={activeSection === 'itemSpecifics'} 
              onClick={() => handleSectionChange('itemSpecifics')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <PlaylistAddCheckIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Item Specifics管理" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>
            
            <Divider sx={{ my: 1 }} />
            
            <ListItem 
              button 
              selected={activeSection === 'apiKeyInput'} 
              onClick={() => handleSectionChange('apiKeyInput')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText 
                primary="APIキー入力" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeSection === 'userTemplateManager'} 
              onClick={() => handleSectionChange('userTemplateManager')}
              sx={{ borderRadius: 1, mx: 1 , py: 0.75, }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText 
                primary="ユーザーテンプレート管理" 
                primaryTypographyProps={{ fontSize: '0.85rem' }}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* メインコンテンツ */}
      <Main open={isSidebarOpen}>
        <Toolbar />

        {!apiKey && (
          <Alert 
            severity="warning" 
            sx={{ mb: 3 }}
            action={
              <Button 
                color="inherit" 
                size="small" 
                onClick={() => handleSectionChange('apiKeyInput')}
              >
                設定画面へ
              </Button>
            }
          >
            OpenAI APIキーが設定されていません。左のサイドバーから「APIキー入力」を選択し、APIキーを入力してください。
          </Alert>
        )}

        {/* メイン画面内のステッパー表示 (モバイル向け) */}
        {!matches && activeSection === 'main' && (
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 2, mb: 4 }}>
            {['テンプレート選択', '画像アップロード', '自動編集設定', 'プレビュー・出力'].map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        {activeSection === 'main' && (
          <Box>
            {/* パンくずリスト */}
            <Breadcrumbs 
              separator={<NavigateNextIcon fontSize="small" />} 
              sx={{ mb: 3 }}
            >
              <Link color="inherit" onClick={() => handleSectionChange('main')} sx={{ cursor: 'pointer' }}>
                メイン
              </Link>
              <Typography color="text.primary">
                {activeStep === 0 && 'テンプレート選択'}
                {activeStep === 1 && '画像アップロード'}
                {activeStep === 2 && '自動編集設定'}
                {activeStep === 3 && 'プレビュー・出力'}
              </Typography>
            </Breadcrumbs>

            {/* ステップ1: テンプレート選択 */}
            {activeStep === 0 && (
              <Card sx={{ mb: 4 }}>
                <CardHeader 
                  title="テンプレート選択" 
                  subheader="出品するカテゴリーテンプレートを選択してください"
                  avatar={<CategoryIcon color="primary" />}
                />
                <CardContent>
                  <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel id="template-select-label">カテゴリーテンプレート</InputLabel>
                    <Select
                      labelId="template-select-label"
                      value={selectedTemplate}
                      label="カテゴリーテンプレート"
                      onChange={handleTemplateChange}
                    >
                      <MenuItem value="">
                        <em>テンプレートを選択してください</em>
                      </MenuItem>
                      <Divider textAlign="left">システムテンプレート</Divider>
                      {Object.keys(categoryTemplates).map((templateName) => (
                        <MenuItem key={templateName} value={templateName}>
                          {templateName}
                        </MenuItem>
                      ))}
                      <Divider textAlign="left">ユーザーテンプレート</Divider>
                      {userCategoryTemplates.map((template) => (
                        <MenuItem key={template.name} value={`user-category-${template.name}`}>
                          {template.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button 
                    variant="outlined" 
                    startIcon={<AddCircleOutlineIcon />} 
                    onClick={openCSVUploadDialog}
                    sx={{ mr: 2 }}
                  >
                    CSVからテンプレート作成
                  </Button>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleNext}
                    disabled={!selectedTemplate}
                    endIcon={<NavigateNextIcon />}
                  >
                    次へ: 画像アップロード
                  </Button>
                </CardActions>
              </Card>
            )}

            {/* ステップ2: 画像アップロード */}
            {activeStep === 1 && (
              <Card sx={{ mb: 4 }}>
                <CardHeader 
                  title="画像アップロード" 
                  subheader="商品画像をアップロードしてください"
                  avatar={<CloudUploadIcon color="primary" />}
                />
                <CardContent>
                  {selectedTemplate && (
                    <Box>
                

                      <ImageUploader
                        user={user}
                        showSnackbar={showSnackbar}
                        defaultValues={{
                          'Action(CC=Cp1252)': 'Add',
                          'ConditionID': '3000',
                          'C:Brand': 'No Brand',
                          'PayPalAccepted': '1',
                          'PayPalEmailAddress': 'payAddress',
                          'Country': 'JP',
                          'Apply Profile Domestic': '0.0',
                          'Apply Profile International': '0.0',
                          'BuyerRequirements:LinkedPayPalAccount': '0.0',
                          'Duration': 'GTC',
                          'Format': 'FixedPriceItem',
                          'Quantity': '1',
                          'Currency': 'USD',
                          'SiteID': 'US',
                          'C:Country': 'Japan',
                          'BestOfferEnabled': '0',
                        }}
                        columns={columns}
                        setColumns={setColumns}
                        data={data}
                        setData={setData}
                        startRow={startRow}
                        setStartRow={setStartRow}
                        endRow={endRow}
                        setEndRow={setEndRow}
                      />

                   

                
                    </Box>
                  )}
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                  <Button onClick={handleBack}>
                    戻る
                  </Button>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleNext}
                    disabled={data.length === 0}
                    endIcon={<NavigateNextIcon />}
                  >
                    次へ: 自動編集設定
                  </Button>
                </CardActions>
              </Card>
            )}

            {/* ステップ3: 自動編集設定 */}
            {activeStep === 2 && (
              <Card sx={{ mb: 4 }}>
                <CardHeader 
                  title="自動編集設定" 
                  subheader="商品情報を自動的に編集・生成する機能を設定します。ここではまず画像解析を行ってから、その結果をもとに自動編集を行います。"

                  avatar={<AutoFixHighIcon color="primary" />}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    自動編集機能
                  </Typography>

                  <Grid container spacing={2} alignItems="flex-start">
                    {/* 左側: 機能一覧 */}
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography variant="subtitle1" gutterBottom>
                          適用する機能を選択
                        </Typography>
                        <List>
                          {availableFunctions.map((func, index) => (
                            <ListItem key={index} disablePadding>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={!!selectedFunctions.find(f => f.name === func.name)}
                                    onChange={handleFunctionToggle(func)}
                                    color="primary"
                                  />
                                }
                                label={
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {func.icon && <Box component="span" sx={{ mr: 1 }}>{func.icon}</Box>}
                                    {func.displayName}
                                  </Box>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Grid>

                    {/* 右側: パターン保存・読み込み */}
                    <Grid item xs={12} md={8}>
                      {/* <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          パターン管理
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                              <InputLabel>既存パターンを選択</InputLabel>
                              <Select
                                value={selectedPatternName}
                                label="既存パターンを選択"
                                onChange={(e) => {
                                  setSelectedPatternName(e.target.value);
                                  const pattern = patterns.find(p => p.id === e.target.value);
                                  if (pattern) applyPatternSettings(pattern);
                                }}
                              >
                                <MenuItem value="">
                                  <em>パターンを選択</em>
                                </MenuItem>
                                {patterns.map((pattern) => (
                                  <MenuItem key={pattern.id} value={pattern.id}>
                                    {pattern.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              variant="outlined"
                              onClick={() => setIsSavePatternDialogOpen(true)}
                              startIcon={<SaveIcon />}
                              sx={{ mb: 2 }}
                              fullWidth
                            >
                              現在の設定をパターン保存
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper> */}

                      {/* Item Specifics 設定セクション */}
                      {selectedFunctions.some(f => f.name === 'applyItemSpecifics') && (
                        <Paper sx={{ p: 2, mt: 2 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            Item Specifics設定
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth size="small">
                                <InputLabel>Item Specifics カテゴリ</InputLabel>
                                <Select
                                  value={selectedItemSpecificsCategory}
                                  label="Item Specifics カテゴリ"
                                  onChange={handleCategoryChange}
                                >
                                  <MenuItem value="">
                                    <em>カテゴリを選択</em>
                                  </MenuItem>
                                  {Object.keys(itemSpecificsCategories).map((categoryName) => (
                                    <MenuItem key={categoryName} value={categoryName}>
                                      {categoryName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth size="small">
                                <InputLabel>適用するカラム</InputLabel>
                                <Select
                                  multiple
                                  value={selectedItemSpecificsColumns}
                                  onChange={handleColumnsChange}
                                  renderValue={(selected) => selected.join(', ')}
                                  label="適用するカラム"
                                >
                                  {availableColumns.map((colName) => (
                                    <MenuItem key={colName} value={colName}>
                                      <Checkbox checked={selectedItemSpecificsColumns.indexOf(colName) > -1} />
                                      <ListItemText primary={colName} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={overwriteExisting}
                                    onChange={(e) => setOverwriteExisting(e.target.checked)}
                                  />
                                }
                                label="既存の値を上書きする"
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      )}

                      {/* 画像解析設定セクション */}
                      <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          画像解析設定
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                value={imageAnalysisMode}
                                onChange={(e) => setImageAnalysisMode(e.target.value)}
                              >
                            
                                <FormControlLabel
                                  value="all"
                                  control={<Radio />}
                                  label="すべての商品を解析"
                                />
                              </RadioGroup>
                            </FormControl>
                          
                          
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                value={imageAnalysisStrategy}
                                onChange={(e) => setImageAnalysisStrategy(e.target.value)}
                              >
                               
                                <FormControlLabel
                                  value="preferAnalysis"
                                  control={<Radio />}
                                  label="解析結果を優先"
                                />
                                
                              </RadioGroup>
                            </FormControl>
                        
                            <Button
                              variant="contained"
                              color="info"
                              startIcon={<AnalyticsIcon />}
                              onClick={handleExecuteImageAnalysis} // 正しい関数を呼び出す
                              disabled={isProcessing}
                            >
                              画像解析のみ実行
                            </Button>
                          </Grid>

                          <Grid item xs={12} md={6}> 
                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>AIタイトル生成用 カテゴリーを選択</InputLabel>
                            <Select
                              value={selectedCategory}
                              label="カテゴリーを選択"
                              onChange={(e) => {
                                const categoryName = e.target.value;
                                setSelectedCategory(categoryName);
                                // customCategories から該当カテゴリーの詳細を取得して state に反映
                                const category = customCategories.find(cat => cat.name === categoryName);
                                if (category) {
                                  setDeleteStrings(category.deleteStrings || []);
                                  setReplacePairs(category.replacePairs || []);
                                  setPrependText(category.prependText || '');
                                  setAppendText(category.appendText || '');
                                  setAdditionalPrompt(category.additionalPrompt || '');
                                  setLimitTitleLength(category.limitTitleLength || false);
                                }
                              }}
                            >
                              <MenuItem value="">
                                <em>未選択</em>
                              </MenuItem>
                              {customCategories.map((cat) => (
                                <MenuItem key={cat.name} value={cat.name}>
                                  {cat.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>


                          <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel>AI商品説明生成用 テンプレートを選択</InputLabel>
                            <Select
                              value={selectedAiDescTemplateId}
                              label="テンプレートを選択"
                              onChange={(e) => {
                                const tid = e.target.value;
                                setSelectedAiDescTemplateId(tid);
                                // Firebaseからテンプレート詳細を読み込む
                                handleLoadAiDescTemplate(tid);
                              }}
                            >
                              <MenuItem value="">
                                <em>未選択</em>
                              </MenuItem>
                              {templates.map((tpl) => (
                                <MenuItem key={tpl.id} value={tpl.id}>
                                  {tpl.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          </Grid>
                        </Grid>
                      </Paper>


                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between', p: 2 }}>
                  <Button onClick={handleBack}>
                    戻る
                  </Button>
                  <Box>
                    <Button 
                      variant="contained" 
                      color="secondary"
                      onClick={handleApplyAutoEdits}
                      disabled={selectedFunctions.length === 0 || isProcessing}
                      startIcon={isProcessing ? <CircularProgress size={20} /> : <AutoFixHighIcon />}
                      sx={{ mr: 2 }}
                    >
                      {isProcessing ? '処理中...' : '自動編集を実行'}
                    </Button>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleNext}
                      endIcon={<NavigateNextIcon />}
                    >
                      次へ: プレビュー・出力
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            )}

            {/* ステップ4: プレビュー・出力 */}
            {activeStep === 3 && (
              <Card sx={{ mb: 4 }}>
                <CardHeader 
                  title="プレビュー・出力" 
                  subheader="処理結果のプレビューとダウンロード"
                  avatar={<PreviewIcon color="primary" />}
                />
                <CardActions sx={{ justifyContent: 'space-between' }}>
                  <Button onClick={handleBack}>
                    戻る
                  </Button>
                
                </CardActions>
              </Card>
            )}

           {/* データ編集セクション */}

  
            {showAnalysisPreview ? (
              // 画像解析結果プレビュー表示
              <Paper elevation={3} sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">
                    <AnalyticsIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                    画像解析結果プレビュー
                  </Typography>
                  <Button 
                    variant="outlined" 
                    startIcon={<ArrowBackIcon />} 
                    onClick={() => setShowAnalysisPreview(false)}
                  >
                    テーブル表示に戻る
                  </Button>
                </Box>
                
                {/* 解析結果プレビュー */}
          <Box sx={{ maxHeight: '600px', overflow: 'auto' }}>
            <TableContainer component={Paper} variant="outlined" sx={{ overflowX: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '150px' }}>No.</TableCell>
                    <TableCell sx={{ width: '250px' }}>画像</TableCell>
                    <TableCell sx={{ width: '30%' }}>解析結果</TableCell>
                    <TableCell sx={{ width: '20%' }}>AIタイトル</TableCell>
                    <TableCell sx={{ width: '30%' }}>AI商品説明</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.slice(
                    previewCurrentPage === 'All' ? 0 : (previewCurrentPage - 1) * itemsPerPageForPreview,
                    previewCurrentPage === 'All' ? data.length : previewCurrentPage * itemsPerPageForPreview
                  ).map((row, rowIndex) => (
                    <TableRow key={rowIndex} hover>
                      <TableCell>
                        {rowIndex + 1}
                        <Box sx={{ mt: 1, display: 'flex', gap: 0.5 }}>
                          <Tooltip title="プレビュー">
                            <IconButton size="small" color="primary" onClick={() => handlePreviewRow(row)}>
                              <PreviewIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="削除">
                            <IconButton size="small" color="error" onClick={() => handleDeleteRow(rowIndex)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="画像解析">
                            <IconButton 
                              size="small" 
                              color="info" 
                              onClick={() => handleOpenProductImageDialog(row, rowIndex)}
                            >
                              <AnalyticsIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {row.PicURL ? (
                          <Box>
                            <Grid container spacing={1} sx={{ maxWidth: 300 }}>
                              {getImageUrls(row.PicURL).map((imageUrl, imgIndex) => (
                                <Grid item xs={2.4} key={imgIndex}>
                                  <Box 
                                    component="img"
                                    src={imageUrl}
                                    alt={`商品画像 ${imgIndex + 1}`}
                                    sx={{ 
                                      width: '100%', 
                                      height: 50, 
                                      objectFit: 'cover',
                                      borderRadius: 1,
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handlePreviewRow(row)}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        ) : (
                          <Typography variant="body2" color="text.secondary">なし</Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            whiteSpace: 'pre-wrap',
                            maxHeight: '200px', 
                            overflow: 'auto' 
                          }}
                        >
                          {row.jp_image_description || '(解析結果なし)'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            whiteSpace: 'pre-wrap',
                            maxHeight: '200px', 
                            overflow: 'auto' 
                          }}
                        >
                          {row.jp_title || row.Title || '(タイトルなし)'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: 'top' }}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            whiteSpace: 'pre-wrap',
                            maxHeight: '200px', 
                            overflow: 'auto' 
                          }}
                        >
                          {row.jp_desc || row.Description || '(商品説明なし)'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
              </Paper>
            ) : (
              // 通常のデータ編集表示（既存のテーブル）
              <>
                <Paper elevation={3} sx={{ p: 2, mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    データ編集
                  </Typography>
                  <EditableSpreadsheet
                    data={data}
                    setData={setData}
                    columns={columns}
                    setColumns={setColumns}
                    initialData={initialData}
                    setInitialData={setInitialData}
                    userId={user ? user.uid : null}
                    setIsDataModified={setIsDataModified}
                  />
                </Paper>
              </>
            )}
          </Box>
        )}

        {/* 他のセクション */}
        {activeSection === 'priceManager' && (
          <Box>
            <PriceManager data={data} setData={setData} />
          </Box>
        )}

        {activeSection === 'itemSpecifics' && (
          <Box>
            <ItemSpecificsManager userId={user ? user.uid : null} headers={columns} data={data} setData={setData} />
          </Box>
        )}

        {activeSection === 'templateManager' && (
          <Box>
            <ColumnNameBasedTemplateManager data={data} setData={setData} columns={columns} setColumns={setColumns} setIsDataModified={setIsDataModified} />
          </Box>
        )}

        {activeSection === 'titleGeneration' && (
          <Box>
            <TitleGenerationComponent data={data} setData={setData} apiKey={apiKey} user={user} />
          </Box>
        )}

        {activeSection === 'descriptionGeneration' && (
          <Box>
            <OpenAIDescriptionGenerator data={data} setData={setData} apiKey={apiKey} user={user} />
          </Box>
        )}

        {activeSection === 'apiKeyInput' && (
          <Box>
            <APIKeyInput user={user} apiKey={apiKey} setApiKey={setApiKey} />
          </Box>
        )}

        {activeSection === 'userTemplateManager' && (
          <Box>
            <UserTemplateManager userTemplates={userTemplates} setUserTemplates={setUserTemplates} user={user} />
          </Box>
        )}

        {/* ダイアログ */}
        <Dialog open={isCSVUploadDialogOpen} onClose={() => setIsCSVUploadDialogOpen(false)}>
          <DialogTitle>CSVファイルからテンプレートを作成</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="text.secondary" paragraph>
              CSVファイルをアップロードしてカラム情報をテンプレートとして保存します。
            </Typography>
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              fullWidth
              sx={{ mt: 2 }}
            >
              CSVファイルを選択
              <input type="file" accept=".csv" onChange={handleCSVFileChange} hidden />
            </Button>
            {csvFile && (
              <Box sx={{ mt: 2 }}>
                <Chip label={csvFile.name} color="primary" onDelete={() => setCsvFile(null)} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsCSVUploadDialogOpen(false)}>キャンセル</Button>
            <Button onClick={handleCSVUpload} disabled={!csvFile} variant="contained" color="primary">
              アップロード
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
          <DialogTitle>実行する自動編集機能を選択</DialogTitle>
          <DialogContent>
            <List>
              {availableFunctions.map((func, index) => (
                <ListItem key={index} disablePadding>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!selectedFunctions.find(f => f.name === func.name)}
                        onChange={handleFunctionToggle(func)}
                        color="primary"
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {func.icon && <Box component="span" sx={{ mr: 1 }}>{func.icon}</Box>}
                        {func.displayName}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFunctionDialogClose}>キャンセル</Button>
            <Button onClick={handleFunctionDialogClose} color="primary" variant="contained">
              選択を確定
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isSavePatternDialogOpen} onClose={() => setIsSavePatternDialogOpen(false)}>
          <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
          <DialogContent>
            <TextField 
              label="パターン名" 
              value={newPatternName} 
              onChange={(e) => setNewPatternName(e.target.value)} 
              fullWidth 
              margin="normal"
              placeholder="例: 洋服カテゴリ用設定"
              helperText="このパターン名でよく使う設定を保存できます"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
            <Button 
              onClick={saveCurrentSettingsAsPattern} 
              color="primary" 
              variant="contained"
              disabled={!newPatternName.trim()}
            >
              保存
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isManagePatternsDialogOpen} onClose={() => setIsManagePatternsDialogOpen(false)}>
          <DialogTitle>パターンの管理</DialogTitle>
          <DialogContent>
            {patterns.length > 0 ? (
              <List>
                {patterns.map((pattern) => (
                  <ListItem key={pattern.id}>
                    <ListItemText primary={pattern.name} />
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePattern(pattern.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="text.secondary" sx={{ py: 2 }}>
                保存されたパターンはありません
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isExclusionLogOpen} onClose={() => setIsExclusionLogOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>除外された商品の一覧</DialogTitle>
          <DialogContent>
            {exclusionLog.length > 0 ? (
              <Box>
                {exclusionLog.map((log, index) => (
                  <Box key={index} sx={{ mb: 1, borderBottom: '1px solid #ddd', pb: 1 }}>
                    <Typography variant="body2">
                      行番号: {log.rowIndex + 1} / タイトル: {log.rowData.Title || log.rowData.title || '(No Title)'}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography>除外された商品はありません。</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsExclusionLogOpen(false)}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* 画像選択ダイアログ */}
        <EnhancedImageSelectionDialog
          open={productImageDialogOpen}
          images={productImages}
          onClose={() => setProductImageDialogOpen(false)}
          onConfirm={handleConfirmProductImages}
        />

        {/* 商品詳細プレビューダイアログ */}
        <Dialog 
          open={previewDialogOpen2} 
          onClose={() => setPreviewDialogOpen2(false)} 
          maxWidth="md" 
          fullWidth
          scroll="paper"
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="div">商品プレビュー</Typography>
              <IconButton onClick={() => setPreviewDialogOpen2(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {previewRowData ? (
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {previewRowData.Title || '(タイトルなし)'}
                </Typography>
                
                <Grid container spacing={3}>
                  {/* 左カラム: 画像エリア */}
                  <Grid item xs={12} md={6}>
                    <Box>
                      {getImageUrls(previewRowData.PicURL)[0] ? (
                        <Box
                          component="img"
                          src={getImageUrls(previewRowData.PicURL)[0]}
                          alt="メイン画像"
                          sx={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: 400,
                            objectFit: 'contain',
                            borderRadius: 1,
                            mb: 2
                          }}
                        />
                      ) : (
                        <Paper 
                          variant="outlined" 
                          sx={{ 
                            height: 300, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            mb: 2
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            画像がありません
                          </Typography>
                        </Paper>
                      )}
                      
                      {/* サムネイル */}
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {getImageUrls(previewRowData.PicURL).map((url, idx) => (
                          <Box
                            key={idx}
                            component="img"
                            src={url}
                            alt={`サムネイル ${idx+1}`}
                            sx={{
                              width: 70,
                              height: 70,
                              objectFit: 'cover',
                              borderRadius: 1,
                              border: '1px solid #eee',
                              cursor: 'pointer'
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                  
                  {/* 右カラム: 商品詳細情報 */}
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle1" fontWeight="bold">商品情報</Typography>
                      <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Box component="span" sx={{ fontWeight: 'bold' }}>価格:</Box>
                          <Box component="span">${previewRowData.StartPrice || '設定なし'}</Box>
                        </Typography>
                        
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Box component="span" sx={{ fontWeight: 'bold' }}>カテゴリー:</Box>
                          <Box component="span">{previewRowData.Category || previewRowData.PrimaryCategory || '設定なし'}</Box>
                        </Typography>
                        
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Box component="span" sx={{ fontWeight: 'bold' }}>コンディション:</Box>
                          <Box component="span">{previewRowData.ConditionID === '3000' ? '新品' : previewRowData.ConditionID || '設定なし'}</Box>
                        </Typography>
                        
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box component="span" sx={{ fontWeight: 'bold' }}>ブランド:</Box>
                          <Box component="span">{previewRowData['C:Brand'] || '設定なし'}</Box>
                        </Typography>
                      </Paper>
                    </Box>
                    
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">説明</Typography>
                      <Paper variant="outlined" sx={{ p: 2, mt: 1, maxHeight: 300, overflow: 'auto' }}>
                        {previewRowData.Description ? (
                          <Box dangerouslySetInnerHTML={{ __html: previewRowData.Description }} />
                        ) : (
                          <Typography variant="body2" color="text.secondary">説明がありません</Typography>
                        )}
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
                
                {/* Item Specifics */}
                <Box sx={{ mt: 3 }}>
                  <Typography variant="subtitle1" fontWeight="bold">Item Specifics</Typography>
                  <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
                    <Grid container spacing={2}>
                      {Object.keys(previewRowData)
                        .filter(key => key.startsWith('C:') && previewRowData[key])
                        .map((key, idx) => (
                          <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Box component="span" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                                {key.replace('C:', '')}:
                              </Box>
                              <Box component="span">
                                {previewRowData[key]}
                              </Box>
                            </Typography>
                          </Grid>
                        ))}
                        
                      {Object.keys(previewRowData)
                        .filter(key => key.startsWith('C:') && previewRowData[key]).length === 0 && (
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">
                            Item Specificsがありません
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Box>
                
                {/* 解析データ */}
                {previewRowData.jp_image_description && (
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1" fontWeight="bold">画像解析結果</Typography>
                    <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
                      <Typography variant="body2" whiteSpace="pre-wrap">
                        {previewRowData.jp_image_description}
                      </Typography>
                    </Paper>
                  </Box>
                )}
              </Box>
            ) : (
              <Typography>データがありません</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setPreviewDialogOpen2(false)}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* スナックバー */}
        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity={snackbar.severity} 
            variant="filled"
            sx={{ width: '100%' }}
          >
            {typeof snackbar.message === 'string'
    ? snackbar.message
    : JSON.stringify(snackbar.message)}
          </Alert>
        </Snackbar>
        
        {/* 処理中オーバーレイ */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isProcessing}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress color="inherit" size={60} sx={{ mb: 2 }} />
            <Typography variant="h6">処理中...</Typography>
            {progress.totalItems > 0 && (
              <Box sx={{ width: '300px', mt: 2 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={(progress.completedItems / progress.totalItems) * 100} 
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" color="white" align="center">
                  {progress.completedItems} / {progress.totalItems} 完了
                </Typography>
              </Box>
            )}
          </Box>
        </Backdrop>
      </Main>
    </Box>
  );
}

export default BulkListingFromScratch;
        