import React, { useState, useEffect } from 'react';
import {
  TextField,
  Chip,
  Button,
  Box,
  Alert,
  Snackbar,
  Typography,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { db } from '../../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';

function APIKeyInput({ user, apiKey, setApiKey,apiKeys, setApiKeys }) {
  // 複数の API キーを配列で管理する state
  const [apiKeysInput, setApiKeysInput] = useState([]);
  const [showKey, setShowKey] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  // ユーザー固有の秘密鍵を生成
  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value'; // 固定の塩値（環境変数などで管理するとより安全です）
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  // マウント時または親から渡された apiKey（従来の単一キー）がある場合、Chip 配列に変換
  useEffect(() => {
    // 親から複数キーが来たら、それをチップとして表示する
    if (apiKeys && apiKeys.length > 0) {
      setApiKeysInput(apiKeys);
    } else if (apiKey) {
      setApiKeysInput([apiKey]);
    }
  }, [apiKeys, apiKey]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      alert('ログインしてください。');
      return;
    }
    try {
      const encryptionKey = generateEncryptionKey(user.uid);
      // 各キーを暗号化
      const encryptedKeys = apiKeysInput.map((key) =>
        CryptoJS.AES.encrypt(key, encryptionKey).toString()
      );
      // Firestore に複数のキーを apiKeys 配列として保存
      await setDoc(doc(db, 'userApiKeys', user.uid), { apiKeys: encryptedKeys }, { merge: true });
      // 後方互換のため、1 件のみの場合は親の apiKey にもセット
      if (apiKeysInput.length > 0) {
        setApiKey(apiKeysInput[0]);
      }
      // setApiKeys が渡されていれば複数キーの情報も親コンポーネントに渡す
      if (setApiKeys) {
        setApiKeys(apiKeysInput);
      }
      setSnackbar({ open: true, message: 'APIキーが保存されました。', severity: 'success' });
    } catch (error) {
      console.error('APIキーの保存エラー:', error);
      setSnackbar({ open: true, message: `APIキーの保存に失敗しました: ${error.message}`, severity: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Alert severity="warning" sx={{ mb: 1 }}>
        セキュリティ警告: APIキーは暗号化されてFirestoreに保存されます。キーは1つずつ追加・削除できます。
      </Alert>
      <Autocomplete
        multiple
        freeSolo
        // options プロパティを必ず指定（ここでは空の配列）
        options={[]}
        value={apiKeysInput}
        onChange={(event, newValue) => setApiKeysInput(newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={showKey ? option : '●●●●●'}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="API Key(s)"
            placeholder="キーを入力してEnter"
            type={showKey ? 'text' : 'password'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <IconButton onClick={() => setShowKey(!showKey)} edge="end">
                    {showKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        APIキーを保存
      </Button>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。料金はユーザーの責任です。
      </Typography>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default APIKeyInput;





// // APIKeyInput.js

// import React, { useState, useEffect } from 'react';
// import {
//   TextField,
//   Button,
//   Box,
//   Alert,
//   IconButton,
//   Snackbar,
//   Typography,
// } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { db } from '../../firebaseConfig';
// import { doc, setDoc } from 'firebase/firestore';
// import CryptoJS from 'crypto-js';

// function APIKeyInput({ user, apiKey, setApiKey }) {
//   const [apiKeyInput, setApiKeyInput] = useState('');
//   const [showKey, setShowKey] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

//   // ユーザー固有の秘密鍵を生成
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value'; // 固定の塩値
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // コンポーネントのマウント時に受け取ったapiKeyを設定
//   useEffect(() => {
//     if (apiKey) {
//       setApiKeyInput(apiKey);
//     }
//   }, [apiKey]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!user) {
//       alert('ログインしてください。');
//       return;
//     }
//     try {
//       const encryptionKey = generateEncryptionKey(user.uid);
//       const encryptedKey = CryptoJS.AES.encrypt(apiKeyInput, encryptionKey).toString();
//       // Firestoreに保存
//       await setDoc(doc(db, 'userApiKeys', user.uid), { apiKey: encryptedKey }, { merge: true });
//       setApiKey(apiKeyInput); // 親コンポーネントの状態を更新
//       setSnackbar({ open: true, message: 'APIキーが保存されました。', severity: 'success' });
//     } catch (error) {
//       console.error('APIキーの保存エラー:', error);
//       setSnackbar({ open: true, message: `APIキーの保存に失敗しました: ${error.message}`, severity: 'error' });
//     }
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
//       <Alert severity="warning" sx={{ mb: 1 }}>
//         セキュリティ警告: APIキーは暗号化されてFirestoreに保存されます。
//       </Alert>
//       <TextField
//         label="OpenAI API Key"
//         value={apiKeyInput}
//         onChange={(e) => setApiKeyInput(e.target.value)}
//         type={showKey ? 'text' : 'password'}
//         fullWidth
//         margin="normal"
//         InputProps={{
//           endAdornment: (
//             <IconButton onClick={() => setShowKey(!showKey)} edge="end">
//               {showKey ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           ),
//         }}
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 0 }}>
//         APIキーを保存
//       </Button>
//       <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
//         注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
//         キーの使用に関連するすべての料金はユーザーの責任となります。
//       </Typography>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// }

// export default APIKeyInput;