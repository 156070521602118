// StandaloneImageAnalysis.js
// このファイルは、Firebase Storage の画像URLを元に画像解析を行う完全なサンプルです。
// 画像を取得し、base64 に変換して Data URL を生成、そして OpenAI の GPT-4 Turbo（Vision 対応）で解析します。

import axios from 'axios';
import { Buffer } from 'buffer'; // Node.js 環境の場合。ブラウザでは不要

/**
 * Firebase Storage の画像URLから画像データを取得し、base64 Data URL を生成する関数
 * @param {string} firebaseImageUrl - Firebase Storage の画像URL（?alt=media を含む）
 * @returns {Promise<string>} - 生成された Data URL (例: data:image/png;base64,...)
 */
const fetchImageAsDataURL = async (firebaseImageUrl) => {
  try {
    // 画像データをバイナリとして取得
    const imageResponse = await axios.get(firebaseImageUrl, { responseType: 'arraybuffer' });
    const contentType = imageResponse.headers['content-type'] || 'image/png';
    const imageBuffer = imageResponse.data;
    const base64Image = Buffer.from(imageBuffer, 'binary').toString('base64');
    return `data:${contentType};base64,${base64Image}`;
  } catch (err) {
    console.error('fetchImageAsDataURL エラー:', err.response?.data || err.message);
    throw err;
  }
};

/**
 * 画像を解析して説明文を生成する関数
 * @param {string} imageUrl - Firebase Storage の画像URL（?alt=media を含む）
 * @param {string} apiKey - OpenAI APIキー
 * @returns {Promise<string>} - 生成された説明文
 */
const ImageAnalysis = async (imageUrl, apiKey) => {
  try {
    console.log('ImageAnalysis: imageUrl=', imageUrl);

    // 1. Firebase の画像URLから画像データを取得して Data URL を生成
    const dataUrl = await fetchImageAsDataURL(imageUrl);
    console.log('ImageAnalysis: dataUrl 生成完了');

    // 2. Chat Completion API のリクエストペイロード作成
    const payload = {
      model: 'gpt-4o-mini', // 公式の Vision 対応モデルを指定
      messages: [
        {
          role: 'user',
          // content はオブジェクト配列形式で送る（Vision 対応の場合）
          content: [
            {
              type: 'text',
              text: '以下の画像に基づいて、商品の特徴を日本語で箇条書きにて詳しく説明してください。'
            },
            {
              type: 'image_url',
              image_url: {
                url: dataUrl
              }
            }
          ]
        }
      ],
      max_tokens: 1000 // 必要に応じて調整
    };

    // 3. OpenAI API にリクエスト
    const apiResponse = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      }
    );

    // 4. 結果を取得
    const description = apiResponse.data.choices[0].message.content.trim();
    console.log('ImageAnalysis: description =', description);
    return description;
  } catch (error) {
    if (error.response) {
      console.error('ImageAnalysis エラー - ステータスコード:', error.response.status);
      console.error('ImageAnalysis エラー - レスポンスデータ:', error.response.data);
    } else if (error.request) {
      console.error('ImageAnalysis エラー - レスポンスがありません:', error.request);
    } else {
      console.error('ImageAnalysis エラー:', error.message);
    }
    console.error('ImageAnalysis エラーコンフィグ:', error.config);
    throw error;
  }
};

export default ImageAnalysis;

