// domTransform.js (例)

// (A) 正規表現用にエスケープするヘルパー
function escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  // (B) メイン関数
  export function transformFinalHTMLWithDOMParser(html, sentencesToDelete, wordsToReplace) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
  
    function traverse(node) {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          let text = child.nodeValue || '';
  
          // 1) sentencesToDelete (単語単位で削除)
          sentencesToDelete.forEach((delStr) => {
            const trimmed = delStr.trim();
            if (trimmed) {
              // 単語境界 \b を使う → 例: \bitem\b
              const escaped = escapeRegExp(trimmed);
              const reg = new RegExp(`\\b${escaped}\\b`, 'gi');
              text = text.replace(reg, '');
            }
          });
  
          // 2) wordsToReplace (単語単位で置換)
          wordsToReplace.forEach(({ from, to }) => {
            const fromTrimmed = from.trim();
            if (fromTrimmed) {
              const escapedFrom = escapeRegExp(fromTrimmed);
              const reg = new RegExp(`\\b${escapedFrom}\\b`, 'gi');
              text = text.replace(reg, to);
            }
          });
  
          child.nodeValue = text;
        }
        else if (child.nodeType === Node.ELEMENT_NODE) {
          traverse(child);
        }
      });
    }
  
    traverse(doc.body);
  
    return doc.body.innerHTML;
  }
 