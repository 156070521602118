// AdminNotificationSender.js

import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import {
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';

function AdminNotificationSender() {
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');     // ★追加: タイトル用ステート
  const [details, setDetails] = useState(''); // ★追加: 詳細用ステート
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [editedUsers, setEditedUsers] = useState({});
  const auth = getAuth();
  const functions = getFunctions();

  useEffect(() => {
    const checkAdminAndFetchUsers = async () => {
      const user = auth.currentUser;
      if (user) {
        // トークンを強制的にリフレッシュして、最新のカスタムクレームを取得
        await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setUserIsAdmin(true);

          // ユーザー一覧の取得
          try {
            const getAllUsers = httpsCallable(functions, 'getAllUsers');
            const result = await getAllUsers();
            const usersData = result.data.users;

            // ユーザー情報を確認するためのデバッグログ
            console.log('取得したユーザー情報:', usersData);

            // Firestore から各ユーザーの maxRequestsPerDay を取得
            const usersWithMaxRequests = await Promise.all(
              usersData.map(async (userData) => {
                const userDocRef = doc(db, 'users', userData.uid);
                const userDoc = await getDoc(userDocRef);
                const userDocData = userDoc.exists() ? userDoc.data() : {};
                return {
                  uid: userData.uid,
                  email: userData.email,
                  disabled: userData.disabled === true, // 明示的に true/false を設定
                  maxRequestsPerDay: userDocData.maxRequestsPerDay || 350,
                };
              })
            );

            setUsers(usersWithMaxRequests);
            setLoadingUsers(false);
          } catch (error) {
            console.error('ユーザー情報の取得中にエラーが発生しました:', error);
            setLoadingUsers(false);
            setSnackbar({
              open: true,
              message: 'ユーザー情報の取得に失敗しました。',
              severity: 'error',
            });
          }
        } else {
          setSnackbar({
            open: true,
            message: '管理者権限が必要です。アクセスが拒否されました。',
            severity: 'error',
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: 'ユーザーがサインインしていません。ログインしてください。',
          severity: 'warning',
        });
      }
    };
    checkAdminAndFetchUsers();
  }, [auth, functions]);

  const sendNotification = async () => {
    if (!message.trim()) {
      setSnackbar({
        open: true,
        message: '通知メッセージを入力してください。',
        severity: 'warning',
      });
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('ユーザーが認証されていません。');
      }

      const idToken = await user.getIdToken();

      const response = await fetch(
        'https://us-central1-ebayprice-405908.cloudfunctions.net/api/sendSystemNotification',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ title, message, details }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || '通知の送信に失敗しました。');
      }

      setSnackbar({
        open: true,
        message: '通知が送信されました。',
        severity: 'success',
      });
      setTitle('');
      setMessage('');
      setDetails('');
    } catch (error) {
      console.error('通知送信エラー:', error);
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      });
    }
  };

  const handleInputChange = (userId, value) => {
    setEditedUsers({
      ...editedUsers,
      [userId]: value,
    });
  };

  const handleSave = async (userId) => {
    const newMaxRequestsPerDay = parseInt(editedUsers[userId]);
    if (isNaN(newMaxRequestsPerDay) || newMaxRequestsPerDay < 0) {
      setSnackbar({
        open: true,
        message: '有効な数値を入力してください。',
        severity: 'warning',
      });
      return;
    }
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { maxRequestsPerDay: newMaxRequestsPerDay });
      setSnackbar({
        open: true,
        message: 'ダウンロード上限が更新されました。',
        severity: 'success',
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.uid === userId
            ? { ...user, maxRequestsPerDay: newMaxRequestsPerDay }
            : user
        )
      );
      setEditedUsers((prev) => {
        const updated = { ...prev };
        delete updated[userId];
        return updated;
      });
    } catch (error) {
      console.error('更新中にエラーが発生しました:', error);
      setSnackbar({
        open: true,
        message: '更新に失敗しました。',
        severity: 'error',
      });
    }
  };

  const handleToggleUserStatus = async (userId, disable) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('ユーザーが認証されていません。');
      }

      const idToken = await user.getIdToken();

      const response = await fetch(
        'https://us-central1-ebayprice-405908.cloudfunctions.net/api/updateUserStatus',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ userId, disable }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'ユーザー状態の更新に失敗しました。');
      }

      setSnackbar({
        open: true,
        message: `ユーザーが${disable ? '無効化' : '有効化'}されました。`,
        severity: 'success',
      });

      // ユーザーリストを更新
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.uid === userId ? { ...user, disabled: disable } : user
        )
      );
    } catch (error) {
      console.error('ユーザー状態の更新中にエラーが発生しました:', error);
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
      });
    }
  };

  if (!userIsAdmin) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" color="error">
          アクセスが拒否されました。管理者権限が必要です。
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 800, margin: '0 auto' }}>
        <Typography variant="h5" gutterBottom>
          管理者ダッシュボード
        </Typography>
        {/* 通知送信フォーム */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            システム通知の送信
          </Typography>
          <Typography variant="body1" gutterBottom>
            ユーザーに送信する通知メッセージを入力してください。
          </Typography>
          <TextField
            label="タイトル"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            variant="outlined"
          />

          <TextField
            label="通知メッセージ"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            margin="normal"
          />
          
          <TextField
            label="詳細"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            margin="normal"
          />

          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={sendNotification}
              >
                通知を送信
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* ユーザー管理テーブル */}
        <Box sx={{ mt: 4 }}>
          {loadingUsers ? (
            <Typography>ユーザー情報を読み込み中...</Typography>
          ) : (
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                ユーザー管理
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ユーザーID</TableCell>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>有効状態</TableCell>
                    <TableCell>ダウンロード上限</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow key={user.uid}>
                      <TableCell>{user.uid}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.disabled ? '無効' : '有効'}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          defaultValue={user.maxRequestsPerDay || 350}
                          onChange={(e) =>
                            handleInputChange(user.uid, e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Button
                            variant="contained"
                            onClick={() => handleSave(user.uid)}
                            disabled={!editedUsers[user.uid]}
                          >
                            保存
                          </Button>
                          <Button
                            variant="contained"
                            color={user.disabled ? 'primary' : 'secondary'}
                            onClick={() => handleToggleUserStatus(user.uid, !user.disabled)}
                            sx={{ ml: 1 }}
                          >
                            {user.disabled ? '有効化' : '無効化'}
                          </Button>
                        </Box>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AdminNotificationSender;

